import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";

import { useNavigate } from "react-router";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
  ValueParserParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";

import { Box, HStack, IconCTAButton, VStack } from "../../components/utils";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import Avatar from "../esopOverview/Avatar";
import { Action, CTADropdown } from "../../components/shared/Dropdown";

import QuickSearch from "../../components/shared/QuickSearch";
import SearchInput from "../../components/shared/SearchInput";
import { useGetFilterStates } from "../../queries/gridUtils";
import MyCustomFilters from "../../components/shared/MyCustomFilters";
import { saveTableFilterState } from "../../api/gridUtils";
import { AvailableTableFilters, SaveTableState } from "../../types/Table";
import StatusLabelColors from "../../constants/StatusLabelColors";
import { TableId } from "../../constants/TableIdConstants";
import { useTableStateManagement } from "../../components/shared/TableHook";

import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";

function BuyBackOffersListAgGridTable({
  offersListTableData,
}: {
  offersListTableData: any[];
}) {
  const currency = getCurrencyType();

  const gridApi = useRef<GridApi | any>(null);
  // route params logic
  const navigate = useNavigate();

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };

  const { data, refetch } = useGetFilterStates("EMPLOYEES");

  const saveNewFilter = (filterName: string) => {
    const filterState = gridApi.current?.api.getFilterModel();
    const filterStateToSave: SaveTableState = {
      filterName,
      filterState,
      tableName: "EMPLOYEES",
    };
    saveTableFilterState(filterStateToSave).then((_) => {
      toast(`${filterName} saved successfully`, {
        autoClose: 2000,
        type: "success",
      });
      refetch();
    });
  };
  const onFilterSelected = (filterItem: AvailableTableFilters | null) => {
    gridApi?.current?.api.setFilterModel(filterItem?.filterState);
  };
  function numberValueParser(params: ValueParserParams) {
    const res = Number.parseInt(params.newValue, 10);
    if (Number.isNaN(res)) {
      return undefined;
    }
    return res;
  }

  const componentsRegistery = useMemo(
    () => ({
      employeeName: memo(EmployeeNameRender),
      grantedOptions: memo(GrantedOptionsRender),
      exercisedOptions: memo(ExercisedOptionsRender),
      vestedOptions: memo(VestedOptionsRender),
      eligibility: memo(EligibilityRender),
      willingness: memo(WillingnessRender),
      status: memo(StatusRender),
    }),
    []
  );

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
  };
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.employeeTable);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "EMPLOYEE NAME",
        field: "employeeName",
        cellRenderer: "employeeName",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,

        filter: "agTextColumnFilter",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "GRANTED OPTIONS",
        field: "grantedOptions",
        cellRenderer: "grantedOptions",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,

        valueFormatter: (params: any) => {
          const grantedOptions = params.value.toLocaleString(currency);
          return grantedOptions;
        },
        filter: "agNumberColumnFilter",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "EXERCISED OPTIONS",
        field: "exercisedOptions",
        filter: "agNumberColumnFilter",
        cellRenderer: "exercisedOptions",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        valueFormatter: (params: any) => {
          const exercisedOptions = params.value.toLocaleString(currency);
          return exercisedOptions;
        },
      },
      {
        headerName: "VESTED OPTIONS",
        field: "vestedOptions",
        filter: "agNumberColumnFilter",
        cellRenderer: "vestedOptions",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        valueFormatter: (params: any) => {
          const vestedOptions = params.value.toLocaleString(currency);
          return vestedOptions;
        },
      },
      {
        headerName: "ELIGIBILITY",
        field: "eligibility",
        filter: "agNumberColumnFilter",
        cellRenderer: "eligibility",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        valueFormatter: (params: any) => {
          const eligibility = params.value.toLocaleString(currency);
          return eligibility;
        },
      },
      {
        headerName: "WILLINGNESS",
        field: "willingness",
        filter: "agNumberColumnFilter",
        cellRenderer: "willingness",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNoOfShares",
        valueFormatter: (params: any) => {
          const willingness = params.value.toLocaleString(currency);
          return willingness;
        },
      },
      {
        headerName: "STATUS",
        field: "status",
        filter: "agSetColumnFilter",
        cellRenderer: "status",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],

        filterParams: {
          buttons: ["reset"],
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
        },
      },
    ],
    []
  );

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );

    const totalGrantedOptions = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.grantedOptions,
      0
    );
    const totalExercisedOptions = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.exercisedOptions,
      0
    );
    const totalVestedOptions = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.vestedOptions,
      0
    );
    const totalEligibility = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.eligibility,
      0
    );
    const totalWillingness = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.willingness,
      0
    );

    function formatToFourDecimals(value: number) {
      if (value === 0) return "0";

      const [integerPart, decimalPart] = value.toFixed(4).split(".");

      if (/^0+$/.test(decimalPart)) {
        return parseInt(integerPart, 10).toLocaleString();
      }

      const trimmedDecimalPart = decimalPart.replace(/0+$/, "");

      return `${parseInt(
        integerPart,
        10
      ).toLocaleString()}.${trimmedDecimalPart}`;
    }

    api.setPinnedBottomRowData([
      {
        employeeName: "Total",
        grantedOptions: formatToFourDecimals(totalGrantedOptions),
        exercisedOptions: formatToFourDecimals(totalExercisedOptions),
        vestedOptions: formatToFourDecimals(totalVestedOptions),
        eligibility: formatToFourDecimals(totalEligibility),
        willingness: formatToFourDecimals(totalWillingness),
      },
    ]);
  }

  const rowData = useMemo(
    () =>
      offersListTableData?.map((template) => ({
        employeeName: template.employeeName,
        grantedOptions: template.granted,
        exercisedOptions: template.exercised,
        vestedOptions: template.vested,
        eligibility: template.eligibility,
        willingness: template.willingness,
        status: template.state,
      })),
    [offersListTableData]
  );

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.api.paginationSetPageSize(Number(value));
  }, []);
  const onGridReady = (params: any) => {
    gridApi.current = params;
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter(filterModel);
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  return (
    <VStack className="w-full">
      <HStack className="items-center justify-end gap-4">
        <HStack>
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
        </HStack>
        {/* <QuickSearch
              className="px-2"
              placeholder="Search"
              onChange={(e) => {
                gridApi.current.setQuickFilter(e.target.value);
              }}
            /> */}
        <IconCTAButton
          value={"Columns"}
          onClick={() => openToolPanel("columns")}
          iconName={"fluent:column-triple-edit-20-regular"}
          className={`px-4 font-medium items-center flex flex-row ${
            isColumnOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isColumnOpen}
        />
        <IconCTAButton
          value={"Filters"}
          onClick={() => openToolPanel("filters")}
          iconName={"material-symbols:filter-alt"}
          className={`px-4  font-medium items-center flex flex-row ${
            isFilterOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isFilterOpen}
        />
        <MyCustomFilters
          currentFilterState={gridApi.current?.api.getFilterModel()}
          availableFilters={data || []}
          onFilterSelect={onFilterSelected}
          className={""}
          onNewFilterAddition={(e) => {
            saveNewFilter(e);
          }}
        ></MyCustomFilters>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            getRowStyle={(params) => ({
              background: params.data.isLastPinnedRow ? "#f0f0f0" : "",
              fontWeight: params.data.isLastPinnedRow ? "normal" : "normal",
              color: params.data.isLastPinnedRow ? "black" : "",
            })}
            suppressRowTransform={true}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            alwaysShowHorizontalScroll
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            components={componentsRegistery}
            sideBar={sideBar}
            singleClickEdit={true}
            suppressCellFocus={true}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressMenuHide={true}
            onRowDataUpdated={setPinnedBottomRowData}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            stopEditingWhenCellsLoseFocus={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            onSelectionChanged={handleSelectionChanged}
            suppressRowClickSelection={true}
            pagination={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}

export function EmployeeNameRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="pb-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.employeeName}
      </p>
    </div>
  );
}

export function GrantedOptionsRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="pb-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.grantedOptions}
      </p>
    </div>
  );
}

export function ExercisedOptionsRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="pb-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.exercisedOptions}
      </p>
    </div>
  );
}

export function VestedOptionsRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="pb-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.vestedOptions}
      </p>
    </div>
  );
}

export function EligibilityRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="pb-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.eligibility}
      </p>
    </div>
  );
}

export function WillingnessRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="pb-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.willingness}
      </p>
    </div>
  );
}

export function StatusRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="pb-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.status}
      </p>
    </div>
  );
}

export default BuyBackOffersListAgGridTable;
