import { QueryFunctionContext } from "react-query";
import {
  AddEmployeeReq,
  EmployeeCredentialGeneration,
  EmployeeUploadHeaderResponse,
  EmployeesOverviewDto,
  ImportConfig,
  RevokeAccessRequest,
  RevokeAccessResponse,
  UploadEmployeeExcel,
  ValidateAndImportResponse,
  ValidationResponse,
} from "../types/Employee";
import {
  AddPlanReq,
  AddPlanRes,
  EsopAccountingReq,
  EsopPlan,
  FinancialReportReq,
  SH6ReportReq,
} from "../types/EsopPlan";
import {
  AccelerateVestingDto,
  AddGrantReq,
  AddGrantRes,
  CreatePoolDto,
  CustomResponse,
  EsopCreateDto,
  Grant,
  GrantActivity,
  GrantResponse,
  ImportRes,
  OnboardingReportDto,
  OptionStateChangeReq,
  PlanStateChangeDto,
  PoolDetailsDto,
  ReportDto,
  SettlementSignatureDto,
  SignatureAttachment,
  Template,
  TrustDetails,
  UpdateSettlementLetterDto,
  UploadTemplate,
  ValidateRes,
} from "../types/Grant";
import { JwtPayload, LoginRequest, LoginResponse } from "../types/Login";
import {
  AddVestingTemplateReq,
  AddVestingTemplateRes,
  EmployeePageDetails,
  EmployeeStockTransaction,
  EmployeeVestingDetail,
  VestingEventReq,
  VestingTemplate,
} from "../types/VestingTemplate";

import { CaptableUserAccess } from "../layouts/SwitchProduct";
import { TransactionCard } from "../types/Card";
import { DocumentInterface, DocumentVault } from "../types/DocumentsInterface";
import { EnvelopeDetails } from "../types/ESign";
import {
  ResignationDto,
  UploadEmployeeDto,
} from "../types/EmployeeResignation";
import {
  EsopSubsidiaryCompany,
  SubsidiaryCompanyDropDown,
} from "../types/EsopSubsidiaryCompany";
import { PlanOwnershipDto } from "../types/Ownership";
import { NewUserDetail, UserCompanyData } from "../types/UserDetailModel";
import { ListofCompanies, Permission } from "../utils/interfaces/Companies";
import captableApi from "./captableApi";
import hissaApi from "./hissaApi";
import optionsApi from "./optionsApi";
import { EmployeeDataSync } from "../types/ExportExcelEmployee";
import { ReportType } from "../pages/reports/utils/report";

/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getAllGrants(): Promise<CustomResponse<Grant[]>> {
  return optionsApi.get("v1/plan/all").then((res) => res.data);
}

/**
 * resource: employee
 * action: view
 * @returns
 */

export async function getEmployeesOverview(): Promise<
  CustomResponse<EmployeesOverviewDto>
> {
  return optionsApi.get("v1/employee/all").then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getEsopPlansSummary(): Promise<
  CustomResponse<EsopPlan[]>
> {
  return optionsApi.get("v1/plan/summary").then((res) => res.data);
}

export async function getPoolDetails(): Promise<
  CustomResponse<PoolDetailsDto>
> {
  return optionsApi.get("v1/pool/details").then((res) => res.data);
}

export async function getTrustDetails(): Promise<
  CustomResponse<TrustDetails[]>
> {
  return optionsApi.get("v1/pool/trust").then((res) => res.data);
}

export async function createTrust(
  trustCreateDto: TrustDetails
): Promise<CustomResponse<TrustDetails>> {
  return optionsApi
    .post("v1/pool/createTrust", trustCreateDto)
    .then((res) => res.data);
}

export async function editTrust(
  trustCreateDto: TrustDetails
): Promise<CustomResponse<string>> {
  return optionsApi
    .put("v1/pool/editTrust", trustCreateDto)
    .then((res) => res.data);
}

export async function deleteTrust(
  trustId: string
): Promise<CustomResponse<string>> {
  const deleteBody = {
    id: trustId,
  };
  return optionsApi
    .delete("v1/pool/deleteTrust", { data: deleteBody })
    .then((res) => res.data);
}

/**
 * resource: vestingTemplate
 * action: view
 * @returns
 */

export async function getVestingTemplates(): Promise<
  CustomResponse<VestingTemplate[]>
> {
  return optionsApi
    .get("v1/vestingSchedule/getAllTemplates")
    .then((res) => res.data);
}
/**
 * resource: vestingEvent
 * action: view
 * @returns
 */

export async function getVestingEvents(): Promise<VestingEventReq[]> {
  return optionsApi
    .get("v1/vestingSchedule/allVestingEvents")
    .then((res) => res.data);
}
/**
 * resource: template
 * action: view
 * @returns
 */

export async function getEmployeeTemplate(): Promise<CustomResponse<Template>> {
  return optionsApi
    .get("v1/imports/employeeUploadTemplate")
    .then((res) => res.data);
}
/**
 * resource: template
 * action: view
 * @returns
 */

export async function getGrantTemplate(): Promise<
  CustomResponse<UploadTemplate>
> {
  return optionsApi
    .get("v1/imports/grantUploadTemplate")
    .then((res) => res.data);
}
/**
 * resource: template
 * action: view
 * @returns
 */
export async function addGrant(
  addGrantDto: AddGrantReq
): Promise<CustomResponse<AddGrantRes>> {
  return optionsApi.post("v1/grant/add", addGrantDto).then((res) => res.data);
}

export async function getVestingOverrides() {
  return optionsApi.get("v1/grant/getVestingOverrides").then((res) => res.data);
}

export async function editGrant(
  addGrantDto: AddGrantReq
): Promise<CustomResponse<AddGrantRes>> {
  return optionsApi.post("v1/grant/add", addGrantDto).then((res) => res.data);
}

/**
 * resource: grant
 * action: create
 * @returns
 */

export async function addGrantExcel(
  uploadExcelDto: UploadEmployeeExcel
): Promise<GrantResponse> {
  return optionsApi
    .post("v1/imports/createGrantsFromExcel", uploadExcelDto)
    .then((res) => res.data.data);
}

/**
 * resource: employee
 * action: create
 * @returns
 */

export async function addEmployeeExcel(
  uploadExcelDto: UploadEmployeeExcel
): Promise<string> {
  return optionsApi
    .post("imports/createGrantsFromExcel", uploadExcelDto)
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: create
 * @returns
 */

export async function addPlan(
  esopPlanDto: AddPlanReq
): Promise<CustomResponse<AddPlanRes>> {
  return optionsApi.post("v1/plan/create", esopPlanDto).then((res) => res.data);
}
/**
 * resource: employee
 * action: create
 * @returns
 */

export async function addEmployee(
  employeeDto: AddEmployeeReq
): Promise<CustomResponse<string>> {
  return optionsApi
    .post("v1/employee/add", employeeDto)
    .then((res) => res.data);
}

export async function editEmployee(
  employeeDto: AddEmployeeReq
): Promise<CustomResponse<string>> {
  return optionsApi
    .put("v1/employee/edit", employeeDto)
    .then((res) => res.data);
}

/**
 * resource: employee
 * action: delete
 * @returns
 */

export async function deleteEmployee(
  id: string
): Promise<CustomResponse<string>> {
  const deleteBody = {
    id,
  };
  return optionsApi
    .delete(`v1/employee/delete`, { data: deleteBody })
    .then((res) => res.data);
}

/**
 * resource: employee
 * action: delete
 * @returns
 */

export async function revokeOrEnableEmployeeAccess(
  revokeAccessRequest: RevokeAccessRequest
): Promise<RevokeAccessResponse> {
  return optionsApi
    .post(`v1/employee/enable-revoke-access`, revokeAccessRequest)
    .then((res) => res.data);
}

export async function getEmployeeVestingTable(
  empId: string
): Promise<CustomResponse<EmployeeVestingDetail[]>> {
  return optionsApi
    .get(`v1/employee/vesting`, { params: { id: empId } })
    .then((res) => res.data);
}

/**
 * resource: employeePage
 * action: view
 * @returns
 */

export async function getEmployeePageDetails(
  employeeId: string
): Promise<CustomResponse<EmployeePageDetails>> {
  return optionsApi
    .get(`v1/employee/employee_page`, { params: { id: employeeId } })
    .then((res) => res.data);
}

export async function getEmployeeTransactionDetails(
  id: string
): Promise<CustomResponse<EmployeeStockTransaction[]>> {
  return optionsApi
    .get(`v1/liquidityEvent/getEmployeeTransaction`, { params: { id } })
    .then((res) => res.data);
}
/**
 * resource: vestingTemplate
 * action: create
 * @returns
 */

export async function addVestingTemplate(
  vestingScheduleDto: AddVestingTemplateReq
): Promise<CustomResponse<AddVestingTemplateRes>> {
  return optionsApi
    .post("v1/vestingSchedule/create", vestingScheduleDto)
    .then((res) => res.data);
}

export async function addVestingTemplateForPlan(
  vestingScheduleDto: VestingTemplate
): Promise<CustomResponse<VestingTemplate>> {
  return optionsApi
    .post("v1/vestingSchedule/createForPlan", vestingScheduleDto)
    .then((res) => res.data);
}

export async function doLogin(loginDto: LoginRequest): Promise<LoginResponse> {
  return optionsApi
    .post("v1/auth/login", loginDto)
    .then((res) => res as LoginResponse);
}
/*v1/auth/*
 * resource: employee
 * action: create
 * @returns
 */

/**
 * resource: employee
 * action: create
 * @returns
 */

export async function getPlanDetails(
  id: string
): Promise<CustomResponse<AddPlanRes>> {
  return optionsApi
    .get("v1/plan/editDetails", { params: { id } })
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function editPlan(
  esopPlanDto: AddPlanReq
): Promise<CustomResponse<AddPlanRes>> {
  return optionsApi.put("v1/plan/update", esopPlanDto).then((res) => res.data);
}

/**
 * resource: grant
 * action: update
 * @returns
 */

export async function changeOptionStates(
  optionStateChangeDto: OptionStateChangeReq
): Promise<CustomResponse<string>> {
  return optionsApi
    .post("v1/grant/state-change", optionStateChangeDto)
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */
/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function changePlanState(
  planStateChangeDto: PlanStateChangeDto
): Promise<any> {
  return optionsApi
    .put(`v1/plan/changePlanState`, planStateChangeDto)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getAllGrantActivity(): Promise<
  CustomResponse<GrantActivity[]>
> {
  return optionsApi.get(`v1/grant/activityLog`).then((res) => res.data);
}

/**
 * @returns
 */

export async function getUserDetails(): Promise<NewUserDetail> {
  return optionsApi.get("/v1/user").then((res) => res?.data?.data);
}
export async function getUserCompanyDetails(): Promise<UserCompanyData> {
  return optionsApi.get("/v1/company").then((res) => res?.data?.data);
}
export async function getUserAssociatedCompanies(): Promise<ListofCompanies> {
  return optionsApi
    .get("/v1/user/listCompanies")
    .then((res) => res?.data?.data);
}
export async function switchCompany(newCompanyDetails: any): Promise<any> {
  return optionsApi
    .put("/v1/user/switchCompany", newCompanyDetails)
    .then((res) => res?.data?.data);
}
export async function getUserRoleAndPermission(): Promise<Permission> {
  return optionsApi
    .get("/v1/user/roleAndPermssion")
    .then((res) => res?.data?.data);
}
/**
 * resource: grant
 * action: delete
 * @returns
 */

export async function deleteGrant(
  id: string[]
): Promise<CustomResponse<string>> {
  const deleteBody = {
    id,
  };
  return optionsApi
    .delete("v1/grant/delete", { data: deleteBody })
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: delete
 * @returns
 */

export async function deletePlan(id: string): Promise<CustomResponse<string>> {
  const deleteBody = {
    id,
  };
  return optionsApi
    .delete("v1/plan/delete", { data: deleteBody })
    .then((res) => res.data);
}

/**
 * resource: employee
 * action: view
 * @returns
 */

export async function remindOfferedEmployee(id: string): Promise<any> {
  return optionsApi
    .post(`v1/grant/reminderEmails`, { ids: [id], isGrantRelated: true })
    .then((res) => res);
}
/**
 * resource: financialReports
 * action: view
 * @returns
 */

export async function generateFinancialReport(
  financialReportDto: FinancialReportReq
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/financial-report`, financialReportDto)
    .then((res) => res.data);
}

export async function generateGrantReport(
  grantReportDto: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/grantReport`, grantReportDto)
    .then((res) => res.data);
}

export async function generateVestWiseReport(
  vestWiseReportDto: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/vestWiseReport`, vestWiseReportDto)
    .then((res) => res.data);
}

export async function generateLiquidityReport(
  liquidityReportDto: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/liquidityReport`, liquidityReportDto)
    .then((res) => res.data);
}

export async function generateCancellationReport(
  cancellationReportDto: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/cancellationReport`, cancellationReportDto)
    .then((res) => res.data);
}

export async function generateAcceptanceReport(
  acceptanceReportDto: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/acceptanceReport`, acceptanceReportDto)
    .then((res) => res.data);
}

export async function generateEmployeeDetailsReport(
  employeeDetailsReportDto: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/employeeDetailsReport`, employeeDetailsReportDto)
    .then((res) => res.data);
}

export async function generateExerciseReport(
  exerciseReportDto: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/exerciseReport`, exerciseReportDto)
    .then((res) => res.data);
}

export async function generateTransactionReport(
  transactionReportDto?: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/transaction-report`, transactionReportDto)
    .then((res) => res.data);
}

export async function generateSH6Report(
  sh6ReportDto: SH6ReportReq
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`v1/report/exportSH6`, sh6ReportDto)
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getCreatePlanDetails(): Promise<
  CustomResponse<EsopCreateDto>
> {
  return optionsApi.get(`v1/plan/details`).then((res) => res.data);
}

/**
 * resource: esopPool
 * action: update
 * @returns
 */

export async function addEditPoolSize(
  createPoolDto: CreatePoolDto
): Promise<CustomResponse<string>> {
  return optionsApi
    .post(`v1/pool/create`, createPoolDto)
    .then((res) => res.data);
}

export async function doVerifyOtp(
  loginDto: LoginRequest
): Promise<LoginResponse> {
  return hissaApi
    .post("login/verifyOtp", loginDto)
    .then((res) => res as LoginResponse);
}

export async function doResendOtp(emailId: string): Promise<any> {
  return hissaApi.post("login/resendOtp", { emailId }).then((res) => res.data);
}

/**
 * resource: company
 * action: view
 * @returns
 */

export async function getAllSubsidiaryCompanies(): Promise<
  SubsidiaryCompanyDropDown[]
> {
  return optionsApi
    .get("v1/company/getAllSubsidiaryCompanyNames")
    .then((res) => res.data.data);
}
/**
 * resource: *
 * action: *
 * @returns
 */

export async function savePlanOwner(
  ownerShipReq: PlanOwnershipDto
): Promise<CustomResponse<string>> {
  return optionsApi
    .put("v1/plan/changeOwnership", ownerShipReq)
    .then((res) => res.data);
}

/**
 * resource: *
 * action: *
 * @returns
 */

export async function getOwnersOfPlan(
  context: QueryFunctionContext
): Promise<CustomResponse<PlanOwnershipDto>> {
  const esopPlanId = context.queryKey[1];
  return optionsApi
    .get(`v1/plan/ownershipDetails?esopPlanId=${esopPlanId}`)
    .then((res) => res.data);
}

export async function downloadPoolStatement(
  poolReportDto?: ReportType
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post("/v1/pool/generatePoolStatement", poolReportDto)
    .then((res) => res.data);
}

export async function validateOnboardingExcel(
  file: string
): Promise<CustomResponse<ValidateRes>> {
  return optionsApi
    .post(`/v1/imports/validateAll`, { file })
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: create
 * @returns
 */

export async function generateReport(
  onboardingReportDto: OnboardingReportDto
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post(`/v1/imports/downloadReport`, onboardingReportDto)
    .then((res) => res.data);
}

export async function downloadTemplate(): Promise<CustomResponse<Template>> {
  return optionsApi
    .get("/v1/imports/historicUploadTemplate")
    .then((res) => res.data);
}

export async function importAll(
  file: string
): Promise<CustomResponse<ImportRes[]>> {
  return optionsApi
    .post("/v1/imports/importAll", { file })
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getEsopAccounting(
  values: EsopAccountingReq
): Promise<CustomResponse<ReportDto>> {
  return optionsApi
    .post("v1/report/esopAccounting", values)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: create
 * @returns
 */

export async function bulkGenerateGrantLetters(ids: string[]) {
  return optionsApi.post(`v1/grant/generateGrantLetters`, ids);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getAllPlanDocs(
  id: string
): Promise<CustomResponse<DocumentInterface[]>> {
  return optionsApi
    .get(`v1/plan/getAllPlanDocs`, { params: { id } })
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function updatePlanDocs(
  documentToUpdate: DocumentInterface[]
): Promise<CustomResponse<DocumentInterface[]>> {
  return optionsApi
    .post(`v1/plan/uploadPlanDocuments`, documentToUpdate)
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: create
 * @returns
 */

export async function createEnvelope(
  envelopDetails: EnvelopeDetails
): Promise<any> {
  return optionsApi
    .post("v1/docuSign/createEnvelope", envelopDetails)
    .then((res) => res.data);
}

export async function generateJwtTokenForEmployee(employeeId: string) {
  return hissaApi
    .get(`employee/getAdminViewEmployeeToken?id=${employeeId}`)
    .then((res) => res.data);
}
export async function generateJwtToken(jwtToken: JwtPayload) {
  return optionsApi
    .post("v1/auth/generateJwtToken", jwtToken)
    .then((res) => res.data);
}
export async function signAndApproveGrantLetter(
  signatureObj: SignatureAttachment
) {
  return optionsApi
    .post("v1/document/insertSignatureApprover", signatureObj)
    .then((res) => res.data.data);
}

export async function createDigioSignRequest(body: {
  entityIds: string[];
  type: string;
}) {
  return optionsApi
    .post(`/v1/esign/requestESign?type=${body.type}`, body)
    .then((res) => res.data.data);
}
export async function previewSettlementLetter(
  signatureObj: SettlementSignatureDto
): Promise<string> {
  return optionsApi
    .post("v1/document/previewSignatureForSettlementLetter", signatureObj)
    .then((res) => res.data.data);
}

export async function updateSettlementLetter(
  signatureObj: UpdateSettlementLetterDto
): Promise<CustomResponse<string>> {
  return optionsApi
    .post("v1/document/updateSettlementLetterAfterPreviewing", signatureObj)
    .then((res) => res.data.data);
}
export async function getCompanyExists(
  context: QueryFunctionContext
): Promise<CaptableUserAccess> {
  const captableCompanyId = context.queryKey[1];
  return captableApi
    .get(`/v1/auth/esopUserAccess?companyId=${captableCompanyId}`)
    .then((res) => res?.data.data);
}
export async function doesCaptableCompanyExists(companyId: any) {
  return captableApi
    .get(`/v1/auth/esopUserAccess?companyId=${companyId}`)
    .then((res) => res?.data.data);
}

export async function setDefaultProduct(): Promise<string> {
  return hissaApi
    .put(`/user/setDefaultProduct?productType=CAPTABLE`)
    .then((res) => res?.data);
}

export async function getTransactionsCard(): Promise<
  CustomResponse<TransactionCard>
> {
  return optionsApi.get("v1/card/transactions").then((res) => res.data);
}

export async function generateEmployeeCredentials(
  generateCredentialsObj: EmployeeCredentialGeneration
): Promise<any> {
  return optionsApi
    .post("v1/employee/generate-credentials", generateCredentialsObj)
    .then((res) => res.data.data);
}

export async function employeeResignation(
  resignationDto: ResignationDto
): Promise<CustomResponse<ResignationDto>> {
  return optionsApi
    .post("v1/employee/resignationProcess", resignationDto)
    .then((res) => res.data);
}

export async function resignationProcess(
  resignationDto: ResignationDto
): Promise<CustomResponse<ResignationDto>> {
  return optionsApi
    .put("v1/employee/resignationProcess", resignationDto)
    .then((res) => res.data);
}

export async function getResignationDetails(
  employeeId: string
): Promise<CustomResponse<ResignationDto>> {
  return optionsApi
    .get(`v1/employee/resignationDetails?employeeId=${employeeId}`)
    .then((res) => res.data);
}

export async function completeResignation(employeeId: string): Promise<void> {
  return optionsApi
    .post(`v1/employee/completeResignation?employeeId=${employeeId}`)
    .then((res) => res.data);
}

export async function addEmplooyeesViaExcel(
  uploadObj: UploadEmployeeDto
): Promise<EmployeeUploadHeaderResponse> {
  return optionsApi
    .post(`v1/imports/uploadEmployeeExcel`, uploadObj)
    .then((res) => res.data.data);
}
export async function saveEmployeeData(uploadeEmployeeData: any): Promise<any> {
  return optionsApi
    .post("v1/imports/saveEmployeeData", uploadeEmployeeData)
    .then((res) => res.data.data);
}
export async function getExistingExcelConfigs(): Promise<ImportConfig[]> {
  return optionsApi
    .get(`v1/imports/getExisingConfigs`)
    .then((res) => res.data.data);
}
export async function validateAndImportCorrecrtedData(
  data: ValidationResponse[][]
): Promise<ValidateAndImportResponse> {
  return optionsApi
    .post(`v1/imports/validateAndImportData`, data)
    .then((res) => res.data.data);
}

export async function accelerateForGrant(
  accelerateDto: AccelerateVestingDto
): Promise<CustomResponse<string>> {
  return optionsApi
    .post(`v1/vesting/accelerate`, accelerateDto)
    .then((res) => res.data.data);
}

export async function discardResignation(employeeId: string): Promise<void> {
  return optionsApi
    .delete(`v1/employee/discardResignation?employeeId=${employeeId}`)
    .then((res) => res.data);
}

export async function jwtAdminForEmployee(employeeId: string) {
  return optionsApi
    .get(`v1/employee/getAdminViewEmployeeToken/${employeeId}`)
    .then((res) => res.data);
}

export async function getAllDocuments(): Promise<
  CustomResponse<DocumentVault[]>
> {
  return optionsApi.get(`v1/employee/allDocuments`).then((res) => res.data);
}

export async function getPresignedUrlForKey(
  awsKey: string
): Promise<{ data: string }> {
  return optionsApi
    .get(`/v1/employee/getPresignedUrl?s3Key=${awsKey}`)
    .then((res) => res.data);
}

export async function getPlanTemplates(): Promise<
  CustomResponse<AddPlanRes[]>
> {
  return optionsApi.get(`v1/plan/templates`).then((res) => res.data);
}

export async function downloadGrantLetters(grantIds: string[]) {
  return optionsApi
    .post("/v1/exports/downloadGrantLetters", { grantIds })
    .then((res) => res.data.data);
}

export async function bulkUploadGrantLetters(grantLetters: any) {
  return optionsApi
    .post("/v1/grant/uploadGrantLetters", grantLetters)
    .then((res) => res.data.data);
}

export async function getCompanyHRMSConfgs(): Promise<EmployeeDataSync[]> {
  return optionsApi
    .get("/v1/imports/getHrmsConfigs")
    .then((res) => res.data.data);
}

export async function scheduleHRMSSync(masterDataType: string) {
  return optionsApi
    .put("v1/imports/sync", { masterDataType })
    .then((res) => res.data.data);
}

export async function transferEmployeeAccess(accessObj: any) {
  return optionsApi
    .put("/v1/employee/transferEmployeeAccess", accessObj)
    .then((res) => res?.data?.data);
}
