import { useFormik, useFormikContext } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import produce from "immer";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Box,
  HStack,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  Error,
  isEsopViewer,
} from "../../components/utils";

import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  ESignSetting,
  SignerDetails,
  SiteSettingsRes,
} from "../../types/SiteSettings";
import { Input, Label } from "../../components/shared/InputField";

import { Select } from "../../components/shared/Select";
import {
  useESginSettings,
  useGetAllCompanyUsers,
  useSetESignSettings,
} from "../../queries/siteSettings";
import { SiteSettingsContext } from "./SiteSettings";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { usePermissionStore } from "../../store/permissionStore";

function ESignSettings() {
  const {
    data: eSginSettingDetails,
    refetch,
    isError,
    isFetched,
  } = useESginSettings();

  const initialValues: ESignSetting = {
    isEsignEnabled: (eSginSettingDetails?.isEsignEnabled as boolean) || false,
    isDocuSignEnabled:
      (eSginSettingDetails?.isDocuSignEnabled as boolean) || false,
    isDigioEnabled: (eSginSettingDetails?.isDigioEnabled as boolean) || false,
    signerDetails: eSginSettingDetails?.signerDetails || [],
    signerDetailsDtos: eSginSettingDetails?.signerDetailsDtos || [],
    useDigioAadhar: eSginSettingDetails?.useDigioAadhar || false,
    useDigioElectronic: eSginSettingDetails?.useDigioElectronic || false,
  };
  const validationSchema = Yup.object({
    isEsignEnabled: Yup.boolean(),
    isDocuSignEnabled: Yup.boolean(),
  });
  const { permission } = usePermissionStore();

  const { mutate: setESignSettings, isLoading: isSettingsSaved } =
    useSetESignSettings();
  const handleSubmit = (values: ESignSetting) => {
    setESignSettings(values, {
      onSuccess: () => {
        toast("Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };

  function handleOnCancel(values: ESignSetting) {
    values = initialValues;
  }

  const initialSettingsSet = useRef(false);
  const [initialSettings, setInitialSettings] = useState<ESignSetting>(
    eSginSettingDetails!
  );
  const formik = useFormik<ESignSetting>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      handleSubmit(values);
    },
    onReset: (values) => {
      handleOnCancel(values);
    },
  });

  useEffect(() => {
    if (eSginSettingDetails) {
      formik.setValues(eSginSettingDetails);
    }
    if (!initialSettingsSet.current) {
      setInitialSettings(_.cloneDeep(formik.values!));
      initialSettingsSet.current = true;
    }
  }, [eSginSettingDetails]);

  const [handleChanges, setHandleChanges] = useState<boolean>();

  useEffect(() => {
    const hasChanges = _.isEqual(initialSettings, formik.values);

    setHandleChanges(hasChanges);
  }, [initialSettings, eSginSettingDetails, formik.values]);

  const isUserEsopViewer = isEsopViewer();

  return (
    <form key="GrantLetterSettings" onSubmit={formik.handleSubmit}>
      <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg ">
        <VStack className="justify-between p-4 gap-9">
          <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
            Digio Settings
          </Box>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Employees should be allowed to E-sign
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                className=""
                value={formik.values.isDigioEnabled}
                label=""
                onClick={() => {
                  formik.setFieldValue(
                    "isDigioEnabled",
                    !formik.values.isDigioEnabled
                  );
                }}
              />
            </Box>
          </HStack>
          {formik.values.isDigioEnabled && (
            <p className={` form-label text-xxs italic font-medium w-2/3 `}>
              Note: This enables only Employee grant acceptance
            </p>
          )}
          {formik.values.isDigioEnabled && (
            <HStack className="gap-40">
              <HStack className="items-center gap-2" radioGroup="">
                <input
                  type="radio"
                  name="signatureOption"
                  className={`accent-orange-501 outline-hidden mr-1 cursor-pointer`}
                  checked={formik.values.useDigioAadhar}
                  onChange={(e) => {
                    formik.setFieldValue("useDigioAadhar", e.target.checked);
                    if (e.target.checked) {
                      formik.setFieldValue("useDigioElectronic", false);
                    }
                  }}
                />
                <Label className="mt-2">Use Aadhar for Signature</Label>
              </HStack>
              <HStack className="items-center gap-2">
                <input
                  type="radio"
                  name="signatureOption"
                  className={`accent-orange-501 outline-hidden mr-1 cursor-pointer`}
                  checked={formik.values.useDigioElectronic}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "useDigioElectronic",
                      e.target.checked
                    );
                    if (e.target.checked) {
                      formik.setFieldValue("useDigioAadhar", false);
                    }
                  }}
                />
                <Label className="mt-2">Use Electronic pad</Label>
              </HStack>
            </HStack>
          )}
        </VStack>

        {!isUserEsopViewer && (
          <HStack className="justify-between mt-12">
            <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              type="submit"
              className={`${
                !handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
                  ? " cursor-not-allowed"
                  : ""
              } flex items-center self-end justify-center`}
              disabled={
                isError ||
                handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
              }
              loading={isSettingsSaved}
            >
              Save Settings
            </ButtonPrimary>
          </HStack>
        )}
      </Box>
    </form>
  );
}

export default ESignSettings;
