/* eslint-disable consistent-return */
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress, Switch } from "@mui/material";
import { ChartData } from "chart.js";
import { format } from "date-fns";
import { Formik, FormikState, useFormikContext } from "formik";
import sumBy from "lodash/sumBy";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import VestingTypes from "../../constants/VestingTypes";
import {
  useCreateMilestones,
  useGetAllMilestones,
} from "../../queries/milestone";
import {
  useAddVestingTemplate,
  useVestingTemplates,
} from "../../queries/vestingTemplate";
import { useError } from "../../store/errorStore";
import { usePermissionStore } from "../../store/permissionStore";
import { useVestingScheduleDialog } from "../../store/useDialogStore";
import {
  AddVestingTemplateReq,
  AddVestingTemplateRes,
  Schedule,
  TriggerType,
  VestingTemplate,
  VestingType,
} from "../../types/VestingTemplate";
import {
  AddVestingMilestoneReq,
  ClosureType,
  MilestoneType,
  VestingMilestone,
} from "../../types/milestone";
import { determineUserAccessToResource } from "../../utils/auth";
import { formatWithTimeZone } from "../../utils/date";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { BarChart } from "./BarChart";
import { generateProjections } from "./generateProjections";
import {
  baseEventScheduleDto,
  baseTimeScheduleDto,
  vestingBaseValues,
} from "./initValues";
import Tooltip from "../../components/shared/Tooltip";

type AddOrEditTemplateProps = {
  mode?: "Edit" | "Add" | "View";
  onClose: () => void;
  template?: VestingTemplate;
  onCreate?: (template: AddVestingTemplateRes) => void;
};

export function AddOrEditVestingTemplate({
  mode = "Add",
  onClose = () => {},
  onCreate = () => {},
  template,
}: AddOrEditTemplateProps) {
  const { setState: setDialog } = useVestingScheduleDialog();
  const { permission } = usePermissionStore();
  const [selectedTemplate, setSelectedTemplate] = useState<
    VestingTemplate | undefined
  >();
  const { data: _vestingTemplates } = useVestingTemplates();
  const vestingTemplates = _vestingTemplates || [];
  const vestingScheduleTemplates = vestingTemplates?.filter(
    (schedule) => !schedule.isDefault
  );
  const { mutate: addTemplate, isLoading: addTemplateLoading } =
    useAddVestingTemplate();
  const { mutate: addMilestones, isLoading: addMilestonesLoading } =
    useCreateMilestones();
  const { mutate: editTemplate, isLoading: editTemplateLoading } =
    useAddVestingTemplate();
  const isLoading = mode === "Edit" ? editTemplateLoading : addTemplateLoading;

  const errorMessage = useError();

  const validationSchema = Yup.object().shape({
    cliffPeriod: Yup.number().required("Cliff period is required"),
    vestingTemplateName: Yup.string()
      .required("Required")
      .notOneOf(
        vestingTemplates
          .filter(
            (v) => v.vestingTemplateName !== template?.vestingTemplateName
          )
          .map((v) => v.vestingTemplateName),
        "Already Exists"
      ),
    timeScheduleDtos: Yup.array().of(
      Yup.object({
        percentage: Yup.number(),
        vestingInterval: Yup.number()
          .typeError("should be a number")
          .test("custom interval", "Invalid interval", (_value, { parent }) => {
            const { vestingInterval, vestingDuration } = parent as Schedule;
            if (vestingInterval === 0 && vestingDuration === 0) return true;
            if (!vestingInterval || !vestingDuration) return false;
            return Number.isInteger(vestingDuration / vestingInterval);
          }),
        vestingDuration: Yup.number().typeError("should be a number"),
      })
    ),
    eventScheduleDtos: Yup.array().of(
      Yup.object({
        percentage: Yup.number(),
        eventName: Yup.string().required("Event Name is required"),
        eventTargetDate: Yup.date(),
      })
    ),
    percentage: Yup.number().when("vestingType", {
      is: VestingType.CUSTOM,
      then: Yup.number().test(
        "percentage",
        "total percentage should be 100",
        (_value, context) => {
          const value = context.parent as AddVestingTemplateReq;
          if (
            (!value.timeScheduleDtos || value.timeScheduleDtos.length < 1) &&
            (!value.eventScheduleDtos || value.eventScheduleDtos.length < 1)
          )
            return true;
          const totalPercentage =
            sumBy(value.eventScheduleDtos, (dto) => dto.percentage || 0) +
            sumBy(value.timeScheduleDtos, (dto) => dto.percentage || 0);
          return totalPercentage === 100;
        }
      ),
    }),
  });

  function getInitialValues() {
    if ((mode === "Edit" || mode === "View") && template) {
      const temp = getValuesForEditing(template);
      temp?.timeScheduleDtos.sort(
        (a, b) => a.sequenceNumber - b.sequenceNumber
      );
      temp?.eventScheduleDtos.sort(
        (a, b) => a.sequenceNumber - b.sequenceNumber
      );
      return temp || vestingBaseValues;
    } else {
      const temp = getValuesForEditing(selectedTemplate);
      temp?.timeScheduleDtos.sort(
        (a, b) => a.sequenceNumber - b.sequenceNumber
      );
      temp?.eventScheduleDtos.sort(
        (a, b) => a.sequenceNumber - b.sequenceNumber
      );
      return temp || vestingBaseValues;
    }
  }

  function getFormValuesFromImport(
    template: VestingTemplate | undefined
  ): AddVestingTemplateReq | undefined {
    if (template) {
      return {
        ...template,
        id: "",
        vestingTemplateName: "",
        timeScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === TriggerType.TIME
          ),
        ],
        eventScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === TriggerType.EVENT
          ),
        ],
      };
    }
  }
  function getValuesForEditing(
    template: VestingTemplate | undefined
  ): AddVestingTemplateReq | undefined {
    if (template) {
      return {
        ...template,
        timeScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === TriggerType.TIME
          ),
        ],
        eventScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === TriggerType.EVENT
          ),
        ],
      };
    }
  }

  const isFractional = vestingScheduleTemplates.some(
    (template) => template.isFractional
  );

  function handleImport(
    e: any,
    setValues: (
      values: React.SetStateAction<AddVestingTemplateReq>,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    const template = vestingTemplates.find(
      (t) => t.id.toString() === e.target.value
    );
    if (!template) return;
    setSelectedTemplate(template);
    setValues(getFormValuesFromImport(template) ?? vestingBaseValues);
  }
  function submitTemplate(
    resetForm: (
      nextState?: Partial<FormikState<AddVestingTemplateReq>> | undefined
    ) => void,
    values: AddVestingTemplateReq,
    template: AddVestingTemplateReq
  ) {
    const timeScheduleDtos =
      template.vestingType === VestingType.STANDARD ||
      (template.vestingType === VestingType.CUSTOM && values.includeTimeVesting)
        ? [
            ...template.timeScheduleDtos.map((s) => ({
              ...s,
              percentage: s.percentage / 100,
            })),
          ]
        : [];
    const eventScheduleDtos =
      template.vestingType === VestingType.CUSTOM && values.includeEventVesting
        ? [
            ...template.eventScheduleDtos.map((s) => ({
              ...s,
              eventTargetDate: formatWithTimeZone(
                s.eventTargetDate || new Date().toString()
              ),
              percentage: s.percentage / 100,
            })),
          ]
        : [];
    template = {
      ...template,
      timeScheduleDtos,
      eventScheduleDtos,
    };
    template.isDefault = false;
    if (mode === "Edit") {
      editTemplate(template, {
        onSuccess: (data) => {
          resetForm();
          onCreate(data.data);
          toast("Template edited successfully", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else {
      addTemplate(template, {
        onSuccess: (data) => {
          resetForm();
          onCreate(data.data);
          toast("Template Added successfully", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  }

  return (
    <Formik
      initialValues={getInitialValues() || vestingBaseValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        const template = { ...values } as AddVestingTemplateReq;
        let errors = false;
        if (
          template.eventScheduleDtos.some((event) => event.newlyAdded === true)
        ) {
          const newMilestones = template.eventScheduleDtos.filter(
            (event) => event.newlyAdded
          );
          const reqDtos = [];
          for (const newMilestone of newMilestones) {
            const data: AddVestingMilestoneReq = {
              milestoneName: newMilestone.eventName,
              closureType: ClosureType.Manual,
              milestoneType: MilestoneType.CompanyWide,
              targetDate: new Date(newMilestone.eventTargetDate),
              notes: "",
            };
            reqDtos.push(data);
          }
          addMilestones(reqDtos, {
            onSuccess: (data) => {
              const newlyaddedMilestones = data.data;
              newMilestones.forEach((milestone) => {
                milestone.milestoneId =
                  newlyaddedMilestones.find(
                    (e) => e.milestoneName === milestone.eventName
                  )?.id ?? "";
              });
              submitTemplate(resetForm, values, template);
            },
            onError: () => {
              errors = true;
              toast(
                "Unable to create the milestone Please check milestone names",
                { type: "error", autoClose: 2000 }
              );
            },
          });
        } else {
          submitTemplate(resetForm, values, template);
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        getFieldProps,
        isValid,
        resetForm,
        handleSubmit,
        setValues,
        setFieldValue,
        setFieldTouched,
      }) => {
        useEffect(() => {
          if (values.timeScheduleDtos.length === 0) {
            const schedules = [baseTimeScheduleDto];
            setFieldValue("timeScheduleDtos", schedules);
          } else {
            setFieldValue("includeTimeVesting", true);
          }
          if (
            values.timeScheduleDtos.length === 1 &&
            !values.timeScheduleDtos[0].percentage
          ) {
            const schedules = [
              { ...values.timeScheduleDtos[0], percentage: 100 },
            ];
            setFieldValue("timeScheduleDtos", schedules);
          }
          setFieldTouched("timeScheduleDtos", false);
        }, [values.vestingType]);
        return (
          <VStack className="w-full gap-9">
            <HStack className="gap-8 ">
              <VStack className="flex-1 gap-9">
                <div>
                  <Label className="text-sm font-normal">
                    Import Vesting Schedule
                  </Label>
                  <Select
                    disabled={mode === "Edit" || mode === "View"}
                    options={vestingTemplates}
                    valueGetter={(o) => o.id}
                    textGetter={(o) => o.vestingTemplateName}
                    onChange={(e) => handleImport(e, setValues)}
                  />
                </div>
                <div>
                  <Label className="text-sm font-normal">Vesting Type</Label>
                  <Select
                    disabled={mode === "View"}
                    options={VestingTypes}
                    isPlaceHolderDisabled={true}
                    valueGetter={(o) => o.text}
                    textGetter={(o) => o.value}
                    {...getFieldProps("vestingType")}
                  />
                </div>
              </VStack>
              <div className="flex-1">
                <ProjectionChart
                  includeTimeVesting={values.includeTimeVesting}
                  includeEventVesting={values.includeEventVesting}
                  isFractional={isFractional}
                />
              </div>
            </HStack>
            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Vesting Template Name
                </Label>
                <Input
                  disabled={mode === "View"}
                  {...getFieldProps("vestingTemplateName")}
                />
                {touched.vestingTemplateName && errors.vestingTemplateName && (
                  <Error text={errors.vestingTemplateName} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Vesting Cliff (Months)
                </Label>
                <Input
                  type="number"
                  disabled={mode === "View"}
                  className=""
                  {...getFieldProps("cliffPeriod")}
                />
                {touched.cliffPeriod && errors.cliffPeriod && (
                  <Error text={errors.cliffPeriod} />
                )}
              </div>
            </HStack>
            {values.vestingType === VestingType.STANDARD && (
              <HStack className="gap-8">
                <div className="flex-1">
                  <Label className="text-sm font-normal">
                    Vesting Interval (Months)
                  </Label>
                  <Input
                    type="number"
                    disabled={mode === "View"}
                    {...getFieldProps("timeScheduleDtos[0].vestingInterval")}
                  />
                  {errors &&
                    errors.timeScheduleDtos &&
                    typeof errors.timeScheduleDtos[0] === "object" && (
                      <Error
                        text={errors.timeScheduleDtos[0]?.vestingInterval}
                      />
                    )}
                </div>
                <div className="flex-1">
                  <Label className="text-sm font-normal">
                    Vesting Period (Months)
                  </Label>
                  <Input
                    type="number"
                    disabled={mode === "View"}
                    className=""
                    {...getFieldProps("timeScheduleDtos[0].vestingDuration")}
                  />
                  {errors &&
                    errors.timeScheduleDtos &&
                    typeof errors.timeScheduleDtos[0] === "object" && (
                      <Error
                        text={errors.timeScheduleDtos[0]?.vestingDuration}
                      />
                    )}
                </div>
              </HStack>
            )}
            {values.vestingType === VestingType.CUSTOM && mode !== "View" && (
              <>
                <HStack className="flex-row-reverse items-center justify-end">
                  <Switch
                    checked={values.includeTimeVesting}
                    onChange={() => {
                      setFieldValue(
                        "includeTimeVesting",
                        !values.includeTimeVesting
                      );
                    }}
                    value={values.includeTimeVesting}
                  />
                  <HandleSwitchToggle />
                  <Label className="mb-0 text-sm font-medium">
                    Include Time Vesting
                  </Label>
                </HStack>
                {values.includeTimeVesting && <TimeScheduleDtos mode={mode} />}
                <HStack className="flex-row-reverse items-center justify-end">
                  <Switch
                    onChange={() => {
                      setFieldValue(
                        "includeEventVesting",
                        !values.includeEventVesting
                      );
                    }}
                    value={values.includeEventVesting}
                    checked={values.includeEventVesting}
                  />
                  <HandleSwitchToggle />
                  <Label className="mb-0 text-sm font-medium">
                    Include Event Vesting
                  </Label>
                </HStack>
                {values.includeEventVesting && (
                  <EventScheduleDtos mode={mode} />
                )}
              </>
            )}
            {values.vestingType === VestingType.CUSTOM && mode === "View" && (
              <>
                <HStack className="items-center">
                  {values.includeTimeVesting && (
                    <Label className="mb-0 text-sm font-normal">
                      Time Vesting
                    </Label>
                  )}
                </HStack>
                {values.includeTimeVesting && <TimeSchedule />}
                <HStack className="items-center">
                  <Label className="mb-0 text-sm font-normal">
                    Event Vesting
                  </Label>
                </HStack>
                {values.includeEventVesting && <EventSchedule />}
              </>
            )}
            <HStack className="justify-between my-10 justify">
              <ButtonPrimary1 onClick={() => onClose()}>Back</ButtonPrimary1>
              <ButtonPrimary1
                onClick={() => {
                  resetForm();
                }}
              >
                Clear
              </ButtonPrimary1>
              <div className="flex items-center">
                {mode !== "View" && (
                  <ButtonPrimary
                    className={`${!isValid && "bg-gray-400"}`}
                    onClick={(e) => {
                      handleSubmit();
                    }}
                    loading={isLoading}
                  >
                    {mode === "Edit" ? "Update Template" : "Create Template"}
                  </ButtonPrimary>
                )}
                {mode === "View" && (
                  <ButtonPrimary
                    disabled={
                      (isLoading && !template?.isEditable) ||
                      !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.VestingSchedule,
                        DefaultAction.Read
                      )
                    }
                    className={`${
                      !template?.isEditable &&
                      "bg-gray-400 hover:bg-gray-400 cursor-not-allowed"
                    }`}
                    onClick={(e) => {
                      if (template?.isEditable) {
                        setDialog({ open: false });
                        setDialog({ open: true, template, mode: "Edit" });
                      }
                    }}
                    loading={isLoading}
                  >
                    Edit Template
                  </ButtonPrimary>
                )}
              </div>
            </HStack>
          </VStack>
        );
      }}
    </Formik>
  );
}

function HandleSwitchToggle() {
  const formik = useFormikContext<AddVestingTemplateReq>();
  useEffect(() => {
    if (!formik.values.includeTimeVesting)
      formik.setFieldValue("timeScheduleDtos", [baseTimeScheduleDto]);
  }, [formik.values.includeTimeVesting]);
  useEffect(() => {
    if (!formik.values.includeEventVesting)
      formik.setFieldValue("eventScheduleDtos", []);
  }, [formik.values.includeEventVesting]);
  return <div />;
}

export function AddVestingTemplateWrapper({
  mode = "Add",
  onClose = () => {},
  onCreate = () => {},
  template,
}: AddOrEditTemplateProps) {
  return (
    <div>
      <div className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit"
            ? "Edit Template"
            : mode === "View"
            ? template?.vestingTemplateName
            : "Add New Template"}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <div className="px-10 pt-4">
        <AddOrEditVestingTemplate
          mode={mode}
          onClose={onClose}
          template={template}
        />
      </div>
    </div>
  );
}

function TimeScheduleDtos({ mode }: { mode?: "Edit" | "Add" | "View" }) {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;

  function handleAdd(index: number) {
    const schedules = [...values.timeScheduleDtos];
    const newSchedule = baseTimeScheduleDto;
    schedules.splice(index + 1, 0, newSchedule);
    setFieldValue("timeScheduleDtos", schedules);
  }
  function handleDelete(index: number) {
    setFieldValue(
      "timeScheduleDtos",
      values?.timeScheduleDtos?.filter((_, i) => i !== index)
    );
  }
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Percentage (%)</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Interval (Months)
          </Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Duration (Months)
          </Label>
        </div>
        <div className="flex self-center flex-1 gap-2">
          {values?.timeScheduleDtos?.length === 0 && (
            <>
              <ButtonSecondary
                className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2"
                onClick={() => handleAdd(0)}
              >
                Add a Row
              </ButtonSecondary>
              <button
                onClick={() => handleDelete(0)}
                className="text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </>
          )}
        </div>
      </HStack>
      {values?.timeScheduleDtos?.map((_, index) => {
        const timeScheduleDto = values.timeScheduleDtos[index];
        const fieldTouched = touched?.timeScheduleDtos?.[index];
        const err = errors?.timeScheduleDtos?.[index] as any;
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                type="number"
                max={100}
                {...getFieldProps(`timeScheduleDtos[${index}].percentage`)}
              />
              {fieldTouched?.percentage && err?.percentage && (
                <Error text={err?.percentage} />
              )}
              {fieldTouched?.percentage && <Error text={errors?.percentage} />}
            </div>
            <div className="flex-1">
              <Input
                type="number"
                className=""
                {...getFieldProps(`timeScheduleDtos[${index}].vestingInterval`)}
              />
              {fieldTouched?.vestingInterval && err?.vestingInterval && (
                <Error text={err?.vestingInterval} />
              )}
            </div>
            <div className="flex-1">
              <Input
                type="number"
                {...getFieldProps(`timeScheduleDtos[${index}].vestingDuration`)}
              />
              {fieldTouched?.vestingDuration && err?.vestingDuration && (
                <Error text={err?.vestingDuration} />
              )}
            </div>
            <div className="flex self-center flex-1 gap-2">
              <ButtonSecondary
                className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2"
                onClick={() => handleAdd(index)}
              >
                Add a Row
              </ButtonSecondary>
              <button
                onClick={() => handleDelete(index)}
                className="text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function TimeSchedule() {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values, getFieldProps } = formik;
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Percentage (%)</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Interval (Months)
          </Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Duration (Months)
          </Label>
        </div>
      </HStack>
      {values?.timeScheduleDtos?.map((_, index) => {
        const timeScheduleDto = values.timeScheduleDtos[index];
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input disabled={true} value={timeScheduleDto.percentage} />
            </div>
            <div className="flex-1">
              <Input
                disabled={true}
                {...getFieldProps(`timeScheduleDtos[${index}].vestingInterval`)}
              />
            </div>
            <div className="flex-1">
              <Input
                disabled={true}
                {...getFieldProps(`timeScheduleDtos[${index}].vestingDuration`)}
              />
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function EventScheduleDtos({ mode }: { mode?: "Edit" | "Add" | "View" }) {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { data: _allMilestones, isFetched } = useGetAllMilestones();
  const [allMilestones, setAllMilestones] = useState<VestingMilestone[]>([]);
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;

  function handleAdd() {
    const schedules = [...values.eventScheduleDtos];
    const newSchedule = { ...baseEventScheduleDto };
    newSchedule.newlyAdded = true;
    schedules.splice(schedules.length + 1, 0, newSchedule);
    setFieldValue("eventScheduleDtos", schedules);
  }
  function handleAddExistingEvent(id: string) {
    const schedules = [...values.eventScheduleDtos];
    const oldSchedule = { ...baseEventScheduleDto };
    const selectedMilestone = allMilestones.find(
      (milestone) => milestone.id === id
    );
    oldSchedule.eventName = selectedMilestone?.milestoneName || "";
    oldSchedule.newlyAdded = false;
    oldSchedule.eventTargetDate = format(
      new Date(selectedMilestone?.targetDate || new Date()),
      "yyyy-MM-dd"
    );
    oldSchedule.milestoneId = selectedMilestone?.id || "";
    schedules.splice(schedules.length + 1, 0, oldSchedule);
    setFieldValue("eventScheduleDtos", schedules);

    const milestones = allMilestones.filter(
      (milestone) => milestone.id !== selectedMilestone?.id
    );
    setAllMilestones(milestones);
  }
  function handleDelete(index: number) {
    const deleteMilestone = values?.eventScheduleDtos?.find(
      (_, i) => i === index
    );
    setFieldValue(
      "eventScheduleDtos",
      values?.eventScheduleDtos?.filter((_, i) => i !== index)
    );

    if (deleteMilestone) {
      const existingMilestone = _allMilestones?.find(
        (milestone) => milestone.id === deleteMilestone.milestoneId
      );
      if (existingMilestone) {
        setAllMilestones((prev) => [...prev, existingMilestone]);
      }
    }
  }
  useEffect(() => {
    setAllMilestones(_allMilestones || []);
  }, [isFetched]);
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1">
          <Select
            placeholder="Select a milestone"
            value=""
            options={allMilestones || []}
            valueGetter={(o) => o.id}
            textGetter={(o) => o.milestoneName}
            onChange={(e) => handleAddExistingEvent(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <ButtonPrimary className="px-2 text-sm" onClick={() => handleAdd()}>
            Create milestone
          </ButtonPrimary>
        </div>
        <div className="flex-1"></div>
        <div className="flex-1"></div>
      </HStack>
      {values.eventScheduleDtos.length > 0 && (
        <HStack className="mt-4 gap-x-8">
          <div className="flex-1 ">
            <Label className="text-sm font-normal">Percentage (%)</Label>
          </div>
          <div className="flex-1 ">
            <Label className="text-sm font-normal">Event Name</Label>
          </div>
          <div className="flex-1 ">
            <Label className="text-sm font-normal">Target Date</Label>
          </div>
          <div className="flex-1 "></div>
        </HStack>
      )}
      {values?.eventScheduleDtos?.map((_, index) => {
        const eventScheduleDto = values.eventScheduleDtos[index];
        const fieldTouched = touched?.eventScheduleDtos?.[index];
        const err = errors?.eventScheduleDtos?.[index] as any;
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                type="number"
                {...getFieldProps(`eventScheduleDtos[${index}].percentage`)}
              />
              {fieldTouched?.percentage && err?.percentage && (
                <Error text={err?.percentage} />
              )}
              <Error text={errors?.percentage} />
            </div>
            <div className="flex-1">
              <Input
                type="text"
                className=""
                disabled={!_.newlyAdded}
                {...getFieldProps(`eventScheduleDtos[${index}].eventName`)}
              />
              {fieldTouched?.eventName && err?.eventName && (
                <Error text={err?.eventName} />
              )}
            </div>
            <div className="flex-1">
              <Input
                type="date"
                className=""
                disabled={!_.newlyAdded}
                {...getFieldProps(
                  `eventScheduleDtos[${index}].eventTargetDate`
                )}
              />
              {fieldTouched?.eventTargetDate && err?.eventTargetDate && (
                <Error text={err?.eventTargetDate} />
              )}
            </div>
            <div className="flex self-center flex-1 gap-2">
              <button
                onClick={() => handleDelete(index)}
                className="text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function EventSchedule() {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values, getFieldProps } = formik;
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Percentage (%)</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Event Name</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Target Date</Label>
        </div>
      </HStack>
      {values?.eventScheduleDtos?.map((_, index) => {
        const eventScheduleDto = values.eventScheduleDtos[index];
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                value={Math.floor(eventScheduleDto.percentage)}
                disabled={true}
              />
            </div>
            <Tooltip
              text={eventScheduleDto.eventName}
              _className="w-[200px] max-w-xs break-words whitespace-normal"
            >
              <div className="flex-1">
                <Input
                  disabled={true}
                  {...getFieldProps(`eventScheduleDtos[${index}].eventName`)}
                />
              </div>
            </Tooltip>

            <div className="flex-1">
              <Input
                disabled={true}
                {...getFieldProps(
                  `eventScheduleDtos[${index}].eventTargetDate`
                )}
              />
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function ProjectionChart({
  includeTimeVesting,
  includeEventVesting,
  isFractional,
}: {
  includeTimeVesting: boolean;
  includeEventVesting: boolean;
  isFractional: boolean;
}) {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values: template } = formik;
  const timeSchedules = template?.timeScheduleDtos?.map((s) => ({
    ...s,
    percentage: s.percentage / 100 || 0,
  }));
  const eventSchedules = template?.eventScheduleDtos?.map((s) => ({
    ...s,
    percentage: s.percentage / 100 || 0,
  }));
  let schedules = [
    ...(includeTimeVesting || template.vestingType === VestingType.STANDARD
      ? timeSchedules || []
      : []),
    ...(includeEventVesting ? eventSchedules || [] : []),
  ];
  schedules = schedules.map((s, i) => ({ ...s, sequenceNumber: i + 1 }));
  const hasUndefined = schedules.some(
    (s) =>
      (s.vestingInterval !== 0 && !s.vestingInterval) ||
      (s.vestingDuration !== 0 && !s.vestingDuration)
  );
  const vestings = hasUndefined
    ? []
    : generateProjections({
        cliffPeriod: template.cliffPeriod,
        optionsGranted: 100,
        schedules,
        vestingStartDate: new Date(new Date().getFullYear(), 0, 1),
        vestingType: template.vestingType,
        isFractional,
      });
  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings.map((v) => format(v.date, "MMM yy")),
    datasets: [
      {
        label: "to date",
        data: vestings.map((v) =>
          isFractional
            ? parseFloat(
                (v.accumulatedVestedOptionsForGrant - v.vestedOptions).toFixed(
                  4
                )
              )
            : v.accumulatedVestedOptionsForGrant - v.vestedOptions
        ),
        backgroundColor: "#F8B195",
      },
      {
        label: "on date",
        data: vestings.map((v) =>
          isFractional
            ? parseFloat(v.vestedOptions.toFixed(4))
            : v.vestedOptions
        ),
        backgroundColor: "#F67280",
        borderRadius: 4,
      },
    ],
  };

  return <BarChart data={data} isFractional={isFractional} />;
}
