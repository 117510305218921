import { useMutation, useQuery } from "react-query";
import {
  doLogin,
  doResendOtp,
  doVerifyOtp,
  getCompanyExists,
  getUserAssociatedCompanies,
  getUserCompanyDetails,
  getUserDetails,
  getUserRoleAndPermission,
  setDefaultProduct,
  switchCompany,
} from "../api/Esop";
import { LoginResponse } from "../types/Login";
import { queryClient } from "./client";

export function uselogin() {
  return useMutation({
    mutationKey: "login",
    mutationFn: doLogin,
    onSuccess: (loginDto) => {
      if (loginDto && loginDto?.data?.reason !== "OTP Sent") {
        handleLogin(loginDto);
      }
      queryClient.invalidateQueries("userRoleAndPermission");
      queryClient.refetchQueries("userRoleAndPermission");
    },
  });
}

export function useUserDetails() {
  return useQuery({
    queryKey: "userDetails",
    queryFn: getUserDetails,
  });
}
export function useGetUserCompanyDetails() {
  return useQuery({
    queryKey: "companyDetails",
    queryFn: getUserCompanyDetails,
  });
}
export function useGetUserCompanies() {
  return useQuery({
    queryKey: "userCompanies",
    queryFn: getUserAssociatedCompanies,
    // onSuccess: (data) => {
    //   useAuthorizationStore.setState((store) => ({
    //     ...store,
    //     ...data.authorities[0],
    //   }));
    //   useAuthStore.setState((store) => ({
    //     ...store,
    //     user: data,
    //   }));
    // },
  });
}

export function useVerifyOtp() {
  return useMutation({
    mutationKey: "verification",
    mutationFn: doVerifyOtp,
    onSuccess: (verifiedLoginDto) => {
      if (verifiedLoginDto) handleLogin(verifiedLoginDto);
    },
    onError: () => {},
  });
}
export function useResendOtp() {
  return useMutation({
    mutationKey: "resend",
    mutationFn: doResendOtp,
  });
}

function handleLogin(loginDto: LoginResponse) {
  const accesstoken = loginDto.headers.accesstoken;
  if (accesstoken) {
    localStorage.setItem("accesstoken", accesstoken);
  }
  queryClient.invalidateQueries("userDetails");
}

export function useGetCompanyExists(companyId: string) {
  return useQuery({
    queryKey: ["esopCompanyExists", companyId],
    queryFn: getCompanyExists,
  });
}

export function useSetDefaultProduct() {
  return useMutation({
    mutationKey: "setDefaultProduct",
    mutationFn: setDefaultProduct,
    onSuccess: () => {},
    onError: () => {},
  });
}
export function useSwitchCompany() {
  return useMutation({
    mutationKey: "switchCompany",
    mutationFn: switchCompany,
    onSuccess: (data) => {
      queryClient.resetQueries();
      queryClient.refetchQueries();
    },
    onError: () => {},
  });
}
export function useGetUserRoleAndPermission() {
  return useQuery({
    queryKey: "userRoleAndPermission",
    queryFn: getUserRoleAndPermission,
    onSuccess: (data) => {},
    onError: (data) => {},
    enabled: false,
  });
}
