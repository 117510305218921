/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ButtonSecondary, HStack, VStack } from "../../../../components/utils";
import jsonContent from "../../../../dummyData.json";
import { useOrgTemplateStore } from "../../../../store/useOrgTemplateStore";
import { RandomDepartmentColorGenerator } from "../utils/RandomDepartmentColorGenerator";
import OrganiSationStructureDataProcessor, {
  OrgDataProcessor,
} from "../utils/OrgStructureJSONDataProc";
import { useEmployeeTemplate } from "../../../../queries/employees";

const CompanyStructureTemplateDialog = ({
  open,
  onClose = () => {},
  onStepChange = () => {},
}: {
  open: boolean;
  onClose: () => void;
  onStepChange: () => void;
}) => {
  const [jsonData, setJsonData] = useState<OrgDataProcessor>();
  const {
    selectedCompany,
    setSelectedCompany,
    setSelectedCompanyData,
    setEmployeeListUploaded,
  } = useOrgTemplateStore();
  const [companies, setCompanies] = useState<string[]>();
  const [teamSize, setTeamSizes] = useState<Map<string, number>>();
  const [allDepartments, setAllDepartments] = useState<[string, string[]][]>();

  useEffect(() => {
    setJsonData(jsonContent as unknown as OrgDataProcessor);
  }, [jsonContent]);

  useEffect(() => {
    if (jsonData) {
      const processedData = new OrganiSationStructureDataProcessor(jsonData);
      setCompanies(processedData.getAllCompaniesFromData());
      setAllDepartments(
        Array.from(processedData.getAllDepartmentsAndLevelsForTemplate())
      );
      setEmployeeListUploaded(false);
      setTeamSizes(processedData.getAllCompaniesTeamSizes());
    }
  }, [jsonData]);

  function getRolesByLevelAndDepartment(
    companyName: string,
    departmentName: string,
    level: string
  ) {
    const processedData = new OrganiSationStructureDataProcessor(jsonData);
    const roles = processedData.getRolesForTemplate(
      companyName,
      departmentName,
      level
    );
    return roles.length > 0 ? roles.join(",") : "-";
  }

  const onClickedStart = (companyName: string) => {
    if (!selectedCompany)
      return toast("Please select a template", { type: "error" });
    const processedData = new OrganiSationStructureDataProcessor(jsonData);
    setSelectedCompanyData(
      processedData.getCompanyDataFromCompanyName(companyName)
    );
    onClose();
    onStepChange();
    return "done";
  };
  let departmentIndex = 0;
  let departmentIndex2 = 0;
  let prevDept = "";

  return (
    <VStack className="">
      <div className="font-semibold p-7">
        <div className="flex justify-between border-b-[0.5px] pb-2">
          <VStack className="gap-2 text-lg2 text-black-501">
            <span>Choose your Organization Structure Template</span>
            <span className="text-sm4 text-gray-401">
              Provide details of your organisation’s hierarchy, employees, and
              key personnel
            </span>
          </VStack>
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </div>
      </div>
      <HStack className="w-full gap-1 py-4 pl-4 overflow-x-auto">
        {companies &&
          companies.map((companyName, i) => (
            <HStack className="gap-1 min-w-max" key={i}>
              {i === 0 && (
                <VStack className="gap-6">
                  <VStack className="">
                    <VStack className="gap-6 p-6 text-center">
                      <p className="text-base font-medium">&nbsp;</p>
                      <p className="text-xs text-center">&nbsp;</p>
                      <HStack className="font-semibold text-tertiaryText">
                        &nbsp;
                      </HStack>
                      <HStack className="pt-[13px]">&nbsp;</HStack>
                    </VStack>
                  </VStack>
                  <VStack className="">
                    {allDepartments &&
                      allDepartments.map(([departmentName, levels], index) => {
                        const existingDept = departmentName;
                        if (existingDept === prevDept) index -= 1;
                        const color = RandomDepartmentColorGenerator(
                          departmentIndex,
                          departmentName,
                          true
                        );
                        const bgColor = `${color}45`;
                        const textColor = color;

                        departmentIndex =
                          departmentIndex > 12 ? 0 : departmentIndex + 1;
                        prevDept = existingDept;

                        return (
                          <VStack key={index} className="w-full">
                            <div
                              className="p-2 py-4 font-semibold text-center"
                              style={{
                                background: bgColor,
                                color: textColor,
                              }}
                            >
                              {index === 0 ? "Levels" : "\xa0"}
                            </div>
                            {levels &&
                              levels.map((level, ind) => (
                                <VStack
                                  className={`font-semibold text-center py-4 px-1 ${
                                    ind % 2 === 0 ? "" : "bg-[#F1F4F965]"
                                  }`}
                                  key={ind}
                                >
                                  {level}
                                </VStack>
                              ))}
                          </VStack>
                        );
                      })}
                  </VStack>
                </VStack>
              )}
              {
                <VStack key={i} className="gap-6 min-w-max">
                  <VStack
                    className={`${
                      selectedCompany === companyName &&
                      "bg-secondary rounded-xl !text-white group"
                    }`}
                  >
                    <VStack className="gap-6 p-6 text-center ">
                      <p
                        className={`text-base font-medium text-orange-501 ${
                          selectedCompany === companyName && "!text-white"
                        }`}
                      >
                        {companyName}
                      </p>
                      <p
                        className={`text-xs text-center text-gray-light ${
                          selectedCompany === companyName && "!text-white"
                        }`}
                      >
                        Optimal for {teamSize?.get(companyName)} team size and{" "}
                        <br /> new startup
                      </p>
                      <HStack className="items-center justify-center w-full text-center">
                        {!(
                          selectedCompany && selectedCompany === companyName
                        ) ? (
                          <ButtonSecondary
                            className="!text-orange-501 "
                            onClick={() => setSelectedCompany(companyName)}
                          >
                            Select
                          </ButtonSecondary>
                        ) : (
                          <ButtonSecondary
                            className="!text-white !bg-secondaryText"
                            onClick={() => onClickedStart(companyName)}
                          >
                            Start
                          </ButtonSecondary>
                        )}
                      </HStack>
                      <HStack className="pt-1"></HStack>
                    </VStack>
                  </VStack>
                  <VStack className="">
                    {allDepartments &&
                      allDepartments.map(([departmentName, levels], index) => {
                        const existingDept = departmentName;
                        if (existingDept === prevDept) index -= 1;
                        const backgroundColor = `${RandomDepartmentColorGenerator(
                          departmentIndex2,
                          departmentName,
                          true
                        )}45`;
                        const textColor = RandomDepartmentColorGenerator(
                          departmentIndex2,
                          departmentName,
                          true
                        );
                        departmentIndex2 =
                          departmentIndex2 > 12 ? 0 : departmentIndex2 + 1;
                        prevDept = existingDept;
                        return (
                          <VStack key={index} className="w-full">
                            <div
                              className="p-2 py-4 font-semibold text-center"
                              style={{
                                background: backgroundColor,
                                color: textColor,
                              }}
                            >
                              {departmentName} Department{" "}
                            </div>
                            {levels &&
                              levels.map((level, ind) => (
                                <VStack
                                  className={`font-semibold text-center py-4 px-1 ${
                                    ind % 2 === 0 ? "" : "bg-[#F1F4F965]"
                                  }`}
                                  key={ind}
                                >
                                  {getRolesByLevelAndDepartment(
                                    companyName,
                                    departmentName,
                                    level
                                  )}
                                </VStack>
                              ))}
                          </VStack>
                        );
                      })}
                  </VStack>
                </VStack>
              }
            </HStack>
          ))}
      </HStack>
    </VStack>
  );
};

export default CompanyStructureTemplateDialog;
