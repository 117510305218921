import { useMutation, useQuery } from "react-query";
import {
  deleteTableState,
  getTableFilterState,
  postTableFilterState,
} from "../api/AgGridCache";
import { queryClient } from "./client";

export function useGetTableFilterState(tableId: string, companyId: string) {
  return useQuery({
    queryKey: ["getTableFilterState", tableId, companyId], // Include companyId
    queryFn: getTableFilterState,
    staleTime: 0,
    cacheTime: 0,
  });
}
export function usePostTableFilterState() {
  return useMutation({
    mutationKey: "postTableFilterState",
    mutationFn: postTableFilterState,
  });
}

export function useDeleteTableState() {
  return useMutation({
    mutationKey: "deleteTableState",
    mutationFn: deleteTableState,
    onSuccess: () => {
      queryClient.invalidateQueries("getTableFilterState");
    },
  });
}
