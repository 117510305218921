import { AgGridReact } from "ag-grid-react";
import { ColDef, ColGroupDef, GridApi } from "ag-grid-community";
import { useMemo, useRef, useState, useEffect } from "react";
import _ from "lodash";
import { Dialog } from "@mui/material";
import SearchInput from "../../../components/shared/SearchInput";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../../components/utils";
import { KeyRole, Levels } from "../../../types/modelling";
import { Action, CTADropdown } from "../../../components/shared/Dropdown";
import {
  getSalaryRangeForUI,
  getSingleSalaryRangeForUI,
} from "../modellingUtils";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import AddKeyRoleDialog from "./dialogs/KeyRoleDialog";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { RandomDepartmentColorGenerator } from "./utils/RandomDepartmentColorGenerator";

export default function KeyRoles() {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  };
  const { selectedCompanyData, addorDeleteKeyRoleToCompanyData } =
    useOrgTemplateStore();
  const [keyRoles, setKeyRoles] = useState<Array<KeyRole>>([]);
  const rowData = useMemo(() => {
    if (!keyRoles) return [];
    return keyRoles.map((keyRole) => ({
      ...keyRole,
      actions: (
        <CTADropdown
          dropdownClassName=" xl:right-10 lg:right-8 md:right-2"
          actions={[
            {
              name: "Remove Key Role",
            },
          ]}
          onAction={(action) => {
            handleAction(keyRole, action);
          }}
        />
      ),
    }));
  }, [keyRoles]);

  useEffect(() => {
    if (selectedCompanyData && selectedCompanyData.departments) {
      const data: KeyRole[] = [];
      Object.entries(selectedCompanyData.departments).forEach(
        ([departmentName, depData]) => {
          Object.entries(depData.levels).forEach(([levelName, levData]) => {
            Object.entries(levData.roles).forEach(([roleName, roleData]) => {
              if (roleData.keyRole) {
                data.push({
                  department: departmentName,
                  level: levelName,
                  role: roleName,
                  salaryRange: {
                    min: roleData.salaryRange.min,
                    max: roleData.salaryRange.max,
                  },
                });
              }
            });
          });
        }
      );
      setKeyRoles(data);
    }
  }, [selectedCompanyData]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      cellStyle: {
        lineHeight: "1.5",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  let index = 0;
  let prevDept = "";
  const columnDefs: ColDef[] = [
    {
      headerName: "DEPARTMENT",
      autoHeight: true,
      field: "department",
      initialWidth: 150,
      minWidth: 150,
      width: 150,
      flex: 1,
      filter: "agSetColumnFilter",
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellStyle: (params: any) => {
        const existingDept = params.value;
        if (existingDept === prevDept) index -= 1;
        const color = RandomDepartmentColorGenerator(index, existingDept);
        index = index > 12 ? 0 : index + 1;
        prevDept = existingDept;
        return {
          color: "#ffffff",
          backgroundColor: color,
          display: "flex",
          "align-items": "center",
          lineHeight: "1.5",
          "justify-content": "center",
        };
      },
    },
    {
      headerName: "ROLE",
      autoHeight: true,
      field: "role",
      flex: 1,
      filter: "agSetColumnFilter",
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName:
        selectedCompanyData && selectedCompanyData.employeeListUploaded
          ? "Salary"
          : "Salary Range",
      autoHeight: true,
      field: "salaryRange",
      filter: "agSetColumnFilter",
      initialWidth: 150,
      valueFormatter: (params) => {
        if (selectedCompanyData && selectedCompanyData.employeeListUploaded) {
          return params.data
            ? getSingleSalaryRangeForUI(
                params.data?.salaryRange.min,
                currencySymbol,
                currencyType
              )
            : getSingleSalaryRangeForUI(
                params.value.min,
                currencySymbol,
                currencyType
              );
        }
        return params.data
          ? getSalaryRangeForUI(
              params.data?.salaryRange,
              currencySymbol,
              currencyType
            )
          : getSalaryRangeForUI(params.value, currencySymbol, currencyType);
      },
      flex: 1,
      minWidth: 150,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: CTADropdown,
    },
  ];

  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });

  function handleAction(keyRole: KeyRole, action: Action) {
    if (action.name === "Remove Key Role") {
      addorDeleteKeyRoleToCompanyData(
        keyRole.department,
        keyRole.level,
        keyRole.role,
        false
      );
    }
  }

  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Assign Key Roles
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Add key roles to all departments of the organisation along with their
          corresponding salary range.
        </HStack>
      </VStack>
      <HStack className="justify-end gap-8 pt-16 pr-5">
        <div className="flex gap-6 text-xs text-center justify-evenly">
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
          <ButtonPrimary
            onClick={() => {
              setDialog({ open: !dialog.open });
            }}
            disabled={!selectedCompanyData?.departments}
          >
            Add
          </ButtonPrimary>
        </div>
      </HStack>
      <Dialog
        maxWidth="xl"
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
      >
        <div className="w-[700px] h-[420px] min-h-min mx-auto bg-white rounded-lg">
          <AddKeyRoleDialog
            open={dialog.open}
            onClose={() => setDialog({ open: false })}
          />
        </div>
      </Dialog>
      <HStack className="justify-between w-full pt-6 pb-6 pl-8">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            suppressRowTransform={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}
