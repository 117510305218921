import React from "react";
import { VStack } from "../components/utils";

const GenericTableHeader = ({
  heading,
  subHeading,
  count,
  additionalInfo,
}: {
  heading: string;
  subHeading: string;
  count?: number;
  additionalInfo?: React.ReactNode;
}) => (
  <div className="flex flex-col px-6 lg:justify-start">
    <p className="text-lg font-medium text-headerColor whitespace-nowrap">
      {heading}
    </p>

    <VStack>
      <div className="items-center ">
        {count !== undefined && (
          <p className="mr-4 text-xs font-medium text-descriptionColor whitespace-nowrap md:mr-12">
            {`${count} ${subHeading}`}
          </p>
        )}
        {additionalInfo && (
          <div className="text-xs font-medium text-descriptionColor whitespace-nowrap md:mr-12">
            {additionalInfo}
          </div>
        )}
      </div>
    </VStack>
  </div>
);
export default GenericTableHeader;
