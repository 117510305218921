import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import BCHeader from "../../shared/BCHeader";

import { ButtonPrimary, ButtonPrimary1, HStack } from "../../components/utils";

import UserProfileForm from "./UserProfileForm";
import { UserProfile } from "../../types/UserDetailModel";
import { ChangePassword } from "../../modals/ChangePassword";
import { useUserDetails } from "../../queries";
import { useSaveUserProfile } from "../../queries/userProfile";

function UserProfilePage() {
  const { data: userProfileDetails } = useUserDetails();

  const { mutate: saveUserDetails, isLoading } = useSaveUserProfile();

  const navigate = useNavigate();

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const initialValues = {
    fullName: userProfileDetails?.full_name || "",
    emailAddress: userProfileDetails?.email_id || "",
    phoneNumber: userProfileDetails?.phone_number || "",
    countryOfResidence: userProfileDetails?.country_of_residence || "India",
    nationality: userProfileDetails?.nationality || "India",
    companyName: userProfileDetails?.searched_company || "",
    designation: userProfileDetails?.designation || "",
    linkedInProfile: userProfileDetails?.linked_in_profile || null,
    gender: userProfileDetails?.gender || null,
    profilePic: userProfileDetails?.pathToProfilePicture || "",
  };

  const handleUserProfileSubmit = (values: UserProfile) => {
    saveUserDetails(values, {
      onSuccess: () => {
        toast("User Profile updated Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        navigate("/options/esopOverview");
      },
      onError: (err: any) => {
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };
  const validationUserDetailsSchema = Yup.object().shape({
    fullName: Yup.string().required().label("User Name"),
    emailAddress: Yup.string().email().required().label("Email ID"),
    phoneNumber: Yup.string().required().label("Contact Number"),
    countryOfResidence: Yup.string().required().label("Country Of Residence"),
    nationality: Yup.string().required().label("Nationality"),
    designation: Yup.string().nullable(true).label("Designation"),
    gender: Yup.string().nullable(true).label("Gender"),
    profilePic: Yup.string().nullable(true).label("Avatar"),
  });

  return (
    <div className="gap-4 mx-auto">
      <BCHeader
        className="items-baseline mb-4"
        bcTitle="User Profile"
        bcSubTitle=""
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values: UserProfile, error: any) => {
          handleUserProfileSubmit(values);
        }}
        validationSchema={validationUserDetailsSchema}
      >
        {(formik) => (
          <div className="bg-white ">
            <Form>
              <Dialog open={dialog.open} maxWidth="md">
                <ChangePassword
                  onSecondaryAction={() => setDialog({ open: false })}
                />
              </Dialog>
              <UserProfileForm setDialog={setDialog} />
              <HStack className="justify-end gap-4 px-4 py-4 mt-3">
                <ButtonPrimary1 type="reset">Cancel</ButtonPrimary1>{" "}
                <ButtonPrimary type="submit" loading={isLoading}>
                  Save
                </ButtonPrimary>
              </HStack>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default UserProfilePage;
