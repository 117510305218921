import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AlertDialog from "../../components/shared/AlertDialog";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { useCreatePlanDetails, usePlanStateChange } from "../../queries";
import { useCompanyStore } from "../../store/companyStore";
import { useError } from "../../store/errorStore";
import { EsopPlan, PlanState } from "../../types/EsopPlan";
import { PlanStateChangeDto } from "../../types/Grant";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { formatDate } from "../../utils/date";
import { getFormattedValue, stringFormat } from "../../utils/string";
import StatusLabel from "./StatusLabel";

function PlanStateChange({
  plan,
  onClose = () => {},
}: {
  plan: EsopPlan;
  onClose: () => void;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const { mutate: changePlanState, status } = usePlanStateChange();
  const errorMessage = useError();
  const initialValues: PlanStateChangeDto = {
    esopPlanState: plan.availableState[0],
    note: plan.note,
    esopPlanId: plan.esopPlanId,
    planPoolSize: plan.poolSize,
    planStartDate: (plan.planStartDate && formatDate(plan.planStartDate)) || "",
  };

  const _availableStates = plan.availableState || [];
  const availablestates = _availableStates.map((state) =>
    stringFormat(state).toLowerCase()
  );
  const { companyData } = useCompanyStore();
  const { data: createPlanDetails } = useCreatePlanDetails();
  const minStartDate = new Date(
    companyData?.companyMetaData.dateOfIncorporation || "1900-01-01"
  );
  const validationSchema = Yup.object().shape({
    planStartDate: Yup.date()
      .required("Plan Start Date is required")
      .min(
        minStartDate,
        `Plan date should not be before company Incorporation date (
    ${format(
      new Date(
        companyData?.companyMetaData.dateOfIncorporation || "1900-01-01"
      ),
      "dd-MM-yyyy"
    )})`
      ),
    planPoolSize: Yup.number()
      .required("Pool Size is required")
      .positive("Pool Size must be a positive number")
      .test(
        "Pool Size",
        "Pool Size must be lesser than available options ",
        (_value, context) => {
          const value = context.parent as PlanStateChangeDto;
          const optionsAvailable =
            plan?.esopPlanState === PlanState.ACTIVE ||
            plan?.esopPlanState === PlanState.AMENDMENT
              ? (createPlanDetails?.esopPoolCount || 0) +
                (plan?.poolSize || 0) * (plan?.conversionNumber || 1)
              : createPlanDetails?.esopPoolCount || 0;
          const sharesAvailable = optionsAvailable * plan.conversionNumber;
          if (value.planPoolSize > sharesAvailable) {
            return false;
          }
          return true;
        }
      )
      .test(
        "is-fractional",
        "Pool Size must be an integer",
        (_value, context) => {
          const value = context.parent as PlanStateChangeDto;
          if (plan?.isFractional) {
            return true;
          } else {
            return Number.isInteger(value?.planPoolSize);
          }
        }
      ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      handleSubmit();
    },
  });
  function handleSubmit() {
    const formattedPlanState = formik.values.esopPlanState
      .toUpperCase()
      .replace(/ /g, "_");
    if (!plan.esopPlanId || !formattedPlanState) return;
    const submissionValues = {
      ...formik.values,
      esopPlanState: formattedPlanState,
    };
    changePlanState(submissionValues, {
      onSuccess: () => {
        toast("State Changed Successfully!", { type: "success" });
        onClose();
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  const sharesAvailable =
    plan?.esopPlanState === PlanState.ACTIVE ||
    plan?.esopPlanState === PlanState.AMENDMENT
      ? (createPlanDetails?.esopPoolCount || 0) +
        (plan?.poolSize || 0) * plan.conversionNumber
      : createPlanDetails?.esopPoolCount || 0;
  const currency = getCurrencyType();
  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Change Plan Status{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>
        </h6>
      </div>
      <VStack className="justify-between p-10 min-w-[700px] gap-8">
        <HStack className="justify-between">
          <p className="font-semibold">{plan.planName}</p>
          <StatusLabel state={plan.esopPlanState ?? ""} />
        </HStack>
        <Box className="w-1/2 pr-3">
          <Label className="text-sm font-normal">Select Plan Status</Label>
          <Select
            className="capitalize"
            options={availablestates}
            isPlaceHolderDisabled={true}
            onChange={(e) =>
              formik.setFieldValue("esopPlanState", e.target.value)
            }
          />
        </Box>
        <HStack>
          <Box className="w-1/2 pr-3">
            <Label className="text-sm font-normal">Plan Start Date</Label>
            <Input type="date" {...formik.getFieldProps("planStartDate")} />
            {formik.touched.planStartDate && formik.errors.planStartDate && (
              <Error text={formik.errors.planStartDate} />
            )}
          </Box>
          <Box className="w-1/2 px-3">
            <div className="flex items-center justify-between">
              <Label className="text-sm font-normal">Plan Pool Size</Label>
              <Label className="text-xs font-normal text-green-400">
                {getFormattedValue(sharesAvailable, currency)} shares available
              </Label>
            </div>
            <Input type="number" {...formik.getFieldProps("planPoolSize")} />
            {formik.touched.planPoolSize && formik.errors.planPoolSize && (
              <Error text={formik.errors.planPoolSize} />
            )}
          </Box>
        </HStack>
        <Box className="w-1/2 pr-3">
          <Label>Note </Label>
          <Input type="textarea" {...formik.getFieldProps("note")} />
        </Box>
        <HStack className="flex justify-end gap-4">
          <ButtonSecondary onClick={() => onClose()}>Cancel</ButtonSecondary>
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => {
              formik.handleSubmit();
            }}
            loading={status === "loading"}
          >
            {status === "idle" && "Confirm"}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
        </HStack>
      </VStack>
      <AlertDialog
        open={showDialog}
        error
        message={errorMessage.message}
        primaryActionText="Retry"
        secondaryActionText="Go Back"
        onPrimaryAction={() => {
          errorMessage.reset();
          onClose();
          setShowDialog(false);
        }}
        onSecondaryAction={() => {
          errorMessage.reset();
          onClose();
          setShowDialog(false);
        }}
        onClose={() => {
          errorMessage.reset();
          onClose();
          setShowDialog(false);
        }}
      />
    </>
  );
}
export default PlanStateChange;
