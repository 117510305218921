import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Select } from "../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  HStack,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../components/utils";
import { sort } from "../utils/arrays";
import useIsMobile from "../utils/detectDevice";
import { BCHeaderType } from "../utils/interfaces/BCHeader";

const BCHeader = (props: BCHeaderType) => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState<string>();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  const {
    bcDescription,
    className,
    onClick,
    selectOption,
    bcTitle,
    bcMiddleTitle,
    bcSubTitle,
    buttonName,
    dropdown,
    selectedValue,
    ...rest
  } = props;

  const esopTransactionsBackRoutes = [
    "view-option-buy-back",
    "start-buy-back",
    "create-option-buyback",
    "edit-options-buyback",
    "create-exercise-event",
    "edit-exercise-flow",
    "complete-buy-back",
    "exercise-event",
    "create-exercise-liquidation-flow",
  ];
  function isPathNamePresent() {
    return esopTransactionsBackRoutes.some((location) =>
      pathname.includes(location)
    );
  }
  const goBack = () => {
    if (pathname.includes("planView")) {
      navigate("/options/allPlans");
    } else if (pathname.includes("employeeDetails")) {
      navigate("/options/allEmployees");
    } else if (isPathNamePresent()) {
      navigate("/options/esopTransactions");
    } else if (pathname.includes("add-edit-pool")) {
      navigate("/options/esopOverview");
    } else if (pathname.includes("plan")) {
      navigate("/options/esopOverview");
    } else if (pathname.includes("completeMilestone")) {
      navigate("/options/milestones");
    } else if (pathname.includes("modelling")) {
      navigate("/modelling/home");
    }
  };
  const { isMobile } = useIsMobile();
  return (
    <>
      <Box
        className={`flex ${
          isMobile
            ? "flex-col text-xs"
            : "font-medium text-base flex-row justify-between"
        }   text-gray-600 ${className}`}
      >
        <VStack>
          <HStack>
            <Box
              className={`h-6${
                isMobile ? "" : "h-6"
              } flex flex-row items-baseline gap-2 pl-2 border-l-4 border-solid border-orange-501`}
            >
              <Box
                className={`${
                  bcSubTitle
                    ? "cursor-pointer text-orange-501 hover:underline"
                    : ""
                }`}
                onClick={goBack}
              >
                {bcTitle}
              </Box>
              {bcMiddleTitle && <Box>{bcMiddleTitle}</Box>}
              {bcSubTitle && (
                <>
                  <Box className="font-semibold"> {" > "} </Box>
                  <Box>{bcSubTitle}</Box>
                </>
              )}
            </Box>
          </HStack>
          {bcDescription && (
            <Box
              className={`text-xxs font-normalx text-gray-light ml-3.5   ${
                isMobile ? "ml-2" : ""
              }`}
            >
              <div>{bcDescription}</div>
            </Box>
          )}
        </VStack>

        <Box className={`flex flex-row ${isMobile ? "mb-4 gap-2 " : ""}`}>
          {dropdown && dropdown.length > 0 ? (
            <Box className="bg-white rounded w-44">
              <Select
                className="p-1 pr-8 bg-white w-44 text-ellipsis"
                value={selectedValue?.id}
                options={sort(dropdown || [], "name")}
                valueGetter={(o) => o.id}
                textGetter={(o) => `${o.name} `}
                isPlaceHolderDisabled={true}
                onChange={selectOption}
              />
            </Box>
          ) : (
            ""
          )}
          <Box>
            {buttonName ? (
              <ButtonPrimary
                className={`px-5 py-1 ${isMobile ? "" : "ml-4"} h-8`}
                onClick={onClick}
                disabled={isUserAdminViewer || isUserEsopViewer}
              >
                {buttonName}
              </ButtonPrimary>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BCHeader;
