import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  ButtonPrimary1,
  Error,
  clearCredentials,
  ButtonSize,
} from "../../components/utils";

import { Input, Label, TextArea } from "../../components/shared/InputField";
import {
  useGetUserRoleAndPermission,
  uselogin,
  useResendOtp,
  useVerifyOtp,
} from "../../queries/login";
import { LoginRequest, LoginResponse } from "../../types/Login";
import { useAuthorizationStore, useAuthStore } from "../../store";
import { useError } from "../../store/errorStore";
import { trackEvent } from "../../amplitudeAnalytics";
import { queryClient } from "../../queries/client";
import { usePermissionStore } from "../../store/permissionStore";
import { Permission } from "../../utils/interfaces/Companies";

const LoginPage = () => {
  const { setAuth, clear } = useAuthStore();
  const { clearAuthorization } = useAuthorizationStore();
  const [otpRecieved, setOtpRecieved] = useState(false);
  const validationSchema = Yup.object().shape({
    emailId: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const errorMessage = useError();
  const intialValues: LoginRequest = {
    emailId: "",
    password: "",
    otp: "",
  };
  const { mutate: login, isLoading, isError } = uselogin();
  const { mutate: verifyOtp } = useVerifyOtp();
  const { mutate: resendVerification } = useResendOtp();
  const { data, refetch: getPermission } = useGetUserRoleAndPermission();
  const { setPermission } = usePermissionStore();

  const handleSubmit = (values: LoginRequest) => {
    trackEvent("login_tapped");
    clearAuthorization();
    clear();
    clearCredentials();
    const loginDto = { ...values };
    const encryptSecretKey = "hissabyrulezero";
    try {
      loginDto.password = btoa(`${loginDto.password}::${encryptSecretKey}`);
    } catch (e: any) {
      //
    }
    if (loginDto.otp === undefined || loginDto.otp === "") {
      delete loginDto.otp;
      login(loginDto, {
        onSuccess: (result: any) => {
          if (result?.data?.reason === "OTP Sent") {
            const otpSchema = Yup.object().shape({
              otp: Yup.string().required("OTP is required"),
            });
            validationSchema.concat(otpSchema);
            setOtpRecieved(true);
            toast("Please enter OTP sent to your registered Email ID", {
              type: "info",
              autoClose: 2000,
            });
            return;
          }
          const accessToken = result?.headers.accesstoken;
          setAuth({
            accessToken,
          });
          if (result?.data?.data?.companyId)
            localStorage.setItem("companyId", result?.data?.data?.companyId);
          if (accessToken) {
            getPermission().then((permission) => {
              setPermission(permission.data as Permission);
              setAuth({
                accessToken,
                isAuthenticated: true,
              });
            });
          }
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.errorMessage);
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else {
      verifyOtp(loginDto, {
        onSuccess: (result) => {
          const accessToken = result.headers.accesstoken;
          if (accessToken) {
            setAuth({ isAuthenticated: true, accessToken, user: result.data });
          }
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      });
    }
  };
  return (
    <>
      <VStack className="w-full px-10 m-10 mx-10 py-7 gap-9">
        <Formik
          initialValues={intialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <>
              <Form>
                <VStack className="max-w-sm p-10 bg-white rounded-lg shadow gap-9">
                  <Box className="py-4 text-lg font-medium border-b">
                    <h6>Login</h6>
                  </Box>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Username</Label>
                    <Input
                      autoComplete="username"
                      type="text"
                      disabled={otpRecieved}
                      {...formik.getFieldProps("emailId")}
                    />
                    {formik.touched.emailId && formik.errors.emailId && (
                      <Error text={formik.errors.emailId} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Password</Label>
                    <Input
                      autoComplete="password"
                      type="password"
                      disabled={otpRecieved}
                      {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <Error text={formik.errors.password} />
                    )}
                  </div>
                  {otpRecieved && (
                    <div className="flex-1">
                      <Label className="text-sm font-normal">OTP</Label>
                      <Input type="text" {...formik.getFieldProps("otp")} />
                      {formik.touched.otp && formik.errors.otp && (
                        <Error text={formik.errors.otp} />
                      )}
                    </div>
                  )}
                  <HStack className="">
                    <ButtonPrimary
                      className="flex items-center justify-center w-1/2"
                      type="submit"
                      loading={isLoading}
                      loadingText="Login.."
                    >
                      Login
                    </ButtonPrimary>
                  </HStack>
                  {isError && (
                    <p className="text-xs text-red-500 ">
                      {errorMessage.message}
                    </p>
                  )}
                </VStack>
              </Form>
            </>
          )}
        </Formik>
      </VStack>
    </>
  );
};

export default LoginPage;
