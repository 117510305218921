/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable etc/no-commented-out-code */
/* eslint-disable no-return-assign */
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import SortingComponent from "../../components/SortingVariation";
import AlertDialog from "../../components/shared/AlertDialog";
import { EmptyTable } from "../../components/shared/EmptyTable";
import { Input } from "../../components/shared/InputField";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
  getCompanyName,
  isEsopViewer,
} from "../../components/utils";
import { useExportToExcel } from "../../queries";
import {
  useBuybackOffers,
  useCloseLiquidityEvent,
  useCompleteLiquidityEvent,
  useDiscardBuyback,
  useSendLiquidationIntimation,
  useStartLiquidityEvent,
} from "../../queries/optionBuyback";
import { useCompanyStore } from "../../store";
import { useError } from "../../store/errorStore";
import {
  buyBackHeadersObj,
  buyBackHeaderslist,
  buyBackHeadingVal,
} from "../../types/ExportExcelOptionBuyBack";
import {
  CumulativeOffer,
  ErrorInterface,
  LiquidityState,
  MonetaryEventState,
  MonetaryTransactionType,
  OfferState,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { globalFilter, sort } from "../../utils/arrays";
import useIsMobile from "../../utils/detectDevice";
import { ExportImport } from "../esopOverview/GrantsTable";
import BuyBackOffersListAgGridTable from "./BuyBackOffersListAgGridTable";
import GenericTableHeader from "../../shared/GenericTableHeader";

const getHeaderObj = (keys: string[]) =>
  keys.map((ele: string) => ({
    key: ele,
    value: buyBackHeadersObj[ele],
  }));

function BuybackEmployeeList({ buyback }: { buyback: OptionBuyBackDetails }) {
  const isUserEsopViewer = isEsopViewer();
  const { companyData } = useCompanyStore();
  const emailConfiguration =
    companyData?.companySettings.emailSettings.emailsToBeSent;
  const { id } = useParams();
  const _id = id ?? "";
  const navigate = useNavigate();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    isLoading?: boolean;
  }>({
    open: false,
    message: " ",
    isLoading: false,
  });

  const errorMessage = useError();
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);
  // const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<{
    field: keyof CumulativeOffer | "" | undefined;
    ascending: boolean;
  }>({ field: "employeeName", ascending: false });
  const { mutate: discardBuyback, isLoading } = useDiscardBuyback();
  const [buybackActionType, setBuybackActionType] = useState("");
  useEffect(() => {
    if (buyback?.eventState === MonetaryEventState.DRAFT) {
      if (
        buyback?.transactionType === MonetaryTransactionType.EXERCISE ||
        buyback?.transactionType === MonetaryTransactionType.EXERCISE_LIQUIDITY
      ) {
        setBuybackActionType("Start Exercise");
      } else {
        setBuybackActionType("Start Buy Back");
      }
    } else if (buyback?.eventState === MonetaryEventState.STARTED) {
      if (
        buyback?.transactionType === MonetaryTransactionType.EXERCISE ||
        buyback?.transactionType === MonetaryTransactionType.EXERCISE_LIQUIDITY
      ) {
        setBuybackActionType("Close Exercise");
      } else {
        setBuybackActionType("Close Buy Back");
      }
    } else if (buyback?.eventState === MonetaryEventState.CLOSED) {
      if (
        buyback.transactionType === MonetaryTransactionType.BUYBACK &&
        buyback.eventState === MonetaryEventState.CLOSED
      ) {
        setBuybackActionType("Complete Buy Back");
      } else {
        setBuybackActionType("Complete Exercise");
      }
    } else if (buyback?.eventState === MonetaryEventState.COMPLETED) {
      if (
        buyback?.transactionType ===
          MonetaryTransactionType.EXERCISE_LIQUIDITY &&
        buyback?.liquidityState === LiquidityState.DRAFT
      ) {
        setBuybackActionType("Start Liquidation");
      } else if (
        buyback?.transactionType ===
          MonetaryTransactionType.EXERCISE_LIQUIDITY &&
        buyback?.liquidityState === LiquidityState.ACTIVE
      ) {
        setBuybackActionType("Close Liquidation");
      } else {
        setBuybackActionType("Complete Liquidation");
      }
    }
  }, [buyback]);

  const { data: _buybackStateData, isPlaceholderData } = useBuybackOffers(_id);
  const buybackStateData = _.sortBy(
    _buybackStateData || [],
    (bb) => bb.employeeName
  );

  let cumulativeOffers: CumulativeOffer[] = [];

  cumulativeOffers = useMemo(
    () => buybackStateData.filter((list) => list),
    [isPlaceholderData, cumulativeOffers, buybackStateData]
  );
  /**
   * used for edit willingness by admin
   */
  const [editRow, setEditRow] = useState({
    editable: false,
    id: "0",
  });
  cumulativeOffers = useMemo(() => {
    if (!cumulativeOffers) return [];
    const filterResult = globalFilter(cumulativeOffers, globalFilterText, [
      "employeeName",
      "state",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [cumulativeOffers, globalFilterText, sortField, editRow]);

  const [updateEligibility, setUpdateEligibility] = useState<number>();
  const [save, setSave] = useState(false);
  // update the updateEligibility using hook
  let currentTableData: any[] = [];
  currentTableData = useMemo(() => {
    // const firstPageIndex = (currentPage - 1) * pageSize;
    // const lastPageIndex = firstPageIndex + pageSize;
    cumulativeOffers.filter((list) => {
      if (list.employeeId === editRow.id && save) {
        list.eligibility = updateEligibility || 0;
        return list;
      } else {
        return list;
      }
    });

    return cumulativeOffers;
  }, [
    cumulativeOffers,
    // currentPage,
    pageSize,
    editRow,
    save,
    updateEligibility,
  ]);

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [globalFilterText]);

  function transactionAction() {
    if (
      buybackActionType === "Start Buy Back" ||
      buybackActionType === "Start Exercise"
    ) {
      setDialog({
        open: true,
        isLoading: false,
        message: `Are you sure you want to start Option ${buybackActionType.replace(
          "Start",
          ""
        )}?
    ${
      emailConfiguration
        ? "This will send an Email invite to all eligible Employees."
        : "Since you have disabled Email configuration, Email invite will not be sent to eligible Employee"
    }`,
      });
    } else if (buybackActionType === "Start Liquidation") {
      setDialog({
        open: true,
        isLoading: false,
        message: `You are about to start the liquidation Flow
        Are you sure you want to continue? `,
      });
    } else if (
      buybackActionType === "Close Buy Back" ||
      buybackActionType === "Close Exercise"
    ) {
      const closeBuyBackMessage = `Are you sure you want to close this Event? 
      Employees who have not yet accepted the offer will not be able to participate in the cash-out program.`;
      const closeExerciseOptionMessage = `Are you sure you want to close this Event? 
      Employees who have not yet accepted the offer will not be able to participate in the Liquidity program.`;
      setDialog({
        open: true,
        isLoading: false,
        message:
          buybackActionType === "Close Buy Back"
            ? closeBuyBackMessage
            : closeExerciseOptionMessage,
      });
    } else {
      setDialog({
        open: true,
        isLoading: false,
        message: `Are you sure you want to complete ${
          buyback?.transactionType !== MonetaryTransactionType.BUYBACK
            ? "the option exercise"
            : "option"
        }  ${buybackActionType.replace("Complete", "")}?
        This will ${
          buyback?.transactionType !== MonetaryTransactionType.BUYBACK
            ? ""
            : "cash"
        } exercise the options based on willingness of Employees. ${
          emailConfiguration
            ? "Email intimation will be sent confirming the same."
            : ""
        }`,
      });
    }
  }

  const { mutate: startEvent } = useStartLiquidityEvent();
  const { mutate: completeEvent } = useCompleteLiquidityEvent();
  const { mutate: closeEvent } = useCloseLiquidityEvent();
  const { mutate: startLiquidation } = useSendLiquidationIntimation();
  function handleTransactionAction() {
    setDialog({ ...dialog, isLoading: true });
    if (
      buybackActionType === "Start Liquidation" ||
      buybackActionType === "Close Liquidation" ||
      buybackActionType === "Complete Liquidation"
    ) {
      let message: string = "";
      if (buybackActionType === "Start Liquidation") {
        message = "Liquidation started successfully";
      } else if (buybackActionType === "Close Liquidation") {
        message = "Liquidation closed successfully";
      } else if (buybackActionType === "Complete Liquidation") {
        message = "Liquidation completed successfully";
      }
      handleELStartTransaction(message);
      return;
    }
    let message = "";
    const optionBuybackDto = { ...buyback };
    optionBuybackDto.liquidityState = LiquidityState.DRAFT;
    if (
      buybackActionType === "Start Buy Back" ||
      buybackActionType === "Start Exercise"
    ) {
      optionBuybackDto.eventState = MonetaryEventState.STARTED;
      message = `${buybackActionType.replace(
        "Start",
        ""
      )} started successfully`;
      startLiquidityEvent(message);
    } else if (
      buybackActionType === "Close Buy Back" ||
      buybackActionType === "Close Exercise"
    ) {
      optionBuybackDto.eventState = MonetaryEventState.CLOSED;
      message = `${buybackActionType.replace("Close", "")} closed successfully`;
      closeLiquidityEvent(message);
    } else if (
      buybackActionType === "Complete Buy Back" ||
      buybackActionType === "Complete Exercise"
    ) {
      optionBuybackDto.eventState = MonetaryEventState.COMPLETED;
      message = `${buybackActionType.replace(
        "Complete",
        ""
      )} completed successfully`;
      completeLiquidityEvent(message);
    }
  }

  function startLiquidityEvent(message: string) {
    startEvent(_id, {
      onSuccess: (data) => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/start-buy-back/${_id}`);
        setDialog({ open: false });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(error.errorRaw, { type: "error", autoClose: 5000 });
        setDialog({ open: false });
      },
    });
  }
  function closeLiquidityEvent(message: string) {
    closeEvent(_id, {
      onSuccess: () => {
        toast(message, { type: "success", autoClose: 2000 });
        navigate(`/options/complete-buy-back/${_id}`);
        setDialog({ open: false });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(error.errorRaw, { type: "success", autoClose: 2000 });
        setDialog({ open: false });
      },
    });
  }
  function completeLiquidityEvent(message: string) {
    completeEvent(_id, {
      onSuccess: () => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/complete-buy-back/${_id}`);
        setDialog({ open: false });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(error.errorRaw, { type: "error", autoClose: 5000 });
        setDialog({ open: false });
      },
    });
  }
  const fileName = `BuyBack_Employees_Details_${getCompanyName()}`;
  function handleELStartTransaction(message: string) {
    const buybackId = _id;
    startLiquidation(buybackId, {
      onSuccess: () => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
        navigate(`/options/liquidation-details/${_id}`);
      },
      onError: (error) => {
        toast("Something went wrong", { type: "error", autoClose: 2000 });
        setDialog({ open: false });
      },
    });
  }

  const { mutate: exportExcel } = useExportToExcel();
  const [downloading, setDownloading] = useState(false);
  const exportClickHandler = () => {
    setDownloading(true);
    const reqData = cumulativeOffers;
    reqData.forEach((item) => {
      if (
        item.state === OfferState.SIGN_GIVEN ||
        item.state === OfferState.WILLINGNESS_GIVEN
      ) {
        item.state = OfferState.OFFERED;
      }
      if (
        item.state !== OfferState.ACCEPTED &&
        item.state !== OfferState.COMPLETED &&
        item.state !== OfferState.PENDING_EXERCISED
      ) {
        item.willingness = 0;
        item.totalValueWillingness = 0;
        item.netPayout = 0;
        item.totalPriceToPay = 0;
      } else {
        item.totalValueWillingness =
          item.willingness *
          (buyback?.transactionType === MonetaryTransactionType.BUYBACK
            ? buyback?.monetaryDetails.price || 0
            : buyback?.monetaryDetails.sellingPrice || 0);
        item.netPayout =
          item.willingness *
            (buyback?.transactionType === MonetaryTransactionType.BUYBACK
              ? buyback?.monetaryDetails.price || 0
              : buyback?.monetaryDetails.sellingPrice || 0) -
          item.totalPriceToPay;
      }
    });
    const reqObj = {
      heading: buyBackHeadingVal,
      companyName: companyData?.name,
      data: reqData,
      headers: getHeaderObj(buyBackHeaderslist),
    };

    exportExcel(reqObj, {
      onSuccess: async (data) => {
        const reportDto = data.data;
        const blob = await convertBase64ToBlob(
          reportDto.base64File ?? "",
          reportDto.fileType
            ? reportDto.fileType
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        downloadBlobObject(blob, fileName);
        setDownloading(false);
      },
      onError: () => {
        toast("Something Went Wrong", { type: "error", autoClose: 2000 });
        setDownloading(false);
      },
    });
  };

  function handleCancelBuyback() {
    setDialog({ open: false });
  }
  function discard() {
    if (buyback?.eventState !== MonetaryEventState.DRAFT) {
      toast(
        `${
          buyback?.transactionType === MonetaryTransactionType.BUYBACK
            ? "Buy back"
            : "Exercise event"
        }  can not be deleted in ${buyback?.eventState} state `,
        {
          type: "warning",
          autoClose: 2000,
        }
      );
    } else {
      discardBuyback(_id, {
        onSuccess: () => {
          toast("Buy Back discarded successfully", {
            type: "success",
            autoClose: 2000,
          });
          navigate("/options/esopTransactions");
        },
        onError: () => {
          toast("Something went wrong!", {
            type: "error",
            autoClose: 5000,
          });
        },
      });
    }
  }
  const { isMobile } = useIsMobile();
  return (
    <>
      <Dialog open={dialog.open}>
        <AlertDialog
          open={dialog.open}
          message={dialog.message}
          status={dialog.isLoading}
          primaryActionText={`${buybackActionType}`}
          secondaryActionText="Cancel"
          onPrimaryAction={handleTransactionAction}
          onSecondaryAction={handleCancelBuyback}
        />
      </Dialog>

      <HStack className="justify-between px-2 py-2 text-sm font-semibold">
        <HStack>
          <GenericTableHeader
            heading={"Offers List"}
            subHeading={""}
          ></GenericTableHeader>
        </HStack>
        <HStack>
          {!downloading ? (
            <ExportImport
              actions={[{ name: "Export to excel" }]}
              onAction={() => exportClickHandler()}
            />
          ) : (
            <HStack
              className={`${
                downloading ? "text-orange-501" : ""
              } p-2  text-orange-501 rounded hover:cursor-pointer bg-slate-100 hover:bg-orange-501 `}
            >
              <div>Please Wait...</div>

              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={18}
                height={18}
              />
            </HStack>
          )}
        </HStack>
      </HStack>
      <Box className={`p-6 bg-[#fefefe] rounded-lg drop-shadow-sm `}>
        <Box
          aria-label="toolbar"
          className={`flex ${
            isMobile ? "flex-col" : "flex-row items-center "
          } justify-between space-y-4`}
        ></Box>
        <BuyBackOffersListAgGridTable offersListTableData={currentTableData} />

        {((buyback?.transactionType ===
          MonetaryTransactionType.EXERCISE_LIQUIDITY &&
          buyback?.liquidityState !== LiquidityState.DRAFT) ||
          // we enable this condition later to closed
          ((!buyback?.isOnAutoPilot ||
            buyback?.eventState === MonetaryEventState.DRAFT ||
            buyback?.eventState === MonetaryEventState.CLOSED) &&
            buyback?.eventState !== MonetaryEventState.COMPLETED)) &&
          !isUserEsopViewer && (
            <HStack className="justify-end gap-4 mt-8">
              <ButtonSecondary onClick={discard}>
                {" "}
                {!isLoading ? (
                  "Discard"
                ) : (
                  <HStack>
                    <div>Please Wait...</div>

                    <Icon
                      className="animate-spin"
                      icon="lucide:loader-2"
                      width={18}
                      height={18}
                    />
                  </HStack>
                )}
              </ButtonSecondary>
              <ButtonPrimary
                onClick={transactionAction}
                // disabled={errorMessage.message !== ""}
              >
                {buybackActionType}
              </ButtonPrimary>
            </HStack>
          )}
      </Box>
    </>
  );
}

export default BuybackEmployeeList;
