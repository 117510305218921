import { QueryFunctionContext } from "react-query";
import captableApi from "./captableApi";
import { useAuthStore } from "../store";
import { useGetUserCompanies } from "../queries";

export interface FilterConditionModel {
  isFilterSave: boolean;
  isColumnSave: boolean;
}
export interface TableStateModel {
  filterState: any;
  columnState: any;
  isFilterSave: boolean;
  isColumnSave: boolean;
}

export async function getTableFilterState(
  context: QueryFunctionContext
): Promise<any> {
  const tableId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const response = await captableApi.get(`v1/utils/tableState`, {
    params: {
      id: `${tableId}${companyId}`,
      platform: "OPTIONS",
    },
  });
  return response.data.data;
}

export async function postTableFilterState({
  tableId,
  companyId,
  filterData,
}: {
  tableId: string;
  companyId: string;
  filterData: TableStateModel;
}): Promise<any> {
  if (Object.keys(filterData.filterState).length === 0) {
    filterData.filterState = null;
  }

  const postData = {
    id: `${tableId}${companyId}`,
    data: filterData,
    companyId,
    platform: "OPTIONS",
  };

  const response = await captableApi.put(`v1/utils/tableState`, postData);
  return response.data;
}
export async function deleteTableState({
  tableId,
  companyId,
}: {
  tableId: string;
  companyId: string;
}): Promise<any> {
  return captableApi
    .delete(`v1/utils/tableState?id=${tableId}${companyId}&platform=OPTIONS`)
    .then((res) => res.data);
}
