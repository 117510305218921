import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";

import { useNavigate } from "react-router";
import {
  CellClickedEvent,
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  INumberCellEditorParams,
  SideBarDef,
  ValueParserParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { useAuthStore } from "../../store";
import { Employee } from "../../types/Employee";
import Tooltip from "../../components/shared/Tooltip";
import GrantStatusLabel from "../esopOverview/StatusLabel";

import {
  Box,
  HStack,
  IconCTAButton,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import Avatar from "../esopOverview/Avatar";
import { Action, CTADropdown } from "../../components/shared/Dropdown";

import {
  capitalizeFirstLetter,
  limitString,
  stringFormat,
} from "../../utils/string";
import QuickSearch from "../../components/shared/QuickSearch";
import SearchInput from "../../components/shared/SearchInput";
import { useGetFilterStates } from "../../queries/gridUtils";
import MyCustomFilters from "../../components/shared/MyCustomFilters";
import { saveTableFilterState } from "../../api/gridUtils";
import { AvailableTableFilters, SaveTableState } from "../../types/Table";
import StatusLabelColors from "../../constants/StatusLabelColors";
import { TableId } from "../../constants/TableIdConstants";
import { useTableStateManagement } from "../../components/shared/TableHook";
import CloudSetting from "../../components/shared/CloudIcon";
import {
  LiquidityState,
  MonetaryEventState,
  MonetaryTransactionType,
  OfferState,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import {
  ConversionMethodology,
  ExerciseReq,
  RaisedBy,
} from "../../types/Exercise";
import {
  useEditExerciseOptions,
  useGetOptionBuyback,
} from "../../queries/optionBuyback";
import { useExerciseApproveDialog } from "../../store/useDialogStore";

function OptionsBuybackAgGridTable({
  optionsBuyBackData,
  exerciseRequestData,
  selectedTab,
  gridRef,
  tableFilterCount,
}: {
  optionsBuyBackData: OptionBuyBackDetails[];
  exerciseRequestData: ExerciseReq[];
  selectedTab: string;
  gridRef: any;
  tableFilterCount: Function;
}) {
  const currency = getCurrencyType();

  const gridApi = useRef<GridApi | any>(null);
  // route params logic
  const navigate = useNavigate();

  const {
    data: _buybackOverview,
    isPlaceholderData,
    isFetching,
  } = useGetOptionBuyback();

  const {
    mutate: updateRequestedExerciseOptions,
    isLoading: isOptionsUpdated,
  } = useEditExerciseOptions();

  const buybackOverview = _buybackOverview || [];

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };

  const { data, refetch } = useGetFilterStates("EMPLOYEES");

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      if (selectedTab !== "Exercise Requests") {
        if (
          cellParams.data.transactionType === MonetaryTransactionType.EXERCISE
        ) {
          if (
            [MonetaryEventState.DRAFT, MonetaryEventState.STARTED].includes(
              cellParams.data.eventState
            )
          ) {
            navigate(`/options/exercise-event/edit/${cellParams.data.id}`);
          } else {
            navigate(`/options/exercise-event/view/${cellParams.data.id}`);
          }
        } else if (cellParams.data.eventState === MonetaryEventState.STARTED) {
          navigate(`/options/start-buy-back/${cellParams.data.id}`);
        } else if (
          cellParams.data.eventState === MonetaryEventState.COMPLETED
        ) {
          if (
            cellParams.data.transactionType ===
              MonetaryTransactionType.EXERCISE_LIQUIDITY &&
            cellParams.data.liquidityState !== LiquidityState.DRAFT
          ) {
            navigate(`/options/liquidation-details/${cellParams.data.id}`);
            return;
          }
          navigate(`/options/complete-buy-back/${cellParams.data.id}`);
        } else navigate(`/options/view-option-buy-back/${cellParams.data.id}`);
      }
    }
  };

  const saveNewFilter = (filterName: string) => {
    const filterState = gridApi.current?.api.getFilterModel();
    const filterStateToSave: SaveTableState = {
      filterName,
      filterState,
      tableName: "EMPLOYEES",
    };
    saveTableFilterState(filterStateToSave).then((_) => {
      toast(`${filterName} saved successfully`, {
        autoClose: 2000,
        type: "success",
      });
      refetch();
    });
  };
  const onFilterSelected = (filterItem: AvailableTableFilters | null) => {
    gridApi?.current?.api.setFilterModel(filterItem?.filterState);
  };
  function numberValueParser(params: ValueParserParams) {
    const res = Number.parseInt(params.newValue, 10);
    if (Number.isNaN(res)) {
      return undefined;
    }
    return res;
  }

  const componentsRegistery = useMemo(
    () => ({
      eventName: memo(EventNameRender),
      offerStartDate: memo(OfferStartDateRender),
      offerEndDate: memo(OfferEndDateRender),
      targetValue: memo(TargetValueRender),
      actualValue: memo(ActualValueRender),
      percentage: memo(PercentageValueRender),
      price: memo(PriceValueRender),
      optionsExercised: memo(OptionsExercisedRender),
      totalExerciseAmount: memo(TotalExerciseAmountRender),
      sharesReceived: memo(SharesReceivedRender),
      employeeName: memo(EmployeeNameRender),
      grantId: memo(GrantIdRender),
      exerciseType: memo(ExerciseTypeRender),
      dateOfExercise: memo(DateRender),
      status: memo(StatusRender),
      willingnessGiven: memo(WillingnessGivenRender),
      note: memo(NoteRender),
    }),
    []
  );

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
  };
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.employeeTable);
  const { setState: setExerciseApproveDialog } = useExerciseApproveDialog();

  const handleModifyAction = (action: Action, data: ExerciseReq) => {
    const runningExerciseEventId = buybackOverview.find(
      (item) =>
        item.eventState === "STARTED" && item.transactionType === "EXERCISE"
    )?.id;
    if (action.name === "Approve/Reject Request" && !action.disabled) {
      setExerciseApproveDialog({
        open: true,
        id: data.id,
        isEmployeeRaised: data.raisedBy === RaisedBy.EMPLOYEE,
      });
    }
  };

  function handleCellValueChanged(event: any) {
    const rowIndex = event.rowIndex;

    const updatedRowData = event.data;
    const rowId = updatedRowData.id;
    const originalRowData = exerciseRequestData?.find(
      (row) => row.id === rowId
    );

    if (
      originalRowData?.numberOfShares! >=
      parseInt(updatedRowData.willingnessGiven, 10)
    ) {
      event.data.willingnessGiven = updatedRowData.willingnessGiven;
      gridApi.current.api.applyTransaction({
        update: [{ rowIndex, data: updatedRowData }],
      });
      gridApi.current.api.refreshCells({ force: true });
      saveUpdateOptions(
        updatedRowData.grantId,
        updatedRowData.exerciseType,
        updatedRowData.raisedBy,
        updatedRowData.willingnessGiven,
        updatedRowData.id
      );
    } else if (
      originalRowData?.numberOfShares! <
      parseInt(updatedRowData.willingnessGiven, 10)
    ) {
      event.data.willingnessGiven = originalRowData?.numberOfShares!;
      gridApi.current.api.applyTransaction({
        update: [{ rowIndex, data: originalRowData }],
      });
      gridApi.current.api.refreshCells({ force: true });

      toast("Cannot request more than already requested", {
        type: "error",
        autoClose: 2000,
      });
    }
  }

  function saveUpdateOptions(
    grantId: string,
    isCashExercise: boolean,
    note: string,
    optionsRequested: number,
    id: string
  ) {
    if (optionsRequested && optionsRequested <= 0) {
      toast("Cannot set willingness to 0");
      return;
    }
    const obj = {
      grantId,
      isCashExercise,
      note,
      optionsRequested,
      id,
    };

    updateRequestedExerciseOptions(obj, {
      onSuccess: (data) => {
        toast("Requested options Successfully updated", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: () => {},
    });
  }

  function isEditable(params: any) {
    return (
      params.data.raisedBy === RaisedBy.EMPLOYEE &&
      params.data.status === "REQUESTED"
    );
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: any[] = useMemo<any>(() => {
    if (selectedTab === "Buy Back") {
      return [
        {
          headerName: "NAME",
          field: "eventName",
          filter: "agMultiColumnFilter",
          getQuickFilterText: (params: any) => params.value.name,
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("eventName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                buttons: ["reset"],
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const eventStatus = params.value.status;
                    return eventStatus;
                  },
                  valueFormatter: (params: any) => {
                    const eventStatus = params.value.status;
                    return eventStatus;
                  },
                },
              },
            ],
          },
          pinned: "left",
          cellRenderer: "eventName",
          minwidth: 350,
          sortable: true,
          autoHeight: true,
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          wrapText: true,
          suppressSizeToFit: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipShareholderName",
        },

        {
          headerName: "OFFER START DATE",
          field: "offerStartDate",
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: any) => {
              if (cellValue == null) {
                return 0;
              }
              const dateParts = cellValue.split("-");

              const day = Number(dateParts[2].split("T")[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < dateFromFilter) {
                return -1;
              } else if (cellDate > dateFromFilter) {
                return 1;
              }
              return 0;
            },
          },

          cellRenderer: "offerStartDate",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "OFFER END DATE",
          field: "offerEndDate",
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: any) => {
              if (cellValue == null) {
                return 0;
              }
              const dateParts = cellValue.split("-");

              const day = Number(dateParts[2].split("T")[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < dateFromFilter) {
                return -1;
              } else if (cellDate > dateFromFilter) {
                return 1;
              }
              return 0;
            },
          },

          cellRenderer: "offerEndDate",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "TARGETED VALUE",
          field: "targetValue",
          filter: "agNumberColumnFilter",
          cellRenderer: "targetValue",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            (params.data?.monetaryOffers?.reduce(
              (accumulator: any, { eligibility }: { eligibility: number }) =>
                accumulator + eligibility,
              0
            ) || 0) *
            (params.data?.transactionType === MonetaryTransactionType.BUYBACK
              ? params.data?.monetaryDetails?.price || 0
              : params.data?.monetaryDetails?.sellingPrice || 0),
        },
        {
          headerName: "ACTUAL VALUE",
          field: "actualValue",
          filter: "agNumberColumnFilter",
          cellRenderer: "actualValue",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            (params.data?.monetaryOffers
              ?.filter(
                (offer: any) =>
                  offer.offerState === OfferState.ACCEPTED ||
                  offer.offerState === OfferState.COMPLETED
              )
              .reduce(
                (accumulator: any, { willingness }: { willingness: number }) =>
                  accumulator + willingness,
                0
              ) || 0) *
            (params.data?.transactionType === MonetaryTransactionType.BUYBACK
              ? params.data?.monetaryDetails.price || 0
              : params.data?.monetaryDetails?.sellingPrice || 0),
        },
        {
          headerName: "PERCENTAGE (%)",
          field: "percentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "percentage",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) => params.data.eventPercentage * 100,
        },
        {
          headerName: "PRICE",
          field: "price",
          filter: "agNumberColumnFilter",
          cellRenderer: "price",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            (params.data as OptionBuyBackDetails).transactionType ===
            MonetaryTransactionType.BUYBACK
              ? params.data.monetaryDetails.price || 0
              : params.data.monetaryDetails?.sellingPrice || 0,
        },
      ];
    } else if (selectedTab === "Exercise and Liquidate") {
      return [
        {
          headerName: "NAME",
          field: "eventName",
          filter: "agMultiColumnFilter",
          getQuickFilterText: (params: any) => params.value.name,
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("eventName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                buttons: ["reset"],
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const eventStatus = params.value.status;
                    return eventStatus;
                  },
                  valueFormatter: (params: any) => {
                    const eventStatus = params.value.status;
                    return eventStatus;
                  },
                },
              },
            ],
          },

          pinned: "left",
          cellRenderer: "eventName",
          minwidth: 250,
          sortable: true,
          autoHeight: true,
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          wrapText: true,
          suppressSizeToFit: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipShareholderName",
        },

        {
          headerName: "OFFER START DATE",
          field: "offerStartDate",
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: any) => {
              if (cellValue == null) {
                return 0;
              }
              const dateParts = cellValue.split("-");

              const day = Number(dateParts[2].split("T")[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < dateFromFilter) {
                return -1;
              } else if (cellDate > dateFromFilter) {
                return 1;
              }
              return 0;
            },
          },

          cellRenderer: "offerStartDate",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "OFFER END DATE",
          field: "offerEndDate",
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: any) => {
              if (cellValue == null) {
                return 0;
              }
              const dateParts = cellValue.split("-");

              const day = Number(dateParts[2].split("T")[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < dateFromFilter) {
                return -1;
              } else if (cellDate > dateFromFilter) {
                return 1;
              }
              return 0;
            },
          },

          cellRenderer: "offerEndDate",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "TARGETED VALUE",
          field: "targetValue",
          filter: "agNumberColumnFilter",
          cellRenderer: "targetValue",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            (params.data?.monetaryOffers?.reduce(
              (accumulator: any, { eligibility }: { eligibility: number }) =>
                accumulator + eligibility,
              0
            ) || 0) *
            (params.data?.transactionType === MonetaryTransactionType.BUYBACK
              ? params.data?.monetaryDetails?.price || 0
              : params.data?.monetaryDetails?.sellingPrice || 0),
        },
        {
          headerName: "ACTUAL VALUE",
          field: "actualValue",
          filter: "agNumberColumnFilter",
          cellRenderer: "actualValue",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            (params.data?.monetaryOffers
              ?.filter(
                (offer: any) =>
                  offer.offerState === OfferState.ACCEPTED ||
                  offer.offerState === OfferState.COMPLETED
              )
              .reduce(
                (accumulator: any, { willingness }: { willingness: number }) =>
                  accumulator + willingness,
                0
              ) || 0) *
            (params.data?.transactionType === MonetaryTransactionType.BUYBACK
              ? params.data?.monetaryDetails.price || 0
              : params.data?.monetaryDetails?.sellingPrice || 0),
        },
        {
          headerName: "PERCENTAGE (%)",
          field: "percentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "percentage",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) => params.data.eventPercentage * 100,
        },
        {
          headerName: "SALE PRICE",
          field: "price",
          filter: "agNumberColumnFilter",
          cellRenderer: "price",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            (params.data as OptionBuyBackDetails).transactionType ===
            MonetaryTransactionType.BUYBACK
              ? params.data.monetaryDetails.price || 0
              : params.data.monetaryDetails?.sellingPrice || 0,
        },
      ];
    } else if (selectedTab === "Exercise") {
      return [
        {
          headerName: "NAME",
          field: "eventName",
          filter: "agMultiColumnFilter",
          getQuickFilterText: (params: any) => params.value.name,
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("eventName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                buttons: ["reset"],
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const eventStatus = params.value.status;
                    return eventStatus;
                  },
                  valueFormatter: (params: any) => {
                    const eventStatus = params.value.status;
                    return eventStatus;
                  },
                },
              },
            ],
          },
          pinned: "left",
          cellRenderer: "eventName",
          minwidth: 250,
          sortable: true,
          autoHeight: true,
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          wrapText: true,
          suppressSizeToFit: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipShareholderName",
        },

        {
          headerName: "OFFER START DATE",
          field: "offerStartDate",
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: any) => {
              if (cellValue == null) {
                return 0;
              }
              const dateParts = cellValue.split("-");

              const day = Number(dateParts[2].split("T")[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < dateFromFilter) {
                return -1;
              } else if (cellDate > dateFromFilter) {
                return 1;
              }
              return 0;
            },
          },

          cellRenderer: "offerStartDate",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "OFFER END DATE",
          field: "offerEndDate",
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: any) => {
              if (cellValue == null) {
                return 0;
              }
              const dateParts = cellValue.split("-");

              const day = Number(dateParts[2].split("T")[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < dateFromFilter) {
                return -1;
              } else if (cellDate > dateFromFilter) {
                return 1;
              }
              return 0;
            },
          },

          cellRenderer: "offerEndDate",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "TOTAL EXERCISE AMOUNT",
          field: "totalExerciseAmount",
          filter: "agNumberColumnFilter",
          cellRenderer: "totalExerciseAmount",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            params.data?.monetaryOffers?.reduce(
              (
                accumulator: any,
                { amountReceived }: { amountReceived: number }
              ) => accumulator + amountReceived,
              0
            ) || 0,
        },
        {
          headerName: "OPTIONS EXERCISED",
          field: "optionsExercised",
          filter: "agNumberColumnFilter",
          cellRenderer: "optionsExercised",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            params.data?.monetaryOffers
              ?.filter(
                (offer: any) =>
                  offer.offerState === OfferState.ACCEPTED ||
                  offer.offerState === OfferState.COMPLETED
              )
              .reduce(
                (accumulator: any, { willingness }: { willingness: number }) =>
                  accumulator + willingness,
                0
              ) || 0,
          valueFormatter: (params: any) =>
            (
              params.data?.monetaryOffers
                ?.filter(
                  (offer: any) =>
                    offer.offerState === OfferState.ACCEPTED ||
                    offer.offerState === OfferState.COMPLETED
                )
                .reduce(
                  (
                    accumulator: any,
                    { willingness }: { willingness: number }
                  ) => accumulator + willingness,
                  0
                ) || 0
            ).toLocaleString(currency),
        },
        {
          headerName: "SHARES RECEIVED",
          field: "sharesReceived",
          filter: "agNumberColumnFilter",
          cellRenderer: "sharesReceived",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
          valueGetter: (params: any) =>
            params.data?.monetaryOffers
              ?.filter(
                (offer: any) =>
                  offer.offerState === OfferState.ACCEPTED ||
                  offer.offerState === OfferState.COMPLETED
              )
              .reduce(
                (
                  accumulator: any,
                  { willingness, conversionRatio, conversionMethodology }: any
                ) =>
                  accumulator +
                  (conversionMethodology === ConversionMethodology.ROUND_UP
                    ? Math.ceil(willingness * (conversionRatio || 1))
                    : Math.floor(willingness * (conversionRatio || 1))),
                0
              ),
          valueFormatter: (params: any) =>
            params.data?.monetaryOffers
              ?.filter(
                (offer: any) =>
                  offer.offerState === OfferState.ACCEPTED ||
                  offer.offerState === OfferState.COMPLETED
              )
              .reduce(
                (
                  accumulator: any,
                  { willingness, conversionRatio, conversionMethodology }: any
                ) =>
                  accumulator +
                  (conversionMethodology === ConversionMethodology.ROUND_UP
                    ? Math.ceil(willingness * (conversionRatio || 1))
                    : Math.floor(willingness * (conversionRatio || 1))),
                0
              )
              .toLocaleString(currency),
        },
      ];
    } else if (selectedTab === "Exercise Requests") {
      return [
        {
          headerName: "EMPLOYEE NAME",
          field: "employeeName",
          filter: "agNumberColumnFilter",
          cellRenderer: "employeeName",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "GRANT ID",
          field: "grantId",
          filter: "agNumberColumnFilter",
          cellRenderer: "grantId",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "EXERCISE TYPE",
          field: "exerciseType",
          filter: "agTextColumnFilter",
          cellRenderer: "exerciseType",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "DATE",
          field: "dateOfExercise",
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (dateFromFilter: Date, cellValue: any) => {
              if (cellValue == null) {
                return 0;
              }
              const dateParts = cellValue.split("-");

              const day = Number(dateParts[2].split("T")[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < dateFromFilter) {
                return -1;
              } else if (cellDate > dateFromFilter) {
                return 1;
              }
              return 0;
            },
          },

          cellRenderer: "dateOfExercise",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "STATUS",
          field: "status",
          filter: "agNumberColumnFilter",
          cellRenderer: "status",
          cellStyle: { paddingTop: "15px", lineHeight: "0" },
          sortable: true,
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "WILLINGNESS GIVEN",
          field: "willingnessGiven",
          sortable: true,
          editable: (params: any) => isEditable(params),
          filter: "agNumberColumnFilter",
          menuTabs: ["filterMenuTab"],

          cellEditorSelector: (params: any) => ({
            component: "agNumberCellEditor",
            popup: false,
            cellEditorPopupPosition: "under",
          }),
          cellClass: (params: any) =>
            params.data.raisedBy === RaisedBy.EMPLOYEE &&
            params.data.status === "REQUESTED"
              ? "milestoneEditor"
              : "",
          cellEditorParams: {
            min: 0,
            max: 100,
            precision: 4,
            step: 0.01,
            showStepperButtons: true,
          } as INumberCellEditorParams,
          width: 200,
          autoHeight: true,
          wrapText: true,
          cellRenderer: "willingnessGiven",
        },
        {
          headerName: "NOTES",
          field: "note",
          sortable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["reset"],
            defaultToNothingSelected: true,
            suppressSelectAll: false,
            suppressMiniFilter: false,
            excelMode: "windows",
            comparator: sortComparator,
          },

          width: 200,
          menuTabs: ["filterMenuTab"],
          autoHeight: true,
          wrapText: true,
          cellRenderer: "note",
        },
        {
          headerName: "",
          field: "actions",
          pinned: "right",
          hide: false,
          width: 80,
          maxWidth: 80,
          filter: false,
          menuTabs: [],
          colId: "action-column",
          suppressNavigable: true,
          suppressColumnsToolPanel: false,
          cellStyle: { "padding-top": "12px", "line-height": "18px" },
          resizable: false,
          sortable: false,
          cellRendererParams: ({ value }: { value: any }) => value?.props,
          cellRenderer: CTADropdown,
        },
      ];
    } else {
      return [];
    }
  }, [selectedTab]);

  const rowData = useMemo(() => {
    if (selectedTab === "Exercise Requests") {
      exerciseRequestData.sort(
        (a, b) =>
          new Date(b.dateOfExercise).getTime() -
          new Date(a.dateOfExercise).getTime()
      );
      return exerciseRequestData?.map((template) => ({
        id: template.id,
        conversionMethodology: template.conversionMethodology,
        conversionRatio: template.conversionRatio,
        dateOfExercise: template.dateOfExercise,
        employeeName: template.optionHolderName,
        exercisePrice: template.exercisePrice,
        grantId: template.identifier,
        identifier: template.identifier,
        exerciseType: template.isCashExercise,
        isFractional: template.isFractional,
        willingnessGiven: template.numberOfShares,
        optionHolderId: template.optionHolderId,
        optionHolderName: template.optionHolderName,
        optionsAvailable: template.optionsAvailable,
        raisedBy: template.raisedBy,
        status: template.status,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Approve/Reject Request",
                disabled: template.status !== "REQUESTED",
              },
            ]}
            onAction={(action) => {
              handleModifyAction(action, template);
            }}
          />
        ),
      }));
    } else {
      optionsBuyBackData?.sort(
        (a, b) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      );
      return optionsBuyBackData?.map((template) => ({
        id: template.id,
        eventName: {
          name: template.eventName,
          status: template.eventState,
        },
        offerStartDate: template.startDate,
        offerEndDate: template.endDate,
        monetaryOffers: template.monetaryOffers,
        monetaryDetails: template.monetaryDetails,
        transactionType: template.transactionType,
        eventPercentage: template.eventPercentage,
        currency,
        selectedTab,
      }));
    }
  }, [selectedTab, optionsBuyBackData, exerciseRequestData, currency]);

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.api.paginationSetPageSize(Number(value));
  }, []);
  const onGridReady = (params: any) => {
    gridApi.current = params;
    gridRef.current = params.api;
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);

    tableFilterCount(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter(filterModel);
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
    rowBuffer: 10,
  };

  return (
    <VStack className="w-full">
      <HStack className="p-4 pl-6 m-0"></HStack>
      <HStack className="items-center justify-end gap-4">
        <HStack>
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
        </HStack>

        <IconCTAButton
          value={"Columns"}
          onClick={() => openToolPanel("columns")}
          iconName={"fluent:column-triple-edit-20-regular"}
          className={`px-4 font-medium items-center flex flex-row ${
            isColumnOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isColumnOpen}
        />
        <IconCTAButton
          value={"Filters"}
          onClick={() => openToolPanel("filters")}
          iconName={"material-symbols:filter-alt"}
          className={`px-4  font-medium items-center flex flex-row ${
            isFilterOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isFilterOpen}
        />
        <MyCustomFilters
          currentFilterState={gridApi.current?.api.getFilterModel()}
          availableFilters={data || []}
          onFilterSelect={onFilterSelected}
          className={""}
          onNewFilterAddition={(e) => {
            saveNewFilter(e);
          }}
        ></MyCustomFilters>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            getRowStyle={(params) => ({
              background: params.data.isLastPinnedRow ? "#f0f0f0" : "",
              fontWeight: params.data.isLastPinnedRow ? "normal" : "normal",
              color: params.data.isLastPinnedRow ? "black" : "",
            })}
            suppressRowTransform={true}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            alwaysShowHorizontalScroll
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            onCellValueChanged={(event) => handleCellValueChanged(event)}
            components={componentsRegistery}
            sideBar={sideBar}
            singleClickEdit={true}
            suppressCellFocus={true}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            onCellClicked={handleCellClick}
            stopEditingWhenCellsLoseFocus={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            onSelectionChanged={handleSelectionChanged}
            suppressRowClickSelection={true}
            // gridOptions={gridOptions}
            pagination={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}

function EventNameRender(data: any) {
  return (
    <div className="whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.value.name}
      </p>
      <GrantStatusLabel state={data.value.status}></GrantStatusLabel>
    </div>
  );
}

export function formatDisplayDate(dateStr: string | Date | undefined) {
  const dateFormat = "dd MMM yyyy";
  if (!dateStr) {
    dateStr = "-";
  }
  if (typeof dateStr === "string") {
    return format(new Date(dateStr), dateFormat);
  } else {
    return format(dateStr, dateFormat);
  }
}

export function OfferStartDateRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {formatDisplayDate(data.data.offerStartDate)}
      </p>
    </div>
  );
}

export function OfferEndDateRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {formatDisplayDate(data.data.offerEndDate)}
      </p>
    </div>
  );
}

export function TargetValueRender(data: any) {
  return (
    <div>
      {data.data.selectedTab !== "Exercise" && (
        <div className="pt-2 whitespace-nowrap">
          <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
            {formatCurrency(
              (data.data?.monetaryOffers?.reduce(
                (accumulator: any, { eligibility }: { eligibility: number }) =>
                  accumulator + eligibility,
                0
              ) || 0) *
                (data.data?.transactionType === MonetaryTransactionType.BUYBACK
                  ? data.data?.monetaryDetails?.price || 0
                  : data.data?.monetaryDetails?.sellingPrice || 0),
              data.data.currency
            )}
          </p>
        </div>
      )}
    </div>
  );
}

export function ActualValueRender(data: any) {
  return (
    <div>
      {data.data.selectedTab !== "Exercise" && (
        <div className="pt-2 whitespace-nowrap">
          <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
            {formatCurrency(
              (data.data?.monetaryOffers
                ?.filter(
                  (offer: any) =>
                    offer.offerState === OfferState.ACCEPTED ||
                    offer.offerState === OfferState.COMPLETED
                )
                .reduce(
                  (
                    accumulator: any,
                    { willingness }: { willingness: number }
                  ) => accumulator + willingness,
                  0
                ) || 0) *
                (data.data?.transactionType === MonetaryTransactionType.BUYBACK
                  ? data.data?.monetaryDetails.price || 0
                  : data.data?.monetaryDetails.sellingPrice || 0),
              data.data.currency
            )}{" "}
          </p>
        </div>
      )}
    </div>
  );
}

export function PercentageValueRender(data: any) {
  return (
    <div>
      {data.data.selectedTab !== "Exercise" && (
        <div className="pt-2 whitespace-nowrap">
          <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
            {(data.data.eventPercentage * 100).toPrecision(3)}
          </p>
        </div>
      )}
    </div>
  );
}

export function PriceValueRender(data: any) {
  return (
    <div>
      {data.data.selectedTab !== "Exercise" && (
        <div className="pt-2 whitespace-nowrap">
          <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
            {" "}
            {formatCurrency(
              (data.data as OptionBuyBackDetails)?.transactionType ===
                MonetaryTransactionType.BUYBACK
                ? data.data.monetaryDetails.price || 0
                : data.data.monetaryDetails.sellingPrice || 0,
              data.data.currency
            )}
          </p>
        </div>
      )}
    </div>
  );
}

export function TotalExerciseAmountRender(data: any) {
  return (
    <div>
      {data.data.selectedTab === "Exercise" && (
        <div className="pt-2 whitespace-nowrap">
          <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
            {formatCurrency(
              data.data?.monetaryOffers?.reduce(
                (
                  accumulator: any,
                  { amountReceived }: { amountReceived: number }
                ) => accumulator + amountReceived,
                0
              ) || 0,
              data.data.currency
            )}
          </p>
        </div>
      )}
    </div>
  );
}

export function OptionsExercisedRender(data: any) {
  return (
    <div>
      {data.data.selectedTab === "Exercise" && (
        <div className="pt-2 whitespace-nowrap">
          <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
            {data.data?.monetaryOffers
              ?.filter(
                (offer: any) =>
                  offer.offerState === OfferState.ACCEPTED ||
                  offer.offerState === OfferState.COMPLETED
              )
              .reduce(
                (accumulator: any, { willingness }: { willingness: number }) =>
                  accumulator + willingness,
                0
              ) || 0}
          </p>
        </div>
      )}
    </div>
  );
}

export function SharesReceivedRender(data: any) {
  return (
    <div>
      {data.data.selectedTab === "Exercise" && (
        <div className="pt-2 whitespace-nowrap">
          <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
            {data.data?.monetaryOffers
              ?.filter(
                (offer: any) =>
                  offer.offerState === OfferState.ACCEPTED ||
                  offer.offerState === OfferState.COMPLETED
              )
              .reduce(
                (
                  accumulator: any,
                  { willingness, conversionRatio, conversionMethodology }: any
                ) =>
                  accumulator +
                  (conversionMethodology === ConversionMethodology.ROUND_UP
                    ? Math.ceil(willingness * (conversionRatio || 1))
                    : Math.floor(willingness * (conversionRatio || 1))),
                0
              ) || 0}
          </p>
        </div>
      )}
    </div>
  );
}

export function EmployeeNameRender(data: any) {
  return (
    <div className="pb-2 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.employeeName}
      </p>
    </div>
  );
}

export function GrantIdRender(data: any) {
  return (
    <div className="pb-2 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.grantId}
      </p>
    </div>
  );
}

export function ExerciseTypeRender(data: any) {
  return (
    <div className="pb-2 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        <p>{data.data.exerciseType ? "CASH" : "STOCK"}</p>
      </p>
    </div>
  );
}

export function DateRender(data: any) {
  return (
    <div className="pb-2 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {new Date(data.data.dateOfExercise).toUTCString().slice(5, 17)}
      </p>
    </div>
  );
}

export function StatusRender(data: any) {
  return (
    <div className="pb-2 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        <GrantStatusLabel state={data.data.status}></GrantStatusLabel>
      </p>
    </div>
  );
}

export function WillingnessGivenRender(data: any) {
  return (
    <div className="pt-3.5 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.willingnessGiven}
      </p>
    </div>
  );
}

export function NoteRender(data: any) {
  return (
    <div className="pt-3.5 whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {`${
          data.data.raisedBy === RaisedBy.ADMIN ||
          data.data.raisedBy === RaisedBy.EMPLOYEE
            ? "Raised by"
            : "Raised during"
        } ${capitalizeFirstLetter(data.data.raisedBy?.toLowerCase() || "-")}` ||
          ""}
      </p>
    </div>
  );
}

export default OptionsBuybackAgGridTable;
