import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { EmployeePortalSetting } from "../../types/SiteSettings";
import { getCurrencySymbol } from "../../utils/currencyFormatter";

import {
  useGetEmployeePortalSettings,
  useSetEmployeePortalSettings,
} from "../../queries/siteSettings";
import { usePermissionStore } from "../../store/permissionStore";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";

function EmployeePortalSettings() {
  const {
    data: employeePortalSettingDetails,
    refetch,
    isError,
    isFetched,
  } = useGetEmployeePortalSettings();
  const { permission } = usePermissionStore();

  const initialValues: EmployeePortalSetting = {
    showPlanToEmployees:
      employeePortalSettingDetails?.showPlanToEmployees || false,
    showEmpPortalValuesForecast:
      employeePortalSettingDetails?.showEmpPortalValuesForecast || false,
    showPPSToEmployees:
      employeePortalSettingDetails?.showPPSToEmployees || false,
    isNomineeMandatory:
      (employeePortalSettingDetails?.isNomineeMandatory as boolean) || false,
    mfaMandatoryForEmployee:
      (employeePortalSettingDetails?.mfaMandatoryForEmployee as boolean) ||
      false,
    showAtGrantPPSToEmployees:
      (employeePortalSettingDetails?.showAtGrantPPSToEmployees as boolean) ||
      false,
    enableGrantLetterDownload:
      (employeePortalSettingDetails?.enableGrantLetterDownload as boolean) ||
      false,
    showEquivalentShares:
      (employeePortalSettingDetails?.showEquivalentShares as boolean) ?? true,
  };
  const validationSchema = Yup.object({
    showPPSToEmployees: Yup.boolean(),
    showPlanToEmployees: Yup.boolean(),
    showEmpPortalValuesForecast: Yup.boolean(),
    isNomineeMandatory: Yup.boolean(),
    mfaMandatoryForEmployee: Yup.boolean(),
    enableGrantLetterDownload: Yup.boolean(),
    showEquivalentShares: Yup.boolean(),
  });
  const { mutate: setEmployeePortalSettings, isLoading: isSettingsSaved } =
    useSetEmployeePortalSettings();
  const handleSubmit = (values: EmployeePortalSetting) => {
    const changedValues: any = {};
    Object.keys(values).forEach((key) => {
      const originalKey: keyof EmployeePortalSetting =
        key as keyof EmployeePortalSetting;
      if (values[originalKey] !== initialValues[originalKey]) {
        changedValues[originalKey] = values[originalKey];
      }
    });
    setEmployeePortalSettings(changedValues, {
      onSuccess: () => {
        toast("Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err.response.data.errorRaw, { type: "error", autoClose: 2000 });
      },
    });
  };

  const initialSettingsSet = useRef(false);
  const [initialSettings, setInitialSettings] = useState<EmployeePortalSetting>(
    employeePortalSettingDetails!
  );

  const formik = useFormik<EmployeePortalSetting>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      handleSubmit(values);
    },
    onReset: (values) => {
      handleOnCancel(values);
    },
  });

  const { values, errors, touched, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (employeePortalSettingDetails) {
      formik.setValues(employeePortalSettingDetails);
    }
    if (!initialSettingsSet.current) {
      setInitialSettings(_.cloneDeep(values));
      initialSettingsSet.current = true;
    }
  }, [employeePortalSettingDetails]);

  const [handleChanges, setHandleChanges] = useState<boolean>();

  useEffect(() => {
    const hasChanges = _.isEqual(employeePortalSettingDetails, formik.values);

    setHandleChanges(hasChanges);
  }, [initialSettings, values, formik.values]);

  function handleOnCancel(values: EmployeePortalSetting) {
    values = initialValues;
  }

  const isUserEsopViewer = isEsopViewer();
  const currencySymbol = getCurrencySymbol();

  const employeePortalErrors =
    errors.showPPSToEmployees ||
    errors.showPlanToEmployees ||
    errors.showEmpPortalValuesForecast;
  function doPartialTouch() {
    formik.setFieldTouched("showPPSToEmployees");
    formik.setFieldTouched("showAtGrantPPSToEmployees");
    formik.setFieldTouched("showPlanToEmployees");
    formik.setFieldTouched("showEmpPortalValuesForecast");
    formik.setFieldTouched("enableGrantLetterDownload");
    formik.setFieldTouched("showEquivalentShares");
  }
  return (
    <form key="employeePortalSettings" onSubmit={formik.handleSubmit}>
      <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg ">
        <VStack className="justify-between p-4 gap-9">
          <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
            Employee Portal Settings
          </Box>
          <VStack className="justify-between">
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                Display Current Value on Employee portal
              </p>
              <Box className="flex items-start w-1/3 ml-2">
                <SwitchButton
                  onClick={() => {
                    setFieldValue(
                      "showPPSToEmployees",
                      !values.showPPSToEmployees
                    );
                  }}
                  value={values?.showPPSToEmployees || false}
                  label=""
                />
              </Box>
            </HStack>
            {/* adding flag for displaying value at grant */}
            {formik.values.showPPSToEmployees && (
              <VStack className="gap-5 px-10 mt-8">
                <HStack className="items-center justify-between ">
                  <p className={` form-label text-sm font-medium w-2/3`}>
                    Display Value at grant on Employee portal
                  </p>
                  <Box className="flex w-1/3 pl-4">
                    <SwitchButton
                      onClick={() => {
                        setFieldValue(
                          "showAtGrantPPSToEmployees",
                          !values.showAtGrantPPSToEmployees
                        );
                      }}
                      value={values?.showAtGrantPPSToEmployees || false}
                      label=""
                    />
                  </Box>
                </HStack>
              </VStack>
            )}
          </VStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Mandate Employee MFA
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  setFieldValue(
                    "mfaMandatoryForEmployee",
                    !values.mfaMandatoryForEmployee
                  );
                }}
                value={values?.mfaMandatoryForEmployee || false}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Display forecast values in Employee portal
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  setFieldValue(
                    "showEmpPortalValuesForecast",
                    !values.showEmpPortalValuesForecast
                  );
                }}
                className=""
                value={values.showEmpPortalValuesForecast}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Show Plan to Employee
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  setFieldValue(
                    "showPlanToEmployees",
                    !values.showPlanToEmployees
                  );
                }}
                className=""
                value={values.showPlanToEmployees || false}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Mandatory Nominee Information for Employees
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  setFieldValue(
                    "isNomineeMandatory",
                    !values.isNomineeMandatory
                  );
                }}
                className=""
                value={values.isNomineeMandatory || false}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Allow Employee to Download Grant Letter
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  setFieldValue(
                    "enableGrantLetterDownload",
                    !values.enableGrantLetterDownload
                  );
                }}
                className=""
                value={values.enableGrantLetterDownload || false}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Show Equivalent Shares (Options to shares conversion)
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  setFieldValue(
                    "showEquivalentShares",
                    !values.showEquivalentShares
                  );
                }}
                className=""
                value={values.showEquivalentShares ?? true}
                label=""
              />
            </Box>
          </HStack>
        </VStack>
        {!isUserEsopViewer && (
          <HStack className="justify-between mt-12">
            <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              className={`${
                !handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
                  ? " cursor-not-allowed"
                  : ""
              } flex items-center self-end justify-center`}
              disabled={
                isError ||
                handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
              }
              onClick={() => {
                if (employeePortalErrors) {
                  doPartialTouch();
                }
              }}
              type="submit"
              loading={isSettingsSaved}
            >
              Save Settings
            </ButtonPrimary>
          </HStack>
        )}
      </Box>
    </form>
  );
}

export default EmployeePortalSettings;
