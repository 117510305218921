import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Box,
  ButtonPrimary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import StatusLabel from "../esopOverview/StatusLabel";

import { Input, Label } from "../../components/shared/InputField";
import {
  useCloseExerciseEvent,
  useCompleteExerciseEvent,
  useCreateExerciseEvent,
  useGetAllApprovedExercises,
  useGetOptionBuyback,
  usePauseExerciseEvent,
} from "../../queries/optionBuyback";
import {
  EventType,
  LiquidityState,
  MonetaryEventState,
  MonetaryOffers,
  MonetaryTransactionType,
  OfferState,
  OptionBuyBackDetails,
  RecurringIntervalUnit,
} from "../../types/OptionBuyback";

import AlertDialog from "../../components/shared/AlertDialog";
import PauseExerciseEvent from "../../components/shared/PauseExerciseEvent";
import { useError } from "../../store/errorStore";
import { useEditPaymentLinkDialog } from "../../store/useDialogStore";
import { formatDate } from "../../utils/date";
import ApprovedExercisesAgGrid from "./ApprovedExercisesAgGrid";

enum ExerciseEventMethod {
  UploadEmployeeList = "UploadEmployeeList",
  GenerateEmployeeList = "GenerateEmployeeList",
  PickFromRequestList = "PickFromRequestList",
}

function CreateExerciseEvent() {
  const [bcTitle, setBCTitle] = useState<string>("Create Exercise Event");
  const errorMessage = useError();
  const { mode, id } = useParams();
  const _id = id ?? "";

  const { data: optionBuybackList } = useGetOptionBuyback();

  const navigate = useNavigate();

  const { mutate: createExerciseEvent, isLoading: creatingExerciseEvent } =
    useCreateExerciseEvent();
  const { mutate: completeExerciseEvent, isLoading: completingExerciseEvent } =
    useCompleteExerciseEvent();
  const { mutate: closeExerciseEvent, isLoading: closingExerciseEvent } =
    useCloseExerciseEvent();
  const { mutate: pauseExerciseEvent, isLoading: pausingExerciseEvent } =
    usePauseExerciseEvent();

  const { refetch: getAllApprovedExercises, data: approvedExercises } =
    useGetAllApprovedExercises(_id);

  const [eventMethod, setEventMethod] = useState<ExerciseEventMethod>(
    ExerciseEventMethod.PickFromRequestList
  );

  const [eventMode, setEventMode] = useState<string>();

  const [exerciseEvent, setExerciseEvent] = useState<OptionBuyBackDetails>();

  useEffect(() => {
    const _exerciseEvent = optionBuybackList?.find((list) => list?.id === _id);
    if (_exerciseEvent) setExerciseEvent(_exerciseEvent);
  }, [optionBuybackList]);

  useEffect(() => {
    if (mode) {
      setEventMode(mode);
      getAllApprovedExercises();
      if (mode === "edit") setBCTitle("Edit Exercise Event");
      else setBCTitle("View Exercise Event");
    } else {
      setEventMode("create");
    }
  }, [id, mode]);

  const validationSchema = Yup.object().shape({
    transactionType: Yup.string(),
    eventName: Yup.string()
      .required("Name required")
      .min(3, "Name should be minimum 3 characters"),
    startDate: Yup.string().required("Start date required"),
    endDate: Yup.string().required("End date required"),
    description: Yup.string()
      .required("Description required")
      .min(10, "minimum 10 characters required"),
  });

  const getCreateInitialValues = () => ({
    id: "",
    eventName: "",
    description: "",
    eventPercentage: 0,
    recurringSetting: {
      isRecurring: false,
      recurringInterval: 0,
      recurringIntervalUnit: RecurringIntervalUnit.MONTH,
    },
    askWillingness: true,
    buyerName: "",
    createdAt: formatDate(new Date().toString()),
    eventState: MonetaryEventState.DRAFT,
    startDate: formatDate(new Date().toString()),
    endDate: "",
    eventType: EventType.STOCK,
    isDeleted: false,
    isOnAutoPilot: false,
    liquidityState: LiquidityState.DRAFT,
    monetaryDetails: {
      fmvPrice: 0,
      miscCharges: 0,
      price: 0,
      sellingPrice: 0,
      paymentLink: "",
    },
    monetaryOffers: [],
    offerFilter: {
      department: [],
      minimumTenure: 0,
      minimumVestedOptions: 0,
      minimumWillingness: 0,
      minimumWillingnessPercentage: 0,
      participationConstraints: [],
    },
    transactionType: MonetaryTransactionType.EXERCISE,
    updatedAt: formatDate(new Date().toString()),
    vestingCutOffDate: formatDate(new Date().toString()),
  });

  const getInitialValues = () => {
    if (exerciseEvent)
      return {
        ...exerciseEvent,
        vestingCutOffDate: formatDate(
          new Date(exerciseEvent?.vestingCutOffDate || "1970-01-01").toString()
        ),
        startDate: formatDate(
          new Date(exerciseEvent?.startDate || "1970-01-01").toString()
        ),
        endDate: formatDate(
          new Date(exerciseEvent?.endDate || "1970-01-01").toString()
        ),
      };
    return getCreateInitialValues();
  };

  const handleSubmit = (values: OptionBuyBackDetails) => {
    if (eventMethod === ExerciseEventMethod.PickFromRequestList) {
      values.eventState = MonetaryEventState.STARTED;
      createExerciseEvent(values, {
        onSuccess: (data) => {
          toast("Exercise Event Created Successfully", {
            type: "success",
            autoClose: 2000,
          });
          navigate(`/options/exercise-event/edit/${data.data.id}`);
        },
        onError: (err: any) => {
          const errMsg = err.response?.data?.errorsValidation?.errorRaw;
          if (errMsg) {
            errorMessage.setMessage(errMsg);
            toast(errMsg, {
              type: "error",
              autoClose: 2000,
            });
          }
        },
      });
    }
  };

  const handleCompleteEvent = () => {
    if (_id && eventMethod === ExerciseEventMethod.PickFromRequestList) {
      completeExerciseEvent(_id, {
        onSuccess: () => {
          toast("Exercise Event Completed Successfully", {
            type: "success",
            autoClose: 2000,
          });
          setDialog({
            open: false,
          });
          navigate(`/options/exercise-event/view/${_id}`);
        },
        onError: (err: any) => {
          const errMsg = err.response?.data?.errorRaw;
          if (errMsg) {
            errorMessage.setMessage(errMsg);
            toast(errMsg, {
              type: "error",
              autoClose: 2000,
            });
          }
        },
      });
    }
  };

  const handleClosedEvent = () => {
    if (_id && eventMethod === ExerciseEventMethod.PickFromRequestList) {
      closeExerciseEvent(_id, {
        onSuccess: () => {
          toast("Exercise Event Closed Successfully", {
            type: "success",
            autoClose: 2000,
          });
          setDialog({
            open: false,
          });
        },
        onError: (err: any) => {
          const errMsg = err.response?.data?.errorRaw;
          if (errMsg) {
            errorMessage.setMessage(errMsg);
            toast(errMsg, {
              type: "error",
              autoClose: 2000,
            });
          }
        },
      });
    }
  };

  const handlePauseEventEvent = (
    actionType: string,
    ids: string[],
    sendEmails: boolean
  ) => {
    const pauseObj: {
      eventId: string;
      actionType: string;
      offerIds: string[];
      sendEmails: boolean;
    } = {
      eventId: _id,
      actionType,
      offerIds: ids,
      sendEmails,
    };
    pauseExerciseEvent(pauseObj, {
      onSuccess: () => {
        toast(
          `Exercise Event ${
            actionType === "paused" ? "Paused" : "Restarted"
          } Successfully`,
          {
            type: "success",
            autoClose: 2000,
          }
        );
        setDialog({
          open: false,
        });
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
      },
    });
  };

  function calculateExerciseWindowDuration() {
    if (exerciseEvent?.endDate && exerciseEvent?.startDate) {
      const daysDiff = Math.ceil(
        (new Date(exerciseEvent.endDate).getTime() -
          new Date(exerciseEvent.startDate).getTime()) /
          (1000 * 3600 * 24)
      );
      if (daysDiff > 0) {
        return daysDiff;
      } else return 0;
    }
    return 0;
  }

  function getEmployeesCountFromApprovals() {
    const employeeIds = new Set<string>();
    approvedExercises?.forEach((exercise) => employeeIds.add(exercise.hrId));
    return employeeIds.size;
  }

  const [dialog, setDialog] = useState<{
    open: boolean;
    index?: number;
    offersList?: MonetaryOffers[];
    offersIncluded?: MonetaryOffers[];
    offersExcluded?: MonetaryOffers[];
    mode?:
      | "Confirm Close Event"
      | "Confirm Complete Event"
      | "Confirm Pause Event"
      | "Confirm Restart Event";
  }>({
    open: false,
    mode: "Confirm Close Event",
    offersIncluded: [],
    offersList: [],
    offersExcluded: [],
  });

  const { state: showPaymentLinkDialog, setState: setShowPaymentLinkDialog } =
    useEditPaymentLinkDialog();
  function editLink(link: string) {
    setShowPaymentLinkDialog({ open: true, link, eventId: _id });
  }
  return (
    <VStack className="gap-6">
      <BCHeader
        bcTitle="Liquidity Event Planning and Administration"
        className="py-3"
        bcSubTitle={bcTitle}
      />
      <VStack className="gap-4">
        <Dialog
          open={dialog.open}
          onClose={() => {
            setDialog({
              open: false,
            });
          }}
          maxWidth="md"
        >
          {dialog.mode === "Confirm Close Event" && (
            <AlertDialog
              message={"Are you sure you would like to Close the Event?"}
              error={false}
              status={false}
              primaryActionText="Close"
              secondaryActionText="Cancel"
              onPrimaryAction={() => {
                handleClosedEvent();
              }}
              onSecondaryAction={() => {
                setDialog({
                  open: false,
                });
              }}
              open={true}
              loading={closingExerciseEvent}
            />
          )}
          {dialog.mode === "Confirm Complete Event" && (
            <AlertDialog
              message={"Are you sure you would like to Complete the Event?"}
              error={false}
              status={false}
              primaryActionText="Complete"
              secondaryActionText="Cancel"
              onPrimaryAction={() => {
                handleCompleteEvent();
              }}
              onSecondaryAction={() => {
                setDialog({
                  open: false,
                });
              }}
              open={true}
              loading={completingExerciseEvent}
            />
          )}
          {dialog.mode === "Confirm Pause Event" && (
            <PauseExerciseEvent
              message={"Are you sure you want to Pause the Event?"}
              error={false}
              // status={false}
              primaryActionText="Pause"
              secondaryActionText="Cancel"
              actionType="paused"
              onPrimaryAction={handlePauseEventEvent}
              onSecondaryAction={() => {
                setDialog({
                  open: false,
                });
              }}
              open={dialog.open}
              offersList={dialog.offersList || []}
              offersIncluded={dialog.offersIncluded || []}
              offersExcluded={dialog.offersExcluded || []}
              loading={pausingExerciseEvent}
            />
          )}
          {dialog.mode === "Confirm Restart Event" && (
            <PauseExerciseEvent
              message={"Are you sure you want to Restart the Event?"}
              error={false}
              // status={false}
              actionType="restarted"
              primaryActionText="Restart"
              secondaryActionText="Cancel"
              onPrimaryAction={handlePauseEventEvent}
              onSecondaryAction={() => {
                setDialog({
                  open: false,
                });
              }}
              open={dialog.open}
              offersList={dialog.offersList || []}
              offersIncluded={dialog.offersIncluded || []}
              offersExcluded={dialog.offersExcluded || []}
            />
          )}
        </Dialog>
        <Formik
          initialValues={getInitialValues()}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(formik) => (
            <Form>
              <Box
                className={`p-4 bg-white border-b border-solid rounded px-10`}
              >
                <VStack className="gap-4 rounded">
                  <HStack>
                    <h6 className={`text-lg font-semibold text-gray-600`}>
                      Exercise Flow
                    </h6>
                    <div className="items-center px-6 py-1 rounded">
                      <StatusLabel
                        state={
                          exerciseEvent?.eventState || MonetaryEventState.DRAFT
                        }
                      />
                    </div>
                  </HStack>
                  <HStack className="gap-16 m-4">
                    <Box className="items-center px-6 py-1 border-b rounded-md shadow-md">
                      <input
                        type="radio"
                        className={`accent-orange-501 outline-hidden mr-3`}
                        disabled={true}
                        checked={
                          eventMethod ===
                          ExerciseEventMethod.GenerateEmployeeList
                        }
                        onChange={(e) =>
                          setEventMethod(
                            ExerciseEventMethod.GenerateEmployeeList
                          )
                        }
                      />
                      <Label className="text-lg">Upload Employee List</Label>
                    </Box>
                    <Box className="items-center gap-2 px-6 py-1 border-b rounded-md shadow-md">
                      <input
                        type="radio"
                        className={`accent-orange-501 outline-hidden mr-3`}
                        disabled={true}
                        checked={
                          eventMethod === ExerciseEventMethod.UploadEmployeeList
                        }
                        onChange={(e) =>
                          setEventMethod(ExerciseEventMethod.UploadEmployeeList)
                        }
                      />
                      <Label className="text-lg">Generate Employee List</Label>
                    </Box>
                    <Box className="items-center gap-2 px-6 py-1 border-b rounded-md shadow-md">
                      <input
                        type="radio"
                        className={`accent-orange-501 outline-hidden mr-3 cursor-pointer`}
                        checked={
                          eventMethod ===
                          ExerciseEventMethod.PickFromRequestList
                        }
                        onChange={(e) =>
                          setEventMethod(
                            ExerciseEventMethod.PickFromRequestList
                          )
                        }
                      />
                      <Label className="text-lg">Pick from Request List</Label>
                    </Box>
                  </HStack>
                  <Box className="justify-between w-full p-4 bg-white border-b rounded-md shadow-md">
                    <HStack className="p-4 border-b-2">
                      <h6 className={`text-lg font-semibold text-gray-600`}>
                        Create Exercise Event
                      </h6>
                    </HStack>
                    <VStack className="w-full p-4 px-5">
                      <HStack className="justify-between gap-10 mb-3">
                        <VStack className="w-1/2">
                          <Label>Exercise Window Name</Label>
                          <Input
                            type="text"
                            {...formik.getFieldProps("eventName")}
                            className={
                              ["view", "edit"].includes(eventMode || "")
                                ? "cursor-not-allowed"
                                : ""
                            }
                            disabled={["view", "edit"].includes(
                              eventMode || ""
                            )}
                          />
                          {formik.touched.eventName &&
                            formik.errors.eventName && (
                              <Error text={formik.errors.eventName} />
                            )}
                        </VStack>
                        <VStack className="w-1/2">
                          <Label>Exercise Start Date</Label>
                          <Input
                            type="date"
                            {...formik.getFieldProps("startDate")}
                            min={format(new Date(), "yyyy-MM-dd")}
                            className={
                              ["view", "edit"].includes(eventMode || "")
                                ? "cursor-not-allowed"
                                : ""
                            }
                            disabled={["view", "edit"].includes(
                              eventMode || ""
                            )}
                          />
                          {formik.touched.startDate &&
                            formik.errors.startDate && (
                              <Error text={formik.errors.startDate} />
                            )}
                        </VStack>
                      </HStack>
                      <HStack className="justify-between gap-10 mb-3">
                        <VStack className="w-1/2">
                          <Label>Exercise End Date</Label>
                          <Input
                            type="date"
                            {...formik.getFieldProps("endDate")}
                            min={format(new Date(), "yyyy-MM-dd")}
                            className={
                              ["view", "edit"].includes(eventMode || "")
                                ? "cursor-not-allowed"
                                : ""
                            }
                            disabled={["view", "edit"].includes(
                              eventMode || ""
                            )}
                          />
                          {formik.touched.endDate && formik.errors.endDate && (
                            <Error text={formik.errors.endDate} />
                          )}
                        </VStack>
                        <VStack className="w-1/2">
                          <Label>Description</Label>
                          <Input
                            type="text"
                            {...formik.getFieldProps("description")}
                            className={
                              ["view", "edit"].includes(eventMode || "")
                                ? "cursor-not-allowed"
                                : ""
                            }
                            disabled={["view", "edit"].includes(
                              eventMode || ""
                            )}
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <Error text={formik.errors.description} />
                            )}
                        </VStack>
                      </HStack>
                      {eventMode === "create" && (
                        <HStack className="justify-between gap-10 mb-3">
                          <VStack className="w-1/2">
                            <Label>Payment Link</Label>
                            <Input
                              type="text"
                              {...formik.getFieldProps(
                                "monetaryDetails.paymentLink"
                              )}
                              className={
                                ["view", "edit"].includes(eventMode || "")
                                  ? "cursor-not-allowed"
                                  : ""
                              }
                              disabled={["view", "edit"].includes(
                                eventMode || ""
                              )}
                            />
                            {formik.touched.monetaryDetails?.paymentLink &&
                              formik.errors.monetaryDetails?.paymentLink && (
                                <Error
                                  text={
                                    formik.errors.monetaryDetails?.paymentLink
                                  }
                                />
                              )}
                          </VStack>
                        </HStack>
                      )}
                      {eventMode !== "create" &&
                        !!formik.values.monetaryDetails.paymentLink && (
                          <HStack className="justify-between gap-10 mb-3">
                            <VStack className="w-1/2">
                              <Label>Payment Link</Label>
                              <HStack className="items-center gap-4">
                                <Input
                                  type="text"
                                  {...formik.getFieldProps(
                                    "monetaryDetails.paymentLink"
                                  )}
                                  className={
                                    ["view", "edit"].includes(eventMode || "")
                                      ? "cursor-not-allowed"
                                      : ""
                                  }
                                  disabled={["view", "edit"].includes(
                                    eventMode || ""
                                  )}
                                />
                                <p
                                  onClick={() =>
                                    editLink(
                                      formik.values?.monetaryDetails.paymentLink
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <Icon
                                    icon="lets-icons:edit-duotone"
                                    className="w-6 h-6"
                                  />
                                </p>
                              </HStack>
                              {formik.touched.monetaryDetails?.paymentLink &&
                                formik.errors.monetaryDetails?.paymentLink && (
                                  <Error
                                    text={
                                      formik.errors.monetaryDetails?.paymentLink
                                    }
                                  />
                                )}
                            </VStack>
                          </HStack>
                        )}
                    </VStack>
                    {!["view", "edit"].includes(eventMode || "") && (
                      <HStack className="justify-end">
                        <ButtonPrimary
                          type="submit"
                          loading={creatingExerciseEvent}
                        >
                          Create Exercise
                        </ButtonPrimary>
                      </HStack>
                    )}
                  </Box>
                  <VStack>
                    <Box>
                      <VStack className="mb-10">
                        <div className="text-lg font-semibold">
                          Employee Details
                        </div>
                        <HStack className="gap-5 mt-3">
                          <Card
                            title="Exercise Window"
                            content={`${calculateExerciseWindowDuration()} days`}
                          />
                          <Card
                            title="Approved Employees"
                            content={getEmployeesCountFromApprovals()}
                          />
                          <Card
                            title="Exercised Options"
                            content={_.sumBy(
                              approvedExercises,
                              (exercise) => exercise.exercisedOptions
                            )}
                          />
                        </HStack>
                      </VStack>
                      {approvedExercises && (
                        <div>
                          <div className="mb-10 text-base font-medium">
                            Exercise Request from Employees
                          </div>
                          <Box
                            style={{
                              height: `${
                                (approvedExercises.length >= 10
                                  ? 10
                                  : approvedExercises.length + 3) * 60
                              }px`,
                            }}
                            className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
                          >
                            <ApprovedExercisesAgGrid
                              approvedExercises={approvedExercises || []}
                            />
                          </Box>
                        </div>
                      )}
                    </Box>
                  </VStack>
                  {/* {eventMode === "edit" && ( */}

                  <HStack className="justify-end gap-4 mt-10">
                    {exerciseEvent?.eventState ===
                      MonetaryEventState.STARTED && (
                      <ButtonPrimary
                        onClick={() => {
                          setDialog({
                            open: true,
                            mode: "Confirm Close Event",
                          });
                        }}
                      >
                        Close
                      </ButtonPrimary>
                    )}
                    {exerciseEvent?.eventState ===
                      MonetaryEventState.STARTED && (
                      <>
                        <ButtonPrimary
                          onClick={() => {
                            setDialog({
                              open: true,
                              mode: "Confirm Pause Event",
                              offersExcluded:
                                exerciseEvent?.monetaryOffers.filter(
                                  (offer) =>
                                    offer.offerState ===
                                    OfferState.PENDING_EXERCISED
                                ),
                            });
                          }}
                        >
                          Pause Exercise Event
                        </ButtonPrimary>
                      </>
                    )}
                    {exerciseEvent?.eventState ===
                      MonetaryEventState.PAUSED && (
                      <ButtonPrimary
                        onClick={() => {
                          setDialog({
                            open: true,
                            mode: "Confirm Restart Event",
                            offersExcluded:
                              exerciseEvent?.monetaryOffers.filter(
                                (offer) =>
                                  offer.offerState ===
                                  OfferState.PENDING_EXERCISED
                              ),
                          });
                        }}
                      >
                        Resume Exercise Event
                      </ButtonPrimary>
                    )}
                    {exerciseEvent?.eventState ===
                      MonetaryEventState.CLOSED && (
                      <ButtonPrimary
                        onClick={() => {
                          setDialog({
                            open: true,
                            mode: "Confirm Complete Event",
                          });
                        }}
                      >
                        Complete
                      </ButtonPrimary>
                    )}
                  </HStack>
                  {/* )} */}
                </VStack>
              </Box>
            </Form>
          )}
        </Formik>
      </VStack>
    </VStack>
  );
}

const Card = (props: any) => (
  <VStack className="items-center gap-4 py-8 font-medium text-center text-black border-b rounded shadow-md text-xs3 px-7">
    <p className="capitalize">{props.title}</p>
    <p className="font-normal whitespace-nowrap">{props.content}</p>
  </VStack>
);

export default CreateExerciseEvent;
