import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-community";
import { useMemo, useRef, useState, useEffect } from "react";
import _ from "lodash";
import { Icon } from "@iconify/react";
import { Box, HStack, VStack } from "../../../components/utils";
import { SummaryPersonnel, SummaryRole } from "../../../types/modelling";
import {
  getSalaryRangeForUI,
  getSingleSalaryRangeForUI,
} from "../modellingUtils";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import OrganiSationStructureDataProcessor, {
  OrgSummaryData,
  OrgSummaryDataWithPersonnel,
} from "./utils/OrgStructureJSONDataProc";
import { OrgSummaryDoughnutChart } from "./utils/OrgSummaryDoughNutChart";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { RandomDepartmentColorGenerator } from "./utils/RandomDepartmentColorGenerator";
import { SummaryChartData } from "./OrgStructureSummary";
import Avatar from "../../esopOverview/Avatar";
import { EmployementStatus } from "../../../types/Employee";
import KeyPersonnel from "./KeyPersonnel";

export default function OrgStructureSummaryWithEmployee() {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  };
  const { selectedCompanyData } = useOrgTemplateStore();
  const [employeesList, setEmployeesList] = useState<Array<SummaryPersonnel>>(
    []
  );
  const [summaryData, setSummaryData] = useState<OrgSummaryDataWithPersonnel>();
  const [chartData, setChartData] = useState<SummaryChartData>();
  function EmployeeInfoRender(emp: {
    employeeName: string;
    employmentStatus: string;
    keyPersonnel: boolean;
  }) {
    return (
      <HStack className="justify-items-center">
        <div className="pt-2">
          <Avatar name={emp.employeeName} status={emp.employmentStatus} />
        </div>
        <HStack className="items-center ">
          <Box className="ml-2">
            <p className="text-xs font-medium text-gray-dark">
              {emp.employeeName}
            </p>
          </Box>
          <span className="pl-2">
            {emp.keyPersonnel && (
              <img
                src="../assets/KeyPersonnel.svg"
                alt="llll"
                height={"20px"}
                width={"20px"}
              />
            )}
          </span>
        </HStack>
      </HStack>
    );
  }
  const rowData = useMemo(
    () =>
      employeesList.map((employee) => ({
        department: employee.department,
        employeeName: (
          <EmployeeInfoRender
            employeeName={employee.employee.name}
            employmentStatus={EmployementStatus.EMPLOYED}
            keyPersonnel={employee.employee.keyPersonnel}
          />
        ),
        level: employee.level,
        role: employee.role,
        salary: employee.salary,
      })),
    [employeesList]
  );

  useEffect(() => {
    if (selectedCompanyData && selectedCompanyData.departments) {
      const data: SummaryPersonnel[] = [];
      Object.entries(selectedCompanyData.departments).forEach(
        ([departmentName, depData]) => {
          Object.entries(depData.levels).forEach(([levelName, levData]) => {
            Object.entries(levData.roles).forEach(([roleName, roleData]) => {
              if (roleData.employees) {
                Object.entries(roleData.employees).forEach(
                  ([empId, empData]) => {
                    data.push({
                      department: departmentName,
                      level: levelName,
                      role: { role: roleName, keyRole: roleData.keyRole },
                      salary: empData.salary,
                      employee: {
                        name: empData.employeeData.employeeName,
                        keyPersonnel: empData.keyPersonnel,
                      },
                    });
                  }
                );
              }
            });
          });
        }
      );
      const processedData = new OrganiSationStructureDataProcessor(
        undefined,
        selectedCompanyData
      );
      const sumData = processedData.getSummarySheetDetailsWithPersonnel();
      const departmentChartData = {
        labels: ["Key Personnel", "Key Roles", "General"],
        datasets: [
          {
            label: "# of Roles",
            data: [
              sumData.noOfKeyPersonnel,
              sumData.noOfKeyRoles,
              sumData.noOfGeneralEmployees,
            ],
            backgroundColor: ["#70B6FE", "#9D6E48", "#50CD89"],
            borderColor: ["#70B6FE", "#9D6E48", "#50CD89"],
          },
        ],
        centerText: "No of Employees",
      };
      setChartData(departmentChartData);
      setSummaryData(sumData);
      setEmployeesList(data);
    }
  }, [selectedCompanyData]);

  function KeyRoleCellRenderer(params: any) {
    if (params.value.keyRole) {
      return (
        <HStack className="items-center gap-2">
          <div>{params.value.role}</div>
          <img
            src="../assets/KeyRole.svg"
            alt="llll"
            height={"20px"}
            width={"20px"}
          />
        </HStack>
      );
    } else {
      return <HStack>{params.value.role}</HStack>;
    }
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      cellStyle: {
        lineHeight: "1.5",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 80,
      filter: true,
      resizable: true,
    }),
    []
  );
  let index = 0;
  let prevDept = "";
  const columnDefs: ColDef[] = [
    {
      headerName: "DEPARTMENT",
      autoHeight: true,
      field: "department",
      minWidth: 150,
      flex: 1,
      filter: "agSetColumnFilter",
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellClass: "removePadding",
      cellStyle: (params: any) => {
        const existingDept = params.value;
        if (existingDept === prevDept) index -= 1;
        const color = RandomDepartmentColorGenerator(index, existingDept);
        index = index > 12 ? 0 : index + 1;
        prevDept = existingDept;
        return {
          color: "#ffffff",
          backgroundColor: color,
          display: "flex",
          "align-items": "center",
          lineHeight: "1.5",
          "justify-content": "center",
        };
      },
    },
    {
      headerName: "NAME",
      autoHeight: true,
      field: "employeeName",
      flex: 1,
      filter: "agSetColumnFilter",
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: EmployeeInfoRender,
    },
    {
      headerName: "LEVEL",
      autoHeight: true,
      field: "level",
      flex: 1,
      filter: "agSetColumnFilter",
      minWidth: 100,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "ROLE",
      autoHeight: true,
      field: "role",
      flex: 1,
      filter: "agSetColumnFilter",
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRenderer: KeyRoleCellRenderer,
    },
    {
      headerName: "Salary",
      autoHeight: true,
      field: "salary",
      filter: "agSetColumnFilter",
      minWidth: 150,
      flex: 1,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      valueFormatter: (params) =>
        params.data
          ? getSingleSalaryRangeForUI(
              params.data?.salary,
              currencySymbol,
              currencyType
            )
          : getSingleSalaryRangeForUI(
              params.value,
              currencySymbol,
              currencyType
            ),
    },
  ];

  return (
    <VStack className="w-full gap-6">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Summary
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Company-wide employment and pay scale structure.
        </HStack>
      </VStack>
      <VStack className="pb-6 pl-4 mt-4 shadow-lg ml-7 mr-7">
        <VStack className="w-full gap-16 ">
          <HStack className="justify-center w-full text-gray-500">
            <p className="text-sm font-medium">Overall Summary</p>
          </HStack>
          <HStack className="gap-10">
            <VStack className="flex-1 gap-6 pt-6 ">
              <HStack className="flex-1">
                <div className="flex-1 text-gray-500 text-sm2">
                  No. of Departments
                </div>
                <div className="flex-1 font-semibold text-lg2">
                  {(summaryData?.noOfDepartments || 0).toLocaleString(
                    currencyType
                  )}
                </div>
              </HStack>
              <HStack className="flex-1">
                <div className="flex-1 text-gray-500 text-sm2">
                  No. of Employees
                </div>
                <div className="flex-1 font-semibold text-lg2">
                  {(
                    (summaryData?.noOfGeneralEmployees || 0) +
                    (summaryData?.noOfKeyEmployees || 0) +
                    (summaryData?.noOfKeyPersonnel || 0)
                  ).toLocaleString(currencyType)}
                </div>
              </HStack>
              <HStack className="flex-1">
                <div className="flex-1 text-gray-500 text-sm2">Salary</div>
                <div className="flex-1 font-semibold text-lg2">
                  {formatCurrency(summaryData?.salary || 0, currencyType)}
                </div>
              </HStack>
            </VStack>
            <VStack className="flex-1 gap-6 ">
              <VStack>
                <OrgSummaryDoughnutChart
                  data={chartData}
                  total={
                    (summaryData?.noOfGeneralEmployees || 0) +
                    (summaryData?.noOfKeyEmployees || 0) +
                    (summaryData?.noOfKeyPersonnel || 0)
                  }
                />
              </VStack>
              <HStack className="items-center justify-center gap-3">
                <HStack className="items-center gap-1">
                  <Box className="w-4 h-3 bg-[#50CD89]"></Box>
                  <p> General</p>
                </HStack>
                <HStack className="items-center gap-1">
                  <Box className="w-4 h-3 bg-[#70B6FE]"></Box>
                  <p> Key Personnel</p>
                </HStack>
                <HStack className="items-center gap-1">
                  <Box className="w-4 h-3 bg-[#9D6E48]"></Box>
                  <p> Key Roles</p>
                </HStack>
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </VStack>
      <HStack className="justify-between w-full pt-6 pb-6 pl-8">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            suppressRowTransform={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}
