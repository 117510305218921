import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { ColDef, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AlertDialog from "../../components/shared/AlertDialog";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useDeleteTrust,
  useEditAddPoolSize,
  useEsopPlans,
  usePoolDetails,
  useTrustDetails,
} from "../../queries/esopPlan";
import BCHeader from "../../shared/BCHeader";
import { useError } from "../../store/errorStore";
import { usePermissionStore } from "../../store/permissionStore";
import { useTrustDialog } from "../../store/useDialogStore";
import { CreatePoolDto, TrustDetails } from "../../types/Grant";
import { determineUserAccessToResource } from "../../utils/auth";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { formatWithTimeZone } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";

function AddOrEditPoolSize() {
  const navigate = useNavigate();
  const { mutate: createOrUpdatePoolSize, isLoading } = useEditAddPoolSize();
  const { data: poolDetails } = usePoolDetails();
  const { permission } = usePermissionStore();

  const isPoolExists = poolDetails?.isPoolExists;
  const initialValues: CreatePoolDto = {
    poolSize: poolDetails?.poolSize ?? 0,
    poolCreationDate: format(
      poolDetails?.poolCreationDate
        ? new Date(poolDetails.poolCreationDate)
        : new Date(),
      "yyyy-MM-dd"
    ),
  };

  const validationSchema = Yup.object().shape({
    poolSize: Yup.string().required("Pool size is required"),
    poolCreationDate: Yup.date().required("date is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const { mutate: deleteTrust, isLoading: deletingTrust } = useDeleteTrust();
  const { setState: setTrustDialog } = useTrustDialog();
  const { data: _plans } = useEsopPlans();
  const plans = _plans ?? [];
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    trust?: TrustDetails;
  }>({
    open: false,
    trust: undefined,
  });
  const errorMessage = useError();
  function deleteTrustShareholder() {
    deleteTrust(deleteDialog.trust?.id || "", {
      onSuccess: () => {
        toast("Trust deleted successfully!", {
          type: "success",
          autoClose: 2000,
        });
        setDeleteDialog({ open: false });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        setDeleteDialog({ open: false });
      },
    });
  }

  function isTrustAttachedToPlan(trust: TrustDetails) {
    return plans.some((p) => p.trustId === trust.id);
  }

  function handleSubmit(values: CreatePoolDto) {
    const saveDto = {
      poolSize: parseInt(values.poolSize.toString(), 10),
      poolCreationDate: formatWithTimeZone(values.poolCreationDate),
    };
    createOrUpdatePoolSize(saveDto, {
      onSuccess: (data) => {
        toast(data.data, {
          type: "success",
          autoClose: 2000,
        });
        navigate("/options/esopOverview");
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.errorsValidation.errorRaw);
        toast(err.response.data.errorsValidation.errorRaw, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  }
  const { isMobile } = useIsMobile();
  const { data: _trustDetails } = useTrustDetails();
  const trustDetails = _trustDetails || [];
  const currency = getCurrencyType();
  const gridApi = useRef<GridApi | null>(null);
  const gridRef = useRef<AgGridReact<TrustDetails>>(null);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "TRUST NAME",
      width: 200,
      field: "trustName",
      filter: "agSetColumnFilter",
      filterValueGetter: (params: any) =>
        params.getValue("trustName").props.trustName,
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      autoHeight: true,
      wrapText: true,
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
    },
    {
      headerName: "TOTAL SHARE",
      field: "trustPoolSize",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) => params.getValue("trustPoolSize"),
      valueFormatter: (params) => params.value.toLocaleString(currency),
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "AVAILABLE SHARE",
      field: "availableShares",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) => params.getValue("availableShares"),
      valueFormatter: (params) => params.value.toLocaleString(currency),
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: CTADropdown,
    },
  ];
  const rowData = useMemo(
    () =>
      trustDetails?.map((trust) => ({
        trustName: trust.trustName,
        trustPoolSize: trust.trustPoolSize,
        availableShares: trust.availableShares,
        actions: (
          <CTADropdown
            dropdownClassName=" xl:right-10 lg:right-8 md:right-2"
            actions={[
              {
                name: "Edit Trust",
                disabled: !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.OptionsPool,
                  DefaultAction.Edit
                ),
              },
              {
                name: "Delete Trust",
                disabled:
                  isTrustAttachedToPlan(trust) ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.OptionsPool,
                    DefaultAction.Delete
                  ),
              },
            ]}
            onAction={(action) => handleAction(trust, action)}
          />
        ),
      })),
    [trustDetails]
  );
  function handleAction(trust: TrustDetails, action: Action) {
    if (action.name === "Edit Trust" && !action.disabled) {
      setTrustDialog({
        open: true,
        mode: "Edit",
        trust,
      });
    } else if (action.name === "Delete Trust" && !action.disabled) {
      setDeleteDialog({ open: true, trust });
    }
  }
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };
  return (
    <VStack className="gap-4 mx-auto">
      <BCHeader
        className="items-baseline py-3"
        bcTitle="Overview and Insights"
        bcSubTitle="Options pool"
      ></BCHeader>
      <Box
        className={`${
          isMobile ? "p-4 overflow-auto" : "p-8 min-w-min"
        } " bg-white rounded-lg  border border-borderColor drop-shadow-box "`}
      >
        <HStack
          aria-label="toolbar"
          className="items-center justify-between mb-8"
        >
          <h4
            className={`${
              isMobile ? "text-sm1" : "text-lg"
            } font-medium text-gray-dark`}
          >
            Options Pool
          </h4>
        </HStack>
        <HStack className="justify-between gap-8">
          <div className="w-1/2">
            <Label className="text-sm font-normal">
              {isPoolExists ? "Pool Edit Date:" : "Pool Creation Date:"}
            </Label>
            <Input
              type="date"
              className={`${
                isMobile ? "" : "w-60"
              }text-gray-light cursor-not-allowed"`}
              {...formik.getFieldProps("poolCreationDate")}
            />
            {formik.touched.poolCreationDate &&
              formik.errors.poolCreationDate && (
                <Error text={formik.errors.poolCreationDate} />
              )}
          </div>
          <div className="w-1/2">
            <Label className="text-sm font-normal">
              Pool size:(Number of shares)
            </Label>
            <Input
              type="number"
              placeholder=""
              {...formik.getFieldProps("poolSize")}
            />{" "}
            {formik.touched && formik.errors.poolSize && (
              <Error text={formik.errors?.poolSize?.toString()} />
            )}
          </div>
        </HStack>
        <HStack className="justify-between w-full py-8 justify">
          <ButtonPrimary1
            className="background: #F5F9FB"
            onClick={() => {
              navigate("/options/esopOverview");
            }}
          >
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            className={
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.OptionsPool,
                DefaultAction.Edit
              )
                ? "opacity-50 cursor-not-allowed"
                : ""
            }
            disabled={
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.OptionsPool,
                DefaultAction.Edit
              )
            }
            loading={isLoading}
          >
            Save
          </ButtonPrimary>
        </HStack>
      </Box>
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false })}
        maxWidth="md"
      >
        <AlertDialog
          onClose={() => setDeleteDialog({ open: false })}
          open={deleteDialog.open}
          message={"Do you want to delete the trust?"}
          onPrimaryAction={deleteTrustShareholder}
          onSecondaryAction={() => setDeleteDialog({ open: false })}
          loading={deletingTrust}
        />
      </Dialog>

      <Box
        className={`${
          isMobile ? "p-4 overflow-auto" : "p-8 min-w-min"
        } " bg-white rounded-lg  border border-borderColor drop-shadow-box "`}
      >
        <HStack className="justify-between mb-8">
          <HStack aria-label="toolbar" className="items-center justify-between">
            <h4
              className={`${
                isMobile ? "text-sm1" : "text-lg"
              } font-medium text-gray-dark`}
            >
              Trust Details
            </h4>
          </HStack>
          <HStack>
            <ButtonPrimary
              disabled={
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.OptionsPool,
                  DefaultAction.Create
                )
              }
              onClick={() => setTrustDialog({ open: true, mode: "Add" })}
            >
              Create a Trust
            </ButtonPrimary>
          </HStack>
        </HStack>
        {trustDetails.length > 0 && (
          <HStack className="justify-between w-full">
            <Box
              style={{
                height: "400px",
              }}
              className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                animateRows={true}
                ref={gridRef}
                suppressRowClickSelection
                pagination={true}
              ></AgGridReact>
            </Box>
          </HStack>
        )}
      </Box>
    </VStack>
  );
}
export default AddOrEditPoolSize;
