import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import _ from "lodash";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useGetEmailCCSettings,
  useGetEmailTemplateNames,
  useSaveEmailCCSettings,
} from "../../queries/siteSettings";
import { EmailCCSettings } from "../../types/SiteSettings";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { useGetAssociatedUsers } from "../../queries/usersRoles";
import { MultiSelect } from "../../components/shared/MultiSelect";
import Tooltip from "../../components/shared/Tooltip";
import { usePermissionStore } from "../../store/permissionStore";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Resource,
  Action as DefaultAction,
} from "../../utils/interfaces/Companies";

function CCEmailSettings() {
  const { data: ccSettings } = useGetEmailCCSettings();
  const { data: emailTempaltes } = useGetEmailTemplateNames();
  const { permission } = usePermissionStore();
  const internalDomains = ["rulezero.com", "qarea.biz"];
  const isProduction = process.env.REACT_APP_ENV === "production";
  const initialSettingsSet = useRef(false);

  const allowedEdits = [
    "AccessGranted",
    "AccessStockGranted",
    "AccessStockOffered",
    "AccessStockOfferedNewPortal",
    "AccessStockGrantedNewPortal",
    "BuyBackOfferMail",
    "LiquidationOfferMailEmployee",
    "LiquidationCloseMailEmployee",
    "LiquidationCloseMailAdmin",
    "LiquidationStartMailAdmin",
    "EmployeeGrantOffer",
    "EsopActive",
    "EsopAmendmentReject",
    "EsopAmendment",
    "EsopApproval",
    "EsopRejected",
    "ExerciseRequest",
    "GrantAccepted",
    "GrantApproval",
    "GrantNotApproved",
    "GrantWithdraw",
    "GrantAcceptedByEmployee",
    "GrantRejectedByEmployee",
    "ReminderEmployeeGrantAccept",
    "ReminderEmployeeLogin",
    "RequestAccess",
    "ResetPasswordSuccess",
    "ExerciseApprovedEmployee",
    "ExerciseRejectedEmployee",
    "ExerciseRestartedEmployee",
    "ExerciseStartEmployee",
    "ExercisePausedEmployee",
    "ExerciseStartAdmin",
    "ExercisePausedAdmin",
    "ExerciseRestartedAdmin",
    "ExerciseClosedAdmin",
    "EmployeeExerciseRequest",
    "ReminderEmailForBuyBackOfferedGrants",
    "EmployeeDeactivationNotifyAdmin",
    "EmployeeDeactivationNotifyEmployee",
    "VestingCompleted",
    "SplitIntimationForEmployee",
  ];
  const { data: associatedUsers } = useGetAssociatedUsers();
  const users = useMemo(
    () =>
      isProduction
        ? associatedUsers?.filter(
            (associatedUser) =>
              associatedUser.email &&
              !internalDomains.some((domain) =>
                associatedUser.email.includes(domain)
              )
          )
        : associatedUsers,
    [associatedUsers]
  );

  const [initialSettings, setInitialSettings] = useState<EmailCCSettings>();

  const [ccEmailSettings, setccEmailSettings] = useState<EmailCCSettings>({
    sendCCEmailsToAdmins: false,
  });
  const { mutate: saveCCSettings, isLoading } = useSaveEmailCCSettings();

  useEffect(() => {
    if (ccSettings && emailTempaltes) {
      const settingObj = {
        sendCCEmailsToAdmins: ccSettings.sendCCEmailsToAdmins,
        emailTemplateCC: {},
      };
      const emailTemplateCC: EmailCCSettings["emailTemplateCC"] = {};
      emailTempaltes
        .filter((template) => allowedEdits.includes(template.templateType))
        .forEach(
          // eslint-disable-next-line no-return-assign
          (template) =>
            (emailTemplateCC[template.templateType] =
              (ccSettings.emailTemplateCC || {})[template.templateType] || [])
        );
      settingObj.emailTemplateCC = emailTemplateCC;
      setccEmailSettings(settingObj);
      if (!initialSettingsSet.current) {
        setInitialSettings(_.cloneDeep(settingObj));
        initialSettingsSet.current = true;
      }
    }
  }, [ccSettings, emailTempaltes]);

  const modifyUserList = (email: string[], templateType: string) => {
    const respectiveUserIds: string[] = users
      ?.filter((user) => email.includes(user.email))
      .map((user) => user.userId?.toString()) as string[];
    const currentCCSettings = ccEmailSettings.emailTemplateCC || {};
    if (templateType in currentCCSettings) {
      currentCCSettings[templateType] = respectiveUserIds;
    }
    setccEmailSettings((prev) => ({
      ...prev,
      emailTemplateCC: currentCCSettings,
    }));
  };
  const saveSettings = () => {
    saveCCSettings(ccEmailSettings, {
      onSuccess: () => {
        toast("Settings Saved successfully", {
          type: "success",
          autoClose: 2000,
        });
        setInitialSettings(ccEmailSettings);
      },
    });
  };
  const [handleChanges, setHandleChanges] = useState<boolean>();

  useEffect(() => {
    const hasChanges = _.isEqual(ccEmailSettings, initialSettings);
    setHandleChanges(hasChanges);
  }, [ccEmailSettings, initialSettings]);

  return (
    <Box className="md:w-[425px] lg:w-[850px] p-4 bg-white rounded-lg overflow-auto">
      <Box className="max-h-[550px] overflow-auto">
        <VStack className="justify-between p-4 gap-9">
          <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
            Email CC Settings
          </Box>
        </VStack>
      </Box>
      <HStack className="gap-10 p-4 pb-6 mr-2">
        <VStack className="w-full">
          <HStack className="justify-between w-full pb-6">
            <p className={` form-label text-sm font-medium w-2/3 inline-flex`}>
              Send CC Mails to Admin Users
              <Tooltip
                text={`If Enabled, users will receive CC emails, this setting doesn't follow private plans (if any)`}
                _className="w-96 left-4"
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </p>
            <Box className="flex justify-end w-1/3 pr-20 ml-2">
              <SwitchButton
                onClick={() => {
                  setccEmailSettings((value) => ({
                    ...value,
                    sendCCEmailsToAdmins: !value.sendCCEmailsToAdmins,
                  }));
                }}
                className=""
                value={ccEmailSettings.sendCCEmailsToAdmins}
                label=""
              />
            </Box>
          </HStack>
          {ccEmailSettings.sendCCEmailsToAdmins && (
            <Box className="overflow-auto transition-transform duration-500 ease-in-out transform max-h-64">
              <HStack className="w-full gap-6 p-2 pb-4 text-lg">
                <VStack className="justify-between w-1/3 align-middle">
                  Email Type
                </VStack>
                <VStack>Emails to Send CCs</VStack>
              </HStack>
              {ccEmailSettings.sendCCEmailsToAdmins &&
                users &&
                ccSettings &&
                Object.keys(ccEmailSettings.emailTemplateCC || {}).map(
                  (emailTemplateName) => (
                    <HStack
                      className="w-full gap-6 p-2"
                      key={emailTemplateName}
                    >
                      <VStack className="justify-between w-1/3 align-middle">
                        {emailTemplateName.replace(/([A-Z])/g, " $1").trim()}
                      </VStack>
                      <VStack className="w-2/3 h-24">
                        <MultiSelect
                          _className="w-full overflow-auto"
                          multiple={true}
                          placeholder={"--Select Users--"}
                          options={
                            users?.map((user) => ({ value: user.email })) || []
                          }
                          handleSelection={(e) => {
                            modifyUserList(e, emailTemplateName);
                          }}
                          optionValues={
                            users
                              ?.filter((user) =>
                                ccEmailSettings.emailTemplateCC?.[
                                  emailTemplateName
                                ]?.includes(user.userId?.toString() || "")
                              )
                              .map((user) => user.email) || []
                          }
                        />
                      </VStack>
                    </HStack>
                  )
                )}
            </Box>
          )}
        </VStack>
      </HStack>
      <HStack className="justify-between mt-12">
        <ButtonPrimary1
          type="reset"
          //   onClick={() =>}
          className="mr-8 text-red-500"
        >
          Cancel
        </ButtonPrimary1>
        <ButtonPrimary
          onClick={() => saveSettings()}
          type="submit"
          className={`${
            !handleChanges ||
            !determineUserAccessToResource(
              permission?.aclList || [],
              Resource.CompanySettings,
              DefaultAction.All
            )
              ? " cursor-not-allowed"
              : ""
          } flex items-center self-end justify-center`}
          disabled={
            handleChanges ||
            !determineUserAccessToResource(
              permission?.aclList || [],
              Resource.CompanySettings,
              DefaultAction.All
            )
          }
          loading={isLoading}
        >
          Save Settings
        </ButtonPrimary>
      </HStack>
    </Box>
  );
}

export default CCEmailSettings;
