import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Input } from "./InputField";
import { sort } from "../../utils/arrays";
import { HStack } from "../utils";

function SearchAutoComplete<T>({
  getSelectedValue,
  data,
  selectedOption,
  field,
}: {
  getSelectedValue: (item: T) => void;
  data: T[];
  selectedOption: T;
  field: keyof T;
}) {
  const sortedData = sort(data || [], field);
  const [searchText, setSearchText] = useState(selectedOption[field] as any);
  const [searched, setSearched] = useState<T[]>();

  const [open, setOpen] = useState(false);
  function searchOptions(text: string) {
    const match = sortedData.filter((d) => {
      const regex = new RegExp(`${text}`, "gi");
      return (d[field] as any).match(regex);
    });
    setSearched(match);
  }
  const contextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!searchText) setSearchText(selectedOption[field]);
      if (!(open && contextRef.current?.contains(e.target))) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [open, searchText]);

  return (
    <div
      ref={contextRef}
      className="bg-white z-10 absolute min-h-[45px] cursor-pointer"
    >
      <HStack className="items-center w-48 rounded shadow-sm bg-inherit text-slate-dark">
        <input
          type="text"
          className="w-full px-2 text-xs font-medium outline-none cursor-pointer bg-inherit form-input"
          value={searchText}
          placeholder="Select"
          onChange={(e) => {
            if (!e.target.value) setSearched(sortedData);
            setSearchText(e.target.value);
            searchOptions(e.target.value);
          }}
          onFocus={() => {
            setOpen(true);
            setSearched(sortedData);
            searchOptions(searchText);
          }}
        />
        {open ? (
          <Icon
            icon="material-symbols:keyboard-arrow-up-rounded"
            width="24"
            className="mr-2 "
            onClick={() => setOpen(!open)}
          />
        ) : (
          <Icon
            icon="material-symbols:keyboard-arrow-down-rounded"
            width="24"
            className="mr-2 "
            onClick={() => setOpen(!open)}
          />
        )}
      </HStack>
      <div className="overflow-auto border divide-y divide-gray-100 rounded shadow-sm max-h-60">
        {open &&
          searched?.map((o, index) => {
            const option = o as unknown as T;
            return (
              <div
                className="flex gap-2 p-2 divide-y rounded "
                key={index}
                onClick={() => {
                  setSearchText(option[field]);
                  getSelectedValue(option);
                  setOpen(!open);
                }}
              >
                {option[field] as unknown as string | number}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default SearchAutoComplete;
