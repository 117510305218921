import React, { useMemo, useRef } from "react";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-community";
import { differenceInSeconds } from "date-fns";
import { toast } from "react-toastify";
import { useGetAllCompanyConfigs, useScheduleSync } from "../../queries";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { formatDisplayDate } from "../../utils/date";
import { BulkActions } from "../esopOverview/GrantsTable";
import { Action } from "../../components/shared/Dropdown";

function SyncDetails() {
  const { data: syncConfigs, refetch: refetchConfigs } =
    useGetAllCompanyConfigs();
  const gridApi = useRef<GridApi | any>(null);
  const { mutate: startSync } = useScheduleSync();
  const noDataImageUrl =
    "https://equion-dev.s3.us-west-2.amazonaws.com/assets/emptyTable.png";
  const navigate = useNavigate();
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      menuTabs: ["filterMenuTab"],
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "DATASOURCE",
        field: "masterDataType",
        valueFormatter: (e) => {
          switch (e.value) {
            case "DarwinboxDataset":
              return "Darwin Box";
            default:
              return e.value;
          }
        },
      },
      {
        headerName: "STATUS",
        field: "status",
      },
      {
        headerName: "SYNC TYPE",
        field: "syncType",
      },
      {
        headerName: "SYNC TIME",
        field: "syncTime",
      },
      {
        headerName: "NOTE",
        field: "note",
        wrapText: false,
        filter: false,
      },
    ],
    []
  );
  const rowData = useMemo(
    () =>
      syncConfigs?.flatMap((config) => {
        const dataToDisplay = config?.employeeDataSyncHistories?.map(
          (history) => ({
            masterDataType: config.masterDataType,
            status: history.syncStatus,
            syncType: history.syncType,
            syncTime: formatDisplayDate(new Date(history.createdAt)),
            syncDuration: differenceInSeconds(
              new Date(history.updatedAt),
              new Date(history.createdAt)
            ),
            note: history.note,
          })
        );
        return dataToDisplay;
      }) || [],
    [syncConfigs]
  );
  const actions = useMemo(
    () =>
      Array.from(
        new Set(
          syncConfigs?.map((config) =>
            config.masterDataType === "DarwinboxDataset"
              ? "Darwin Box"
              : config.masterDataType
          )
        )
      ),
    [syncConfigs]
  );
  function handleBulkAction(action: Action) {
    if (action.name === "Darwin Box") {
      toast("Darwin Box sync started", { autoClose: 2000, type: "info" });
      startSync("DarwinboxDataset", {
        onSuccess: () => {
          toast("Sync Successful", { autoClose: 2000, type: "success" });
        },
        onError: () => {
          toast("Error while Syncing", { autoClose: 2000, type: "error" });
          refetchConfigs();
        },
      });
      setTimeout(() => {
        refetchConfigs();
      }, 1000);
    } else if (action.name === "GREYTIP") {
      toast("GREYTIP sync started", { autoClose: 2000, type: "info" });
      startSync("GREYTIP", {
        onSuccess: () => {
          toast("Sync Successful", { autoClose: 2000, type: "success" });
        },
        onError: () => {
          toast("Error while Syncing", { autoClose: 2000, type: "error" });
          refetchConfigs();
        },
      });
      setTimeout(() => {
        refetchConfigs();
      }, 1000);
    }
  }

  return (
    <VStack className="gap-4">
      <div className="flex items-baseline justify-between">
        <BCHeader
          className="items-baseline "
          bcTitle="Employee Record Sync Histories"
          bcDescription="View all your employee record sync histories"
        />
      </div>
      {syncConfigs && rowData.length === 0 && (
        <Box className="text-center justify-center align-middle">
          <img src={noDataImageUrl} alt="" className="h-44 my-5 w-56 mx-auto" />
          <p className="text-xxs pt-4">No Foreign datasource(s) configured</p>
          <ButtonPrimary
            className="mt-4"
            onClick={() => {
              navigate(`/options/onboarding/employees`);
            }}
          >
            Configure Datasource
          </ButtonPrimary>
        </Box>
      )}
      {syncConfigs && rowData.length > 0 && (
        <Box className="text-center justify-center align-middle m-4">
          <HStack className="items-center justify-between gap-4 bg-white p-4">
            <VStack>
              <p className="text-lg font-medium text-headerColor whitespace-nowrap">
                Sync Histories for the last 30 days
              </p>
            </VStack>
            <BulkActions
              title="Trigger Sync"
              actions={actions.map((action) => ({
                name: action,
              }))}
              onAction={(action) => handleBulkAction(action)}
            />
          </HStack>
          <HStack className="justify-between w-full">
            <Box
              style={{
                height: `${
                  (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
                }px`,
              }}
              className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
            >
              <AgGridReact
                alwaysShowHorizontalScroll
                rowClass={
                  "border-t border-dashed cursor-pointer hover:bg-slate-50 "
                }
                alwaysMultiSort
                onGridReady={onGridReady}
                animateRows={true}
                defaultColDef={defaultColDef}
                rowData={rowData}
                suppressCopyRowsToClipboard={true}
                suppressCopySingleCellRanges={true}
                suppressCellFocus={true}
                suppressMenuHide={true}
                columnDefs={columnDefs}
                rowMultiSelectWithClick={true}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                pagination={true}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
                }
              ></AgGridReact>
            </Box>
          </HStack>
        </Box>
      )}
    </VStack>
  );
}

export default SyncDetails;
