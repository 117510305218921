import { Icon } from "@iconify/react";
import { Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";

import { Input, Label } from "../../components/shared/InputField";
import {
  useApproveExercise,
  useExerciseRequestDetails,
  useRejectExerciseRequest,
} from "../../queries/exercise";
import { useError } from "../../store/errorStore";
import {
  ConversionMethodology,
  ExerciseReq,
  RaisedBy,
} from "../../types/Exercise";
import { ErrorInterface } from "../../types/OptionBuyback";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";

function ExerciseApproval({
  id,
  onClose = () => {},
  isEmployeeRaised,
}: {
  id: string;
  onClose: () => void;
  isEmployeeRaised: boolean;
}) {
  const currency = getCurrencyType();
  const { data } = useExerciseRequestDetails(id || "");
  const sharesAfterExercise = data?.isCashExercise
    ? 0
    : data?.conversionMethodology === ConversionMethodology.ROUND_UP
    ? Math.ceil((data?.numberOfShares || 0) * (data?.conversionRatio || 1))
    : Math.floor((data?.numberOfShares || 0) * (data?.conversionRatio || 1));
  const { mutate: approveExercise, status } = useApproveExercise();
  const { mutate: rejectRequest, status: isRequestRejected } =
    useRejectExerciseRequest();
  const errorMessage = useError();

  function handleReject() {
    rejectRequest(data?.id as string, {
      onSuccess: () => {
        toast("Exercise Request Rejected!", { type: "success" });
        onClose();
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }

  const initialValues: ExerciseReq = {
    esopPlanName: data?.esopPlanName || "",
    planType: data?.planType || "",
    exercisePrice: data?.exercisePrice || 0,
    optionHolderName: data?.optionHolderName || "",
    optionHolderId: data?.optionHolderId || "",
    optionsAvailable: data?.optionsAvailable || 0,
    numberOfShares: data?.numberOfShares || 0,
    isCashExercise: data?.isCashExercise || false,
    isFractional: data?.isFractional || false,
    dateOfExercise: data?.dateOfExercise || "",
    conversionRatio: data?.conversionRatio || 1,
    note: data?.note || "",
    id: data?.id || "",
    identifier: data?.identifier || "",
    status: data?.status || "",
    raisedBy: RaisedBy.ADMIN,
    isSellToCover: false,
    sharesSoldToCover: 0,
    amountReceived: 0,
    sellingPrice: data?.sellingPrice || 0,
    fmv: data?.fmv || 0,
  };

  const validationSchema = Yup.object({
    isSellToCover: Yup.boolean(),
    sharesSoldToCover: Yup.number().when("isSellToCover", {
      is: true,
      then: Yup.number()
        .required()
        .moreThan(0, "Shares sold should be greater than 0")
        .max(
          sharesAfterExercise,
          `Shares sold should be lesser than ${sharesAfterExercise}`
        ),
    }),
    sellingPrice: Yup.number().when("isSellToCover", {
      is: true,
      then: Yup.number()
        .required()
        .moreThan(0, "Selling price should be greater than 0"),
    }),
    amountReceived: Yup.number().when("isSellToCover", {
      is: true,
      then: Yup.number()
        .required()
        .moreThan(0, "Amount Received should be greater than 0"),
    }),
  });
  const currencySymbol = getCurrencySymbol();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (!values.isSellToCover) {
        values.sellingPrice = 0;
        values.amountReceived = 0;
        values.sharesSoldToCover = 0;
      }
      approveExercise(values, {
        onSuccess: () => {
          resetForm();
          toast("Exercise Request Submitted!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          const error = err.response.data as ErrorInterface;
          toast(error.errorMessage, { type: "error", autoClose: 5000 });
          onClose();
        },
      });
    },
  });
  useEffect(() => {
    if (formik.values.sellingPrice && formik.values.sharesSoldToCover) {
      formik.setFieldValue(
        "amountReceived",
        formik.values.sharesSoldToCover * formik.values.sellingPrice
      );
    } else {
      formik.setFieldValue("amountReceived", 0);
    }
  }, [formik.values.sharesSoldToCover, formik.values.sellingPrice]);
  return (
    <>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium stone-700">
        <h6 className="flex justify-between">
          Exercise{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <div className="border-b"></div>
      <VStack className="justify-between gap-4 p-4 px-10 min-h-[400px]">
        <HStack aria-label="section body" className="flex-wrap ">
          {[
            { field: "Employee Name", value: data?.optionHolderName },
            {
              field: "Plan Name",
              value: data?.esopPlanName,
            },
            {
              field: "Exercise Price",
              value: formatCurrency(data?.exercisePrice || 0, currency),
            },
            {
              field: "Exercisable Options",
              value: (data?.optionsAvailable || 0).toLocaleString(currency),
            },
            {
              field: "Options to Exercise",
              value: (data?.numberOfShares || 0).toLocaleString(currency),
            },
            {
              field: "Date of Exercise",
              value: formatDisplayDate(data?.dateOfExercise || "2000-01-01"),
            },
            { field: "Type", value: data?.isCashExercise ? "Cash" : "Stock" },
            {
              field: "Shares after Exercise",
              value: sharesAfterExercise,
            },
          ].map(({ field, value }, i) => (
            <HStack key={i} className="w-1/2 gap-8 p-3">
              <span className="w-1/2 text-sm font-normal text-stone-700">
                {field}
              </span>
              <span className="w-1/2 text-sm font-medium text-gray-600">
                {value?.toString()}
              </span>
            </HStack>
          ))}
        </HStack>
        {isEmployeeRaised && (
          <div className="mx-3 italic text-gray-500 w-fit">
            * Note: Once approved you can view this request in the ongoing
            Exercise Event.{" "}
          </div>
        )}
        {data?.planType === "RSU" && (
          <HStack className="items-center gap-8 mx-3 ">
            <Label className="text-sm font-normal">Sell to cover tax?</Label>
            <Switch {...formik.getFieldProps("isSellToCover")} />
          </HStack>
        )}
        {formik.values.isSellToCover ? (
          <VStack className="h-24 gap-2">
            <HStack className="gap-8 mx-3 ">
              <div className="flex-1 gap-8">
                <Label className="text-sm font-normal">
                  Shares sold for tax
                </Label>
                <Input
                  type="number"
                  {...formik.getFieldProps("sharesSoldToCover")}
                />
                {formik.touched.sharesSoldToCover &&
                  formik.errors.sharesSoldToCover && (
                    <Error text={formik.errors.sharesSoldToCover} />
                  )}
              </div>
              <div className="flex-1 gap-8">
                <Label className="text-sm font-normal">
                  Selling price ({currencySymbol})
                </Label>
                <Input
                  type="number"
                  {...formik.getFieldProps("sellingPrice")}
                />
                {data?.fmv !== 0 && (
                  <Label className="text-xs font-normal">
                    FMV is {formatCurrency(data?.fmv ?? 0, currency)}
                  </Label>
                )}
                {formik.touched.sellingPrice && formik.errors.sellingPrice && (
                  <Error text={formik.errors.sellingPrice} />
                )}
              </div>
            </HStack>
            <HStack className="w-1/2 gap-8 mx-3 pr-7">
              <div className="flex-1 gap-8">
                <Label className="text-sm font-normal">
                  Amount Received ({currencySymbol})
                </Label>
                <Input
                  type="number"
                  {...formik.getFieldProps("amountReceived")}
                />
                {formik.touched.amountReceived &&
                  formik.errors.amountReceived && (
                    <Error text={formik.errors.amountReceived} />
                  )}
              </div>
            </HStack>
          </VStack>
        ) : (
          <VStack className="h-24"></VStack>
        )}
        <HStack className="flex flex-row-reverse justify-between gap-4 mt-24">
          <HStack className="gap-4">
            <ButtonPrimary
              className={`flex items-center self-end justify-center ${
                status === "success" ? "bg-green-500" : ""
              }`}
              onClick={() => {
                if (status === "success") {
                  onClose();
                } else {
                  formik.handleSubmit();
                }
              }}
            >
              {(status === "idle" || status === "error") && "Approve"}
              {status === "loading" && (
                <HStack>
                  Please Wait...
                  <Icon
                    className=" animate-spin"
                    icon="lucide:loader-2"
                    width={18}
                  />
                </HStack>
              )}
              {status === "success" && (
                <Icon icon="clarity:success-standard-line" width={36} />
              )}
            </ButtonPrimary>
            {data?.planType !== "RSU" && (
              <ButtonPrimary
                className={`flex items-center self-end justify-center ${
                  isRequestRejected === "success" ? "bg-green-500" : ""
                }`}
                onClick={() => handleReject()}
              >
                {(isRequestRejected === "idle" ||
                  isRequestRejected === "error") &&
                  "Reject"}
                {isRequestRejected === "loading" && (
                  <HStack>
                    Please Wait...
                    <Icon
                      className=" animate-spin"
                      icon="lucide:loader-2"
                      width={18}
                    />
                  </HStack>
                )}
                {isRequestRejected === "success" && (
                  <Icon icon="clarity:success-standard-line" width={36} />
                )}
              </ButtonPrimary>
            )}
          </HStack>
          <HStack>
            <ButtonSecondary
              onClick={() => onClose()}
              className="text-gray-400 bg-slate-50"
            >
              Cancel
            </ButtonSecondary>
          </HStack>
        </HStack>
      </VStack>
    </>
  );
}
export default ExerciseApproval;
