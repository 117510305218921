import React, { useState } from "react";
import { Box, HStack, VStack } from "../../../components/utils";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";

const AllocationMethod = () => {
  const { compensationModellingData } = useOrgTemplateStore();
  return (
    <VStack className="w-full h-[500px]">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Choose Allocation Method
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Select a preferred option allocation method. You can choose from
          predefined models or fill in a few specific details.
        </HStack>
      </VStack>
      <VStack className="gap-4 pt-6 pl-10 font-medium text-sm1">
        <span>
          Please choose the method on how you would like to allocate options
        </span>
        <VStack className="gap-6 p-4 justify-self-auto">
          <HStack className="items-start gap-4">
            <Box className="p-0.25">
              <input
                type="radio"
                value={compensationModellingData?.allocationMethod}
                onChange={(e) => {}}
                className={`${
                  compensationModellingData?.allocationMethod ===
                  "departmentWise"
                    ? "radioChecked"
                    : ""
                }`}
                checked={
                  compensationModellingData?.allocationMethod ===
                  "departmentWise"
                }
              />
            </Box>
            <VStack className="">
              <label className="text-sm font-medium form-label">
                Department wise (%) allocation
              </label>
              <div className="flex justify-between text-gray-400 text-sm2">
                what department wise does, how it helps in allocation
              </div>
            </VStack>
          </HStack>
          <HStack className="items-start gap-4 text-gray-500 cursor-not-allowed">
            <Box className="p-0.25">
              <input
                type="radio"
                value={compensationModellingData?.allocationMethod}
                onChange={(e) => {}}
                className={`cursor-not-allowed ${
                  compensationModellingData?.allocationMethod === "preDefined"
                    ? "radioChecked"
                    : ""
                }`}
                checked={
                  compensationModellingData?.allocationMethod === "preDefined"
                }
                disabled={true}
              />
            </Box>
            <VStack className="">
              <label className="text-sm font-medium text-gray-500 cursor-not-allowed form-label">
                Choose from the pre-defined models
              </label>
              <div className="flex justify-between text-gray-400 text-sm2">
                what pre-defined does, how it helps in allocation
              </div>
            </VStack>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default AllocationMethod;
