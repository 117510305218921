import { Disclosure, Transition } from "@headlessui/react";
import { useLocation, useMatch, useNavigate } from "react-router";
import {
  Bars3Icon,
  XMarkIcon,
  ChartBarIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  FolderOpenIcon,
  HomeIcon,
  ShieldExclamationIcon,
  UserGroupIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import MobileNavBar from "./MobileNavBar";
import { useAuthorizationStore, useAuthStore } from "../store";
import { redirectToHissa } from "../components/utils";
import { usePermissionStore } from "../store/permissionStore";
import { AclList, Action, Resource } from "../utils/interfaces/Companies";
import { determineUserAccessToResource } from "../utils/auth";
import useIsMobile from "../utils/detectDevice";

interface SideNavItemType {
  name: string;
  permissions: AclList;
  icon: string;
  path: string;
  useCurrentPortal?: boolean;
  enabledForEsopOnlyCompany?: boolean;
  enabled?: boolean;
  childSubRoutes?: Array<string>;
}
export default function MobileSideNav({ setDialogOpen }: any) {
  const { authority } = useAuthorizationStore();

  const { permission } = usePermissionStore();
  const sideNavItemsMain: Array<SideNavItemType> = [
    {
      name: "Overview",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "ic:round-dashboard",
      path: "/options/esopOverview",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
      childSubRoutes: ["add-edit-pool"],
    },
    {
      name: "Plans & Grants",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "icon-park-outline:certificate",
      path: "/options/allPlans",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
      childSubRoutes: ["historic", "planView"],
    },
    {
      name: "Stakeholders",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "heroicons:user-group-solid",
      path: "/options/allEmployees",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
      childSubRoutes: ["employees", "employeeDetails"],
    },
    {
      name: "Liquidity & Exercise",
      permissions: { resource: Resource.All, action: Action.All },
      icon: "carbon:flow-connection",
      path: "/options/esopTransactions",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
      childSubRoutes: [
        "view-option-buy-back",
        "create-option-buyback",
        "edit-options-buyback",
        "exercise-event",
        "complete-buy-back",
        "/create-exercise-liquidation-flow",
        "create-exercise-event",
        "start-buy-back",
      ],
    },
    {
      name: "Vesting Timelines",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "entypo:bar-graph",
      path: "/options/vestingSchedules",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
    },
    {
      name: "Milestones",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "f7:flag-fill",
      path: "/options/milestones",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
      childSubRoutes: ["milestones"],
    },
    {
      name: "Valuation",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "mdi:file-report-outline",
      path: "/options/valuationReports",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
    },
    {
      name: "Reports",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "",
      path: "/options/reports",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      enabled: true,
    },
    {
      name: "Company Profile",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "flowbite:adjustments-horizontal-outline",
      enabledForEsopOnlyCompany: true,
      path: "/settings/companyProfile",
      useCurrentPortal: true,
      enabled: true,
    },
    {
      name: "Site Settings",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "ooui:page-settings",
      enabledForEsopOnlyCompany: true,
      path: "/settings/siteSettings",
      useCurrentPortal: true,
      enabled: true,
    },
    {
      name: "Faq",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "wpf:faq",
      enabledForEsopOnlyCompany: true,
      path: "/settings/faq",
      useCurrentPortal: true,
      enabled: true,
    },
    {
      name: "User & Roles",
      permissions: { resource: Resource.All, action: Action.All },
      icon: "eos-icons:role-binding-outlined",
      enabledForEsopOnlyCompany: true,
      path: "/auth/usersRoles",
      useCurrentPortal: true,
      enabled: true,
    },
  ];
  const [routes, setRoutes] = useState(sideNavItemsMain);

  useMemo(() => {
    if (!permission) return;
    const sideNavItmes: Array<SideNavItemType> = sideNavItemsMain.filter(
      (sideNavItem) =>
        determineUserAccessToResource(
          permission.aclList,
          sideNavItem.permissions.resource,
          sideNavItem.permissions.action
        )
    );
    setRoutes(sideNavItmes);
  }, [authority, permission]);
  const { isMobile } = useIsMobile();
  return (
    <Disclosure as="nav" className="z-50 duration-300 ease-in bg-white shadow">
      {({ open, close }) => (
        <>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex items-center mr-2 -ml-2 lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <></>
                    ) : (
                      <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>

              {open ? (
                <div
                  id="backdrop-element"
                  className="fixed inset-0 z-40 bg-black opacity-50"
                  onClick={(_) => close()}
                ></div>
              ) : null}

              {!open && <MobileNavBar routes={[]} />}
            </div>
          </div>
          <Transition
            enter="transition ease-out"
            enterFrom="transform translate-x-0"
            enterTo="transform translate-x-0"
            leaveFrom="transform translate-x-0"
            leaveTo="transform translate-x-full"
          >
            <Disclosure.Panel
              static
              className={`lg:hidden h-screen ease-in duration-300 ${
                open
                  ? " bg-white w-3/5 fixed inset-0 z-50 overflow-y-auto"
                  : "hidden"
              }`}
            >
              <ul className="pt-2 pb-3 space-y-1 transition-all duration-300 ease-in">
                <img
                  className="w-[72px]  ml-[16px] mt-[15px] mb-[15px] ml-30"
                  src="https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                  alt="hissa_logo"
                />
                {routes
                  .filter((menu) => menu.enabled)
                  .map((item) => (
                    <MobileSideNavItem
                      key={item.name}
                      sideNavItem={item}
                      open={open}
                    />
                  ))}
              </ul>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
function MobileSideNavItem({
  sideNavItem,
  open,
}: {
  sideNavItem: SideNavItemType;
  open: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useIsMobile(1024);

  const expectedRoute = sideNavItem.path.split("/");
  const locationAsList = location.pathname.split("/");

  const hasFormParamOrQueryParam = location.search || location.hash;
  const selected =
    !hasFormParamOrQueryParam &&
    (locationAsList[locationAsList.length - 1] ===
      expectedRoute[expectedRoute.length - 1] ||
      (sideNavItem.childSubRoutes &&
        sideNavItem.childSubRoutes.some((subRoute) =>
          locationAsList.includes(subRoute)
        )));
  return (
    <Disclosure.Button
      as="a"
      key={sideNavItem.name}
      onClick={() =>
        sideNavItem.useCurrentPortal
          ? navigate(sideNavItem.path)
          : redirectToHissa(sideNavItem.path)
      }
      className={` cursor-pointer ${
        selected
          ? "border-l-4 border-primary bg-secondary/5 text-gray-600"
          : "border-l-4 border-transparent text-gray-500"
      } py-3 pl-3 pr-4 text-base font-medium  hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6 flex flex-row justify-start gap-4 ${
        isMobile && "text-sm"
      }`}
    >
      <Icon
        className={`h-6 w-6 shrink-0  ${selected && "text-orange-500"} `}
        height={18}
        width={18}
        icon={sideNavItem.icon}
      ></Icon>
      <span
        className={`${!open && "hidden"} ease-in duration-300 ${
          selected && "text-orange-500"
        } `}
      >
        {sideNavItem.name}
      </span>
    </Disclosure.Button>
  );
}
