export enum EventType {
  CASH = "CASH",
  STOCK = "STOCK",
  LIQUIDITY = "LIQUIDITY",
}

export enum MonetaryEventState {
  DRAFT = "DRAFT",
  STARTED = "STARTED",
  CLOSED = "CLOSED",
  PAUSED = "PAUSED",
  COMPLETED = "COMPLETED",
}

export enum MonetaryTransactionType {
  BUYBACK = "BUYBACK",
  EXERCISE = "EXERCISE",
  EXERCISE_LIQUIDITY = "EXERCISE_LIQUIDITY",
  LIQUIDATION = "LIQUIDATION",
}

export enum LiquidityState {
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
  ACTIVE = "ACTIVE",
}

export enum OfferState {
  DRAFT = "DRAFT",
  OFFERED = "OFFERED",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING_EXERCISED = "PENDING_EXERCISED",
  SIGN_GIVEN = "SIGN_GIVEN",
  WILLINGNESS_GIVEN = "WILLINGNESS_GIVEN",
  COMPLETED = "COMPLETED",
}

export interface OptionBuyBackDetails {
  id?: string;
  companyId?: string;
  createdAt?: string;
  updatedAt?: string;
  description: string;
  startDate: string;
  endDate: string;
  vestingCutOffDate: string;
  monetaryDetails: MonetaryDetails;
  eventState: MonetaryEventState;
  liquidityState: LiquidityState;
  eventName: string;
  offerFilter: OfferFilter;
  isOnAutoPilot: boolean;
  askWillingness: boolean;
  recurringSetting: RecurringSetting;
  eventPercentage: number;
  eventType: EventType;
  transactionType: MonetaryTransactionType;
  buyerName: string;
  isDeleted: boolean;
  monetaryOffers: MonetaryOffers[];
}

export type MonetaryOffers = {
  id: string;
  companyId: string;
  eventId: string;
  grantId: string;
  amountReceived: number;
  createdAt: Date;
  updatedAt: Date;
  eligibility: number;
  willingness: number;
  offerState: OfferState;
  employeeId: string;
  isDeleted: boolean;
  conversionRatio?: number;
  conversionMethodology?: string;
};

export enum RecurringIntervalUnit {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export type RecurringSetting = {
  isRecurring: boolean;
  recurringInterval: number;
  recurringIntervalUnit: RecurringIntervalUnit;
};

export type MonetaryDetails = {
  sellingPrice: number;
  fmvPrice: number;
  price: number;
  miscCharges: number;
  paymentLink: string;
};

export const baseParticipationConstraints = {
  interval: 0,
  intervalUnit: "",
  maxFrequency: 0,
};
export interface ParticipationConstraints {
  maxFrequency: number;
  interval: number;
  intervalUnit: string;
}

export type OfferFilter = {
  department: string[];
  minimumTenure: number;
  minimumVestedOptions: number;
  minimumWillingness: number;
  minimumWillingnessPercentage: number;
  participationConstraints: ParticipationConstraints[];
};

export interface CumulativeOffer {
  employeeId: string;
  employeeName: string;
  isFractional: boolean;
  granted: number;
  vested: number;
  exercised: number;
  eligibility: number;
  willingness: number;
  state: OfferState;
  totalPriceToPay: number;
  stockExercisedOptions: number;
  cashExercisedOptions: number;
  forfeitedOptions: number;
  totalValueWillingness?: number;
  netPayout?: number;
}

export interface LiquidationDetails {
  name: string;
  startDate: string;
  endDate: string;
  sellingPrice: number | undefined;
  buyerName: string;
  totalAmount: number | undefined;
}

export interface ErrorInterface {
  httpStatusCode: number;
  errorType: "General" | "Raw" | "Validation" | "Unauthorized";
  errorMessage: string;
  errors: string[] | null;
  errorRaw: string;
  errorsValidation: ErrorValidationInterface;
}

export interface ErrorValidationInterface {
  httpStatusCode: number;
  errorType: string;
  errors: string[];
  errorRaw: any;
  errorsValidation: any;
  name: string;
  level: string;
  timestamp: string;
}
export type ApprovedExerciseDetails = {
  employeeName: string;
  hrId: string;
  grantId: string;
  identifier: string;
  grantedOptions: number;
  vestedOptions: number;
  dateOfExercise: string;
  isCashExercise: boolean;
  exercisedOptions: number;
  conversionRatio: number;
  conversionMethodology: string;
};
