/* eslint-disable no-empty-pattern */
import { QueryFunctionContext } from "react-query";
import {
  ESignSetting,
  EmailCCSettings,
  EmailSetting,
  EmailTemplate,
  EmailTemplateSaveObject,
  EmployeePortalSetting,
  GrantLetterSetting,
  ListOfEmailTemplates,
  TemplateContent,
} from "../types/SiteSettings";
import { UserDetailModel } from "../types/UserDetailModel";
import hissaApi from "./hissaApi";
import optionsApi from "./optionsApi";

export async function getEmployeePortalSettings(): Promise<EmployeePortalSetting> {
  return optionsApi
    .get("v1/settings/getEmployeePortalSettings")
    .then((res) => res.data.data.data);
}
export async function getEmailSettings(): Promise<EmailSetting> {
  return optionsApi
    .get("v1/settings/getEmailSettings")
    .then((res) => res.data.data.emailSettings);
}

export async function getGrantLetterSettings(): Promise<GrantLetterSetting> {
  return optionsApi
    .get("v1/settings/getGrantLetterSettings")
    .then((res) => res.data.data);
}
export async function getESignSettings(): Promise<ESignSetting> {
  return optionsApi
    .get("v1/settings/getESignSettings")
    .then((res) => res.data.data.esignSettings);
}
export async function setEmployeePortalSettingDetails(
  EmployeePortalSettingDetails: EmployeePortalSetting
): Promise<EmployeePortalSetting> {
  return optionsApi
    .put(
      "v1/settings/updateEmployeePortalSettings",
      EmployeePortalSettingDetails
    )
    .then((res) => res.data);
}

export async function setEmailSettingDetails(
  EmailSettingDetails: EmailSetting
): Promise<EmailSetting> {
  return optionsApi
    .put("v1/settings/updateEmailSettings", EmailSettingDetails)
    .then((res) => res.data);
}
export async function setGrantLetterSettingDetails(
  GrantLetterSettingDetails: GrantLetterSetting
): Promise<GrantLetterSetting> {
  return optionsApi
    .put("v1/settings/updateGrantLetterSettings", GrantLetterSettingDetails)
    .then((res) => res.data);
}
export async function setDeleteDefaultSignature({}): Promise<any> {
  return optionsApi.delete("/v1/settings/deleteSignature").then((res) => res);
}
export async function setESginSettingDetails(
  ESignSettingDetails: ESignSetting
): Promise<ESignSetting> {
  return optionsApi
    .put("v1/settings/updateESignSettings", ESignSettingDetails)
    .then((res) => res.data);
}

export async function getEmailTemplateNames(): Promise<string[]> {
  return hissaApi.get("email-template/dropdown").then((res) => res.data);
}

export async function getEmailTemplateContent(
  context: QueryFunctionContext
): Promise<EmailTemplate> {
  const query = `v1/settings/getEmailtemplateContent?id=${context.queryKey[1]}`;
  return optionsApi.get(query).then((res) => res.data?.data);
}

export async function saveEmailTemplateContent(
  template: EmailTemplateSaveObject
): Promise<string> {
  return optionsApi.post("v1/settings/saveEmailTemplate", template);
}

export async function getAllUser(): Promise<UserDetailModel[]> {
  return hissaApi.get("/user/getAllUser").then((res) => res?.data);
}

export async function listEmailTemplates(): Promise<ListOfEmailTemplates[]> {
  return optionsApi
    .get("v1/settings/listAllEmailTemplates")
    .then((res) => res?.data?.data);
}
export async function setCCEmailSettings(
  ccEmailSetting: EmailCCSettings
): Promise<EmailSetting> {
  return optionsApi
    .put("v1/settings/saveccSettings", ccEmailSetting)
    .then((res) => res.data);
}
export async function getCCEmailSettings(): Promise<EmailCCSettings> {
  return optionsApi
    .get("v1/settings/getccSettings")
    .then((res) => res.data.data);
}
