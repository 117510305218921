import { CircularProgress } from "@mui/material";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { useCreateTrust, useEditTrust, useTrustDetails } from "../../queries";
import { useError } from "../../store/errorStore";
import { TrustDetails } from "../../types/Grant";
import { trustBaseValues } from "../vestingSchedules/initValues";

export function AddOrEditTrust({
  mode = "Add",
  onClose = () => {},
  onCreate = () => {},
  trust,
}: Readonly<TrustProps>) {
  const { mutate: addTrust, isLoading: addLoading } = useCreateTrust();
  const { mutate: editTrust, isLoading: editLoading } = useEditTrust();
  const { data: _trustDetails } = useTrustDetails();
  const trustDetails = _trustDetails || [];
  const errorMessage = useError();
  const validationSchema = Yup.object().shape({
    trustName: Yup.string()
      .required("Trust Name required")
      .trim()
      .test(
        "trust Already Exists",
        "Trust Already Exists in the company",
        (value) => {
          if (
            mode === "Add" &&
            value &&
            trustDetails.some((t) => t.trustName === value)
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),

    trustPoolSize: Yup.number()
      .required("Trust Pool required")
      .moreThan(0, "Trust Pool Size should be more than 0")
      .integer("Shares cannot be in decimal")
      .test(
        "Cannot decrease trust shares",
        "Cannot decrease trust shares",
        (_value, context) => {
          const value = context.parent as TrustDetails;
          const trust = trustDetails.find(
            (t) => t.trustName === value.trustName
          );
          if (
            mode === "Edit" &&
            _value &&
            trust &&
            trust.trustPoolSize > _value
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
  });

  function getInitialValues() {
    if (mode === "Edit" && trust) {
      return trust;
    } else {
      return trustBaseValues;
    }
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        if (mode === "Edit") {
          editTrust(values, {
            onSuccess: () => {
              resetForm();
              toast("Trust Edit successfully", { type: "success" });
              onClose();
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        } else {
          addTrust(values, {
            onSuccess: (data) => {
              resetForm();
              onCreate(data.data);
              toast("Trust Added successfully", { type: "success" });
              onClose();
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        }
      }}
    >
      {({ touched, errors, getFieldProps, isValid, handleSubmit }) => (
        <VStack className="w-full gap-9">
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Trust Name</Label>
              <Input
                {...getFieldProps("trustName")}
                disabled={mode === "Edit"}
              />
              {touched.trustName && errors.trustName && (
                <Error text={errors.trustName} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Trust Pool Size(Number of shares)
              </Label>
              <Input
                type="number"
                className=""
                {...getFieldProps("trustPoolSize")}
              />
              {touched.trustPoolSize && errors.trustPoolSize && (
                <Error text={errors.trustPoolSize} />
              )}
            </div>
          </HStack>
          <HStack className="justify-between my-10 justify">
            <ButtonPrimary1 onClick={() => onClose()}>Back</ButtonPrimary1>
            <ButtonPrimary
              loading={editLoading || addLoading}
              className={`${!isValid && "bg-gray-400"}`}
              onClick={(e) => {
                handleSubmit();
              }}
            >
              {mode === "Edit" ? "Edit Trust" : "Create Trust"}
            </ButtonPrimary>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
}

type TrustProps = {
  mode?: "Edit" | "Add";
  onClose: () => void;
  onCreate: (trust: TrustDetails) => void;
  trust?: TrustDetails;
};

export function AddOrEditTrustWrapper({
  mode = "Add",
  onClose = () => {},
  onCreate = () => {},
  trust,
}: TrustProps) {
  return (
    <div>
      <div className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit" ? "Edit Trust" : "Add New Trust"}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <div className="px-10 pt-4">
        <AddOrEditTrust
          mode={mode}
          onClose={onClose}
          onCreate={onCreate}
          trust={trust}
        />
      </div>
    </div>
  );
}
