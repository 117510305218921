import { useParams, useNavigate } from "react-router-dom";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import {
  CellValueChangedEvent,
  ColDef,
  INumberCellEditorParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Icon } from "@iconify/react";
import { format, isValid } from "date-fns";
import { toast } from "react-toastify";
import {
  useCompleteMilestone,
  useGetMilestoneCompleteDetails,
} from "../../queries/milestone";
import {
  VestState,
  VestingMilestoneCompleteDetails,
  VestingMilestoneState,
} from "../../types/milestone";
import { sortComparator } from "../../utils/agGridUtils";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import StatusLabel from "../esopOverview/StatusLabel";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { formatTimeZoneSetting, formatWithTimeZone } from "../../utils/date";

type CheckMilestoneSummaryProps = {
  data: React.RefObject<AgGridReact<VestingMilestoneCompleteDetails>>;
  setClickedSummary: React.Dispatch<React.SetStateAction<boolean>>;
};

type GrantInfoCellProps = {
  grantIdentifier: string;
  state: string;
};
export function CheckMilestoneSummary(props: CheckMilestoneSummaryProps) {
  const { data: gridApi, setClickedSummary } = props;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { mutate: completeMilestone } = useCompleteMilestone();

  function getAllDataFromTable() {
    const allData: VestingMilestoneCompleteDetails[] = [];
    gridApi.current?.api?.forEachNode((node: any) => {
      if (node.data.newExtendedDate && node.data.newExtendedDate !== "") {
        node.data.newExtendedDate = formatTimeZoneSetting(
          node.data.newExtendedDate
        );
        node.data.newExtendedDate.setHours(5);
        node.data.newExtendedDate.setMinutes(35);
      }
      allData.push(node.data as VestingMilestoneCompleteDetails);
    });
    return allData;
  }

  function getSummaryData() {
    const allData = getAllDataFromTable();
    return allData.filter(
      (data) =>
        !(
          data.updateState === VestingMilestoneState.DONOTHING &&
          data.vestedPercentage !== 100
        )
    );
  }

  const [milestoneCompleteData] = useState(getSummaryData());
  const [allData] = useState(getAllDataFromTable());

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );

  const GrantInfoCell: React.FC<GrantInfoCellProps> = ({
    grantIdentifier,
    state,
  }) => (
    <VStack className="w-full">
      <HStack>
        <span className="px-2 text-xs font-medium text-gray-dark">
          {grantIdentifier}
        </span>
      </HStack>
      <HStack>
        <StatusLabel state={state} _className="leading-0" />
      </HStack>
    </VStack>
  );

  const [colDefs] = useState<ColDef[]>([
    {
      headerName: "GRANT ID",
      field: "grantIdentifier",
      cellStyle: { "padding-top": "15px" },
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: false,
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: GrantInfoCell,
    },
    {
      headerName: "EMPLOYEE NAME",
      field: "employeeName",
      cellStyle: { "line-height": "20px", "padding-top": "15px" },
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "MILESTONE OPTIONS",
      field: "optionsGranted",
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },

    {
      headerName: "VESTED OPTIONS",
      field: "optionsVested",
      sortable: true,
      cellEditor: "numericEditor",
      cellEditorParams: {
        precision: 4,
        step: 1,
        showStepperButtons: true,
      } as INumberCellEditorParams,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "PROGRESS %",
      field: "uiVestedPercentage",
      sortable: true,
      cellEditor: "numericEditor",
      cellEditorParams: {
        min: 0,
        max: 100,
        precision: 4,
        step: 1,
        showStepperButtons: true,
      } as INumberCellEditorParams,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "COMPLETION DATE",
      field: "targetDate",
      valueFormatter: (params) => {
        const formattedDate = params.value
          ? format(new Date(params.value), "dd-MMM-yyyy")
          : "N/A";
        return formattedDate;
      },
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: ["filterMenuTab"],
      autoHeight: true,
      wrapText: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          if (dateFromFilter.getTime() === new Date(cellValue).getTime())
            return 0;
          return dateFromFilter.getTime() > new Date(cellValue).getTime()
            ? -1
            : 1;
        },
      },
    },
    {
      headerName: "LAPSED OPTIONS",
      menuTabs: [],
      valueGetter: (params) =>
        params.data.updateState === VestingMilestoneState.LAPSE
          ? params.data.optionsGranted - params.data.optionsVested
          : 0,
    },
    {
      headerName: "NEW END DATE",
      field: "newExtendedDate",
      valueFormatter: (params) => {
        const formattedDate = params.value
          ? format(new Date(params.value), "dd-MMM-yyyy")
          : "";
        return formattedDate;
      },
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          if (dateFromFilter.getTime() === new Date(cellValue).getTime())
            return 0;
          return dateFromFilter.getTime() > new Date(cellValue).getTime()
            ? -1
            : 1;
        },
      },
    },
  ]);

  const rowData = useMemo(() => {
    const completeData = milestoneCompleteData?.map((milestone) => {
      const data = {
        employeeName: milestone.employeeName,
        employeeId: milestone.employeeId,
        grantId: milestone.grantId,
        grantIdentifier: (
          <GrantInfoCell
            grantIdentifier={milestone.grantIdentifier}
            state={
              milestone.updateState === VestingMilestoneState.EXTEND
                ? "Extended"
                : "Completed"
            }
          />
        ),
        optionsGranted: milestone.optionsGranted,
        optionsVested: milestone.optionsVested ?? 0,
        vestedPercentage: milestone.vestedPercentage ?? 0,
        uiVestedPercentage: milestone.uiVestedPercentage ?? 0,
        newExtendedDate: milestone.newExtendedDate,
        completionDate: milestone.completionDate ?? new Date(),
        notes: milestone.notes ?? "",
        updateState: milestone.updateState,
        milestoneId: milestone.milestoneId,
        projectedVestingId: milestone.projectedVestingId,
        projectedVestingState: milestone.projectedVestingState,
        isFractional: milestone.isFractional,
        milestoneName: milestone.milestoneName,
        targetDate: new Date(milestone.targetDate),
      };
      return data;
    });
    return completeData;
  }, []);

  function handleSubmit() {
    completeMilestone(milestoneCompleteData, {
      onSuccess: () => {
        toast("Event Successfully Executed!", {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/milestones`);
      },
      onError: (err: any) => {
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  }

  return (
    <>
      <HStack className="items-center justify-between py-2 bg-white rounded-md">
        <VStack className="px-6 lg:justify-start">
          <p className="text-lg font-medium text-gray-600 ">
            Milestone Summary -{" "}
            {milestoneCompleteData && milestoneCompleteData[0]?.milestoneName}
          </p>
          <p className="mr-4 text-sm font-medium text-gray-500 md:mr-12">
            {`${milestoneCompleteData?.length} Grants`}
          </p>
        </VStack>
        <HStack className="items-center justify-end py-2">
          <HStack>
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="w-full text-xs font-normal border-0 outline-none bg-inherit"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            ></input>
          </HStack>
        </HStack>
      </HStack>
      <Box
        style={{ height: "calc(84vh - 100px)" }}
        className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
      >
        <AgGridReact
          rowData={rowData}
          quickFilterText={searchText}
          rowSelection="multiple"
          columnDefs={colDefs}
          alwaysMultiSort
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          suppressMenuHide={false}
          tooltipShowDelay={1000}
          rowMultiSelectWithClick={true}
          rowClass={"border-t border-dashed"}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: "#f8f8f8" };
            } else {
              return { background: "#ffffff" };
            }
          }}
        />
      </Box>
      <Box className="flex justify-between px-2 py-2 pt-4 bg-white rounded-md">
        <ButtonPrimary1 onClick={() => setClickedSummary(false)}>
          Back
        </ButtonPrimary1>
        <div className=""></div>
        <ButtonPrimary
          className="text-right"
          disabled={milestoneCompleteData.length <= 0}
          onClick={() => {
            handleSubmit();
          }}
        >
          Complete
        </ButtonPrimary>
      </Box>
    </>
  );
}
