import Dialog from "@mui/material/Dialog";
import { ColDef, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import AlertDialog from "../../components/shared/AlertDialog";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import {
  useDeleteValuation,
  useValuationDetails,
} from "../../queries/Valuation";
import { usePermissionStore } from "../../store/permissionStore";
import { Valuation } from "../../types/ValuationType";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { capitalizeFirstLetter } from "../../utils/string";
import AddOrEditValuation from "./AddOrEditValuation";
import { useTableStateManagement } from "../../components/shared/TableHook";
import { TableId } from "../../constants/TableIdConstants";
import CloudSetting from "../../components/shared/CloudIcon";

function ValuationTable() {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const gridApi = useRef<GridApi | null>(null);
  const { data: _valuationData } = useValuationDetails();

  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Add" | "Edit";
    data?: Valuation;
  }>({
    open: false,
    mode: "Add",
    data: undefined,
  });
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    data?: Valuation;
  }>({
    open: false,
    data: undefined,
  });
  const valuationData = _valuationData?.sort(
    (a, b) =>
      new Date(b.dateOfValuation).getTime() -
      new Date(a.dateOfValuation).getTime()
  );

  useEffect(() => {
    if (valuationData !== undefined) {
      setFilteredRowData(valuationData);
    }
  }, [valuationData]);

  const { permission } = usePermissionStore();
  const { mutate: deleteValuation } = useDeleteValuation();
  const gridRef = useRef<AgGridReact<Valuation>>(null);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "NAME",
        width: 200,
        field: "name",
        filter: "agSetColumnFilter",
        cellStyle: { "line-height": "20px", "padding-top": "15px" },
        filterValueGetter: (params: any) => params.getValue("name"),
        initialWidth: 200,
        minWidth: 200,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        filterParams: {
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },
        menuTabs: ["filterMenuTab"],
        cellRendererParams: ({ value }: { value: { props: any } }) =>
          value.props,
      },
      {
        headerName: "VALUATION DATE",
        field: "dateOfValuation",
        filter: "agDateColumnFilter",
        menuTabs: ["filterMenuTab"],
        sortable: true,
        autoHeight: true,
        wrapText: true,
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (dateFromFilter.getTime() === new Date(cellValue).getTime())
              return 0;
            return dateFromFilter.getTime() > new Date(cellValue).getTime()
              ? -1
              : 1;
          },
        },
      },
      {
        headerName: "REPORT DATE",
        field: "reportDate",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (dateFromFilter.getTime() === new Date(cellValue).getTime())
              return 0;
            return dateFromFilter.getTime() > new Date(cellValue).getTime()
              ? -1
              : 1;
          },
        },
        menuTabs: ["filterMenuTab"],
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "TYPE",
        field: "unitType",
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterValueGetter: (params) => params.getValue("unitType"),

        filterParams: {
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "VALUATION(PPS)",
        field: "pps",
        filter: "agNumberColumnFilter",
        menuTabs: ["filterMenuTab"],
        minWidth: 200,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        valueFormatter: (params) => formatCurrency(params.value, currency),
      },
      {
        headerName: "METHODOLOGY",
        field: "valuationMethodology",
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterParams: {
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },
        sortable: true,
        autoHeight: true,
        minWidth: 200,
        wrapText: true,
      },
      {
        headerName: "SOURCE",
        field: "valuationSource",
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        filterParams: {
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        resizable: false,
        sortable: false,
        menuTabs: ["columnsMenuTab"],
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );
  const rowData = useMemo(
    () =>
      valuationData?.map((valuation) => ({
        name: valuation.name,
        dateOfValuation: formatDisplayDate(valuation.dateOfValuation),
        reportDate: formatDisplayDate(valuation.reportDate),
        unitType: valuation.unitType,
        pps: valuation.pps,
        valuationMethodology: valuation.valuationMethodology,
        valuationSource: capitalizeFirstLetter(
          valuation.valuationSource.toLowerCase()
        ),
        actions: (
          <CTADropdown
            dropdownClassName=" xl:right-10 lg:right-8 md:right-2"
            actions={[
              {
                name: "Edit Valuation",
                disabled: !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.Valuation,
                  DefaultAction.Edit
                ),
              },
              {
                name: "Delete Valuation",
                disabled: !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.Valuation,
                  DefaultAction.Delete
                ),
              },
            ]}
            onAction={(action) => handleAction(valuation, action)}
          />
        ),
      })),
    [valuationData]
  );
  function handleAction(valuation: Valuation, action: Action) {
    if (action.name === "Edit Valuation" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        data: valuation,
      });
    } else if (action.name === "Delete Valuation" && !action.disabled) {
      setDeleteDialog({ open: true, data: valuation });
    }
  }
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.valuationTable);

  const [filteredRowData, setFilteredRowData] = useState<any>(valuationData);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();
    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter(filterModel);
  };
  const onGridReady = (params: any) => {
    gridApi.current = params;
  };

  function deleteValuationEntry() {
    deleteValuation(deleteDialog.data?.id || "", {
      onSuccess: () => {
        toast("Valuation deleted successfully!", {
          type: "success",
          autoClose: 2000,
        });
        setDeleteDialog({ open: false });
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        setDeleteDialog({ open: false });
      },
    });
  }
  return (
    <Box className="w-full p-4 bg-white rounded-lg ">
      <HStack className="items-center justify-between mb-8">
        <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">
            Valuation Details
          </p>
          <p className="text-xs font-medium text-gray-light">
            {filteredRowData?.length} Valuation Reports
          </p>
          <HStack className="p-0 m-0">
            <CloudSetting
              tableId={TableId.valuationTable}
              isLoading={isSaving}
              filterCondition={filterCondition}
              setFilterCondition={setFilterCondition}
            />
          </HStack>
        </VStack>
        <HStack className="max-h-[30px]">
          {" "}
          <ButtonPrimary
            onClick={() => {
              setDialog({ open: true });
            }}
            className={`flex justify-end flex-reverse-row items-center
            ${
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.Valuation,
                DefaultAction.Create
              ) && "opacity-50 cursor-not-allowed "
            }
            `}
            disabled={
              !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.Valuation,
                DefaultAction.Create
              )
            }
          >
            Add Valuation
          </ButtonPrimary>
        </HStack>

        <Dialog open={dialog.open} maxWidth="md">
          <AddOrEditValuation
            onClose={() => setDialog({ open: false })}
            mode={dialog.mode || "Add"}
            data={dialog.data}
          />
        </Dialog>
      </HStack>

      <HStack className="justify-between w-full">
        <Box
          style={{
            height: "400px",
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            animateRows={true}
            ref={gridRef}
            suppressRowClickSelection
            suppressMenuHide={true}
            pagination={true}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>

      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false })}
        maxWidth="md"
      >
        <AlertDialog
          onClose={() => setDeleteDialog({ open: false })}
          open={deleteDialog.open}
          message={"Do you want to delete the valuation?"}
          onPrimaryAction={deleteValuationEntry}
          onSecondaryAction={() => setDeleteDialog({ open: false })}
        />
      </Dialog>
    </Box>
  );
}

export default ValuationTable;
