import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-community";
import { useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import SearchInput from "../../../components/shared/SearchInput";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../../components/utils";
import { Department } from "../../../types/modelling";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import AddDepartmentDialog from "./dialogs/AddDepartmentDialog";
import { DepartmentData } from "./utils/OrgStructureJSONDataProc";

export default function GradeGuide() {
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };
  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  const convertDepartmentDataToDepartment = (departments: {
    [departmentName: string]: DepartmentData;
  }) => {
    const data: Department[] = [];
    data.push(
      ...Object.entries(departments).map(([name, data]) => ({
        name,
        identifier: data.identifier,
      }))
    );
    return data;
  };
  const { selectedCompanyData, deleteDepartmentFromCompanyData } =
    useOrgTemplateStore();
  const [departments, setDepartments] = useState<Array<Department>>(
    selectedCompanyData && selectedCompanyData.departments
      ? convertDepartmentDataToDepartment(selectedCompanyData.departments)
      : []
  );
  useEffect(() => {
    if (selectedCompanyData && selectedCompanyData.departments) {
      setDepartments(
        convertDepartmentDataToDepartment(selectedCompanyData.departments)
      );
    }
  }, [selectedCompanyData]);
  function handleDelete() {
    const rows = gridApi.current?.getSelectedRows();
    const names = rows.map((row: Department) => row.name);
    for (const name of names) {
      deleteDepartmentFromCompanyData(name);
    }
  }
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      cellStyle: {
        lineHeight: "1.5",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "DEPARTMENT",
      checkboxSelection: true,
      width: 200,
      autoHeight: true,
      wrapText: true,
      headerCheckboxSelection: true,
      field: "name",
      filter: "agSetColumnFilter",
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "IDENTIFIER",
      autoHeight: true,
      field: "identifier",
      filter: "agSetColumnFilter",
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      autoHeight: true,
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
  ];
  const rowData = useMemo(() => departments || [], [departments]);

  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Create Grade Guide
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Add department grades within the company. The department identifier
          helps uniquely identify each department in the system. To make
          deletions, check the box adjacent to the department.
        </HStack>
      </VStack>
      <HStack className="justify-end gap-8 pt-16 pr-5">
        <div className="flex gap-6 text-xs text-center justify-evenly">
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
          <Icon
            icon={"mdi:delete"}
            className="cursor-pointer text-orange-501"
            width={30}
            height={30}
            onClick={() => handleDelete()}
          />
          <ButtonPrimary
            onClick={() => {
              setDialog({ open: !dialog.open });
            }}
          >
            Add
          </ButtonPrimary>
        </div>
      </HStack>
      <Dialog
        maxWidth="xl"
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
      >
        <div className="w-[500px] min-h-min mx-auto bg-white rounded-lg">
          <AddDepartmentDialog
            open={dialog.open}
            onClose={() => setDialog({ open: false })}
          />
        </div>
      </Dialog>
      <HStack className="justify-between w-full pt-6 pb-6 pl-8">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}
