import { Icon } from "@iconify/react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import {
  MonetaryEventState,
  MonetaryTransactionType,
  OfferState,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import { Box, HStack, VStack, isEsopViewer } from "../utils";
import Tooltip from "./Tooltip";

export type SummaryProps = {
  name: string;
  transactionEvent: OptionBuyBackDetails[];
};
function SummaryCard(props: SummaryProps) {
  const currency = getCurrencyType();
  const isUserEsopViewer = isEsopViewer();
  const navigate = useNavigate();
  const { name, transactionEvent } = props;
  const eventStates = transactionEvent.map((event) => event.eventState);
  const hasActiveState = eventStates.some(
    (state) =>
      state === MonetaryEventState.DRAFT ||
      state === MonetaryEventState.STARTED ||
      state === MonetaryEventState.CLOSED
  );
  const latestSorted = _.orderBy(
    _.sortBy(transactionEvent, (o) => o.startDate)
  ).reverse()[0];
  const allValues = _.orderBy(_.sortBy(transactionEvent, (o) => o.startDate));
  const {
    data: _optionBuyBack,
    isPlaceholderData,
    isFetching,
  } = useGetOptionBuyback();

  const isCreateDisabled = (_optionBuyBack || []).some(
    (ob) => ob.eventState !== MonetaryEventState.COMPLETED
  );
  // find the event pps
  function getPPSfromOffers(items: OptionBuyBackDetails) {
    const totalAmountReceived =
      items?.monetaryOffers
        ?.filter(
          (offer) =>
            offer.offerState === OfferState.ACCEPTED ||
            offer.offerState === OfferState.COMPLETED
        )
        .reduce(
          (accumulator: any, { amountReceived }: { amountReceived: number }) =>
            accumulator + amountReceived,
          0
        ) || 0;
    const totalGivenWillingness =
      items?.monetaryOffers
        ?.filter(
          (offer) =>
            offer.offerState === OfferState.ACCEPTED ||
            offer.offerState === OfferState.COMPLETED
        )
        .reduce(
          (accumulator: any, { willingness }: { willingness: number }) =>
            accumulator + willingness,
          0
        ) || 0;

    const pps = totalAmountReceived / totalGivenWillingness || 0;
    return pps;
  }
  function getTotalMoney(item: OptionBuyBackDetails) {
    let totalWillingNess = 0;
    (item?.monetaryOffers || []).forEach((offer) => {
      if (
        offer.offerState === OfferState.COMPLETED ||
        offer.offerState === OfferState.ACCEPTED ||
        offer.offerState === OfferState.PENDING_EXERCISED
      ) {
        totalWillingNess += offer.willingness;
      }
    });
    return totalWillingNess;
  }

  const totalAmount = allValues.reduce((acc, item) => {
    const totalMoney = getTotalMoney(item);
    const value =
      totalMoney *
      (item.transactionType === MonetaryTransactionType.BUYBACK
        ? item?.monetaryDetails.price || 0
        : item.transactionType === MonetaryTransactionType.EXERCISE
        ? getPPSfromOffers(item)
        : item?.monetaryDetails.sellingPrice || 0);
    return acc + value;
  }, 0);

  function createEvent(name: string): void {
    if (name === "Exercise") {
      navigate("/options/create-exercise-event?mode=create");
    } else if (name === "Buy Back") {
      navigate("/options/create-option-buyback?mode=create");
    } else if (name === "Exercise and Liquidation") {
      navigate("/options/create-exercise-liquidation-flow?mode=create");
    }
  }
  return (
    <>
      <VStack
        className={`${
          isPlaceholderData ? "loading" : " "
        } gap-2 m-2 rounded shadow-md border-red h-44 max-h-52 relative ${
          !isPlaceholderData && hasActiveState
            ? "border-b-4 border-green-500"
            : ""
        }`}
      >
        {latestSorted ? (
          <>
            <VStack className=" justify-between gap-2 p-4 ">
              <p className="text-base font-semibold text-gray-900">
                {formatCurrency(totalAmount, currency)}
              </p>
              <p className="text-sm font-semibold text-gray-light">
                {latestSorted.transactionType ===
                MonetaryTransactionType.BUYBACK
                  ? "Total Cash out"
                  : latestSorted.transactionType ===
                    MonetaryTransactionType.EXERCISE
                  ? "Total Exercise "
                  : "Total Exercise and Liquidate "}{" "}
                value
              </p>
            </VStack>
            <HStack
              className={`${
                isFetching ? "loading" : "justify-between pl-4 pr-4 pt-4"
              } `}
            >
              <p className="text-sm font-semibold text-gray-dark">
                {latestSorted.transactionType ===
                MonetaryTransactionType.BUYBACK
                  ? latestSorted?.eventName || ""
                  : latestSorted.eventName === MonetaryTransactionType.EXERCISE
                  ? getPPSfromOffers(latestSorted)
                  : latestSorted?.eventName || 0}
              </p>
              <p className="text-sm font-semibold text-gray-400">
                {formatCurrency(
                  getTotalMoney(latestSorted) *
                    (latestSorted.transactionType ===
                    MonetaryTransactionType.BUYBACK
                      ? latestSorted?.monetaryDetails.price || 0
                      : latestSorted.transactionType ===
                        MonetaryTransactionType.EXERCISE
                      ? getPPSfromOffers(latestSorted)
                      : latestSorted?.monetaryDetails.sellingPrice || 0),
                  currency
                )}
              </p>
            </HStack>
            <HStack className="justify-between pl-4 pr-4 pb-2">
              <div className="font-medium text-xs text-gray-light">
                <p>{formatDisplayDate(latestSorted?.startDate)}</p>
              </div>
              <HStack>
                <div className="font-medium text-xs text-gray-light">
                  {latestSorted.transactionType ===
                  MonetaryTransactionType.BUYBACK
                    ? "PPS :"
                    : latestSorted.transactionType ===
                      MonetaryTransactionType.LIQUIDATION
                    ? "Sale Price "
                    : ""}{" "}
                </div>
                <div className="text-xs font-medium text-gray-light">
                  &nbsp;
                  {(latestSorted.transactionType ===
                    MonetaryTransactionType.LIQUIDATION ||
                    latestSorted.transactionType ===
                      MonetaryTransactionType.BUYBACK) &&
                    formatCurrency(
                      latestSorted.transactionType ===
                        MonetaryTransactionType.BUYBACK
                        ? latestSorted?.monetaryDetails.price || 0
                        : latestSorted.transactionType ===
                          MonetaryTransactionType.LIQUIDATION
                        ? latestSorted?.monetaryDetails.sellingPrice || 0
                        : 0,
                      currency
                    )}
                </div>
              </HStack>
            </HStack>
          </>
        ) : (
          <>
            <HStack className="justify-between gap-2 p-4 ">
              <div className="text-sm font-medium text-gray-dark">{name}</div>
            </HStack>
            <HStack className="justify-center ">
              <VStack className="items-center gap-2">
                {isCreateDisabled ? (
                  <Tooltip
                    text={`${
                      isCreateDisabled &&
                      "To create a new Event, Please complete your previous closed events"
                    }`}
                    _className="w-[200px]"
                  >
                    <Icon
                      icon="fluent:add-circle-28-regular"
                      className={`${
                        isCreateDisabled
                          ? "text-opacity-20 cursor-not-allowed"
                          : ""
                      } cursor-pointer text-orange-501 h-11 w-11`}
                      onClick={() => {
                        if (isCreateDisabled || isUserEsopViewer) return;
                        createEvent(name);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Icon
                    icon="fluent:add-circle-28-regular"
                    className={`${
                      isCreateDisabled
                        ? "text-opacity-20 cursor-not-allowed"
                        : ""
                    } cursor-pointer text-orange-501 h-11 w-11`}
                    onClick={() => {
                      if (isCreateDisabled || isUserEsopViewer) return;
                      createEvent(name);
                    }}
                  />
                )}
                <Box className="items-center text-xs text-gray-dark">{`Add ${name} event`}</Box>
              </VStack>
            </HStack>
          </>
        )}
      </VStack>
    </>
  );
}

export default SummaryCard;
