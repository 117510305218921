import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getYear } from "date-fns";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  getCompanyName,
} from "../../components/utils";

import { Input, Label } from "../../components/shared/InputField";
import { ReportDto } from "../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { generateEmployeeDetailsReport } from "../../api/Esop";

function EmployeeDetailsReportDialog({
  onClose = () => {},
}: Readonly<{
  onClose: () => void;
}>) {
  const fileName = `Employee Details Report_${getCompanyName()}`;
  async function downloadReport(reportDto: ReportDto) {
    onClose();
    const blob = await convertBase64ToBlob(
      reportDto.base64File,
      reportDto.fileType
    );
    downloadBlobObject(blob, fileName);
  }
  const currentDate = new Date();

  const initialValues = {
    startDate: "",
    endDate: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      generateEmployeeDetailsReport({
        startDate: values.startDate,
        endDate: values.endDate,
      })
        .then((res) => {
          downloadReport(res.data);
        })
        .catch((err) => {
          toast("Something Went Wrong!", { type: "error", autoClose: 2000 });
        });
    },
  });

  return (
    <VStack>
      <div className="p-8 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Download Employee Details Report
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </h6>
      </div>
      <VStack>
        <HStack className="justify-between pl-6">
          <VStack className=" justify-between  pr-[50px]">
            <Label className="pt-6 text-sm font-normal">
              Transaction Start Date
            </Label>
            <Box>
              <Input
                type="date"
                value={formik.values.startDate}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (!e.target.value) return;
                  formik.setFieldValue("startDate", e.target.value);
                }}
              />
            </Box>
          </VStack>
          <VStack className=" justify-between   pr-[50px]">
            <Label className="pt-6 text-sm font-normal">
              Transaction End Date
            </Label>
            <Box>
              <Input
                type="date"
                value={formik.values.endDate}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (!e.target.value) return;
                  formik.setFieldValue("endDate", e.target.value);
                }}
              />
            </Box>
          </VStack>
        </HStack>
        <HStack className="justify-between px-4 py-6 mt-7">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Generate
          </ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
}
export default EmployeeDetailsReportDialog;
