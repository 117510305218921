/* eslint-disable max-len */
import { getYear } from "date-fns";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
  getCompanyName,
} from "../../components/utils";
import { useDownloadEsopAccounting } from "../../queries";
import { EsopAccountingReq } from "../../types/EsopPlan";
import { ErrorInterface } from "../../types/OptionBuyback";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";

const EsopAccountingDialog = ({
  open,
  onClose = () => {},
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const currentDate = new Date();
  const initialValues = {
    attritionValue: 0,
    hasSelectedIGAAP: false,
    hasSelectedFairMarketValue: false,
    hasSelectedGradedMethod: false,
    changeValuationPerVesting: false,
    planType: "sar",
    startDate: `${getYear(currentDate)}-04-01`,
    endDate: `${getYear(currentDate) + 1}-03-31`,
  };
  const [accountingStandard, setAccountingStandard] = useState("IND-AS");
  const [accountingMethod, setAccountingMethod] = useState("Graded");
  const [valuationType, setValuationType] = useState("FMV");
  const [planType, setPlanType] = useState("ALL");
  const [methods, setMethods] = useState(["Graded"]);
  const [changeValuationPerVesting, setChangeValuationPerVesting] =
    useState("Grant Date");
  const esopAccountingFileName = `ESOP_Accounting_${getCompanyName()}.xlsx`;
  const { mutate: downloadEsopAccounting } = useDownloadEsopAccounting();
  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .when("startDate", (startDate, schema) =>
        startDate
          ? schema.min(startDate, "End date must be greater than start date")
          : schema
      ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const reqValues: EsopAccountingReq = {
        attritionValue:
          valuationType === "Intrinsic" ? 0 : values.attritionValue,
        hasSelectedFairMarketValue: valuationType === "FMV",
        hasSelectedGradedMethod: accountingMethod === "Graded",
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
        planType: planType.toLowerCase(),
        changeValuationPerVesting: changeValuationPerVesting === "Vesting Date",
      };
      downloadEsopAccounting(reqValues, {
        async onSuccess(data) {
          const base64File = data?.data?.base64File || "";
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = await convertBase64ToBlob(base64File, fileType);
          downloadBlobObject(blob, esopAccountingFileName);
          onClose();
        },
        onError(err: any) {
          const error = err.response.data as ErrorInterface;
          toast(error.errors, { type: "error", autoClose: 5000 });
          onClose();
        },
      });
    },
  });
  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Download ESOP-Accounting Report
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </h6>
      </div>
      <VStack className="w-full gap-4 p-4">
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Start date</Label>
            <Input type="date" {...formik.getFieldProps("startDate")} />
            {formik.touched.startDate && formik.errors.startDate && (
              <Error text={formik.errors.startDate} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">End Date</Label>
            <Input type="date" {...formik.getFieldProps("endDate")} />
            {formik.touched.endDate && formik.errors.endDate && (
              <Error text={formik.errors.endDate} />
            )}
          </div>
        </HStack>
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Accounting Standards</Label>
            <Select
              options={["IND-AS", "IGAAP"]}
              value={accountingStandard}
              onChange={(e) => {
                setAccountingStandard(e.target.value);
                if (e.target.value === "IND-AS") {
                  setAccountingMethod("Graded");
                  setMethods(["Graded"]);
                } else {
                  setMethods(["Graded", "Straight"]);
                }
              }}
            ></Select>
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Accounting Method</Label>
            <Select
              options={methods}
              value={accountingMethod}
              onChange={(e) => {
                setAccountingMethod(e.target.value);
              }}
            ></Select>
          </div>
        </HStack>
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Type</Label>
            <Select
              options={["FMV", "Intrinsic"]}
              value={valuationType}
              onChange={(e) => {
                setValuationType(e.target.value);
                if (e.target.value === "Intrinsic") {
                  formik.setFieldValue("attritionValue", 0);
                }
              }}
            ></Select>
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Attrition Value(%)</Label>
            <Input
              type="number"
              disabled={valuationType === "Intrinsic"}
              {...formik.getFieldProps("attritionValue")}
            />
          </div>
        </HStack>
        <HStack className="gap-6">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Type</Label>
            <Select
              options={["ALL", "ESOP", "SAR", "Trust"]}
              value={planType}
              onChange={(e) => {
                setPlanType(e.target.value);
              }}
            ></Select>
          </div>
          <div className="flex-1">
            <div className="hidden">
              <Label className="text-sm font-normal">
                Change Valuation Based On
              </Label>
              <Select
                options={["Grant Date", "Vesting Date"]}
                value={changeValuationPerVesting}
                onChange={(e) => {
                  setChangeValuationPerVesting(e.target.value);
                }}
              ></Select>
            </div>
          </div>
        </HStack>
        <HStack className="justify-between px-4 py-6 mt-7">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Generate
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};
export default EsopAccountingDialog;
