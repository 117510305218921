import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AlertDialog from "../../components/shared/AlertDialog";
import { ExpandButton } from "../../components/shared/ExpandButton";
import { Label, TextArea } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useChangeOptionStates,
  useCreateESignRequest,
  useEsopPlans,
  useGrant,
  useSignAndApproveGrant,
} from "../../queries";
import { useCompanyStore } from "../../store";
import { useError } from "../../store/errorStore";
import {
  useGrantCreatedDialog,
  useSignaturePadDialog,
} from "../../store/useDialogStore";
import { PlanState } from "../../types/EsopPlan";
import { SignatureAttachment } from "../../types/Grant";
import { ErrorInterface } from "../../types/OptionBuyback";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import {
  capitalizeEachWord,
  capitalizeFirstLetter,
  getFormattedValue,
  stringFormat,
} from "../../utils/string";
import StatusLabel from "./StatusLabel";
import { useGetEmailSettings } from "../../queries/siteSettings";

export function GrantCreated() {
  const currency = getCurrencyType();
  const { companyData } = useCompanyStore();
  const { state: grantCreatedDialog, setState: setGrantCreatedDialog } =
    useGrantCreatedDialog();
  const [showDialog, setShowDialog] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [lapseWithdrawed, setLapseWithdrawed] = useState(false);
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  const { grant, isFetching } = useGrant(grantCreatedDialog.grantId ?? "");
  const { data: _plans } = useEsopPlans();
  const errorMessage = useError();
  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (isFetching) return;
    else {
      const plan = (_plans ?? []).filter(
        (plan) => plan.esopPlanId === grant?.planid
      )[0];
      if (plan?.esopPlanState === PlanState.AMENDMENT) {
        setShowDialog(true);
        errorMessage.setMessage(
          "Plan needs to be in active state to update the Grant State"
        );
      }
    }
  }, [grant, isFetching]);
  const { mutate: changeOptionStates, isLoading } = useChangeOptionStates();
  const { mutate: attachSignature, isLoading: signatureBeingAttached } =
    useSignAndApproveGrant();
  const { mutate: createEsignRequest, isLoading: eSignSuccess } =
    useCreateESignRequest();
  const { data: emailSettings, isFetched } = useGetEmailSettings();
  const availableState = grant?.availableStates ?? [];
  const isCustomLetter = grant?.customizedGrantLetter ?? false;
  const [expanded, setExpanded] = useState(true);
  const [emailCheck, setEmailCheck] = useState(false);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (isFetching) return;
    else if (isFetched && grant) {
      if (grant.optionHolderState === "APPROVED") {
        setEmailCheck(
          emailSettings?.sendGrantOfferedEmailsToEmployees || false
        );
      } else if (grant.optionHolderState === "GRANTED") {
        setEmailCheck(
          emailSettings?.sendGrantWithdrawEmailsToEmployees || false
        );
      }
    }
  }, [grant, isFetched, isFetching]);

  function handleClick() {
    if (!grant?.optionHolderId) return;
    changeOptionStates(
      {
        optionHolderIds: [grant.optionHolderId.toString()],
        optionHolderState: "FOR_APPROVAL",
        note: notes,
        mailToBeSent: emailCheck,
        lapseWithdrawed: false,
      },
      {
        onSuccess: () => {
          toast("State Changed Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          setGrantCreatedDialog({ open: false });
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          setGrantCreatedDialog({ open: false });
        },
      }
    );
  }
  useEffect(() => {
    if (showSignaturePad.signatureType === "regular" && !isCustomLetter) {
      handleSignaturePad(showSignaturePad.signature ?? "");
    }
    if (showSignaturePad.signatureType === "esign") {
      createDigiESignRequest();
    }
  }, [showSignaturePad]);
  function createDigiESignRequest() {
    const eSignRequestObject = {
      entityIds: [grant?.optionHolderId.toString() ?? ""],
      type: "grant",
    };
    setShowSignaturePad({ open: false, isLoading: !eSignSuccess });
    createEsignRequest(eSignRequestObject, {
      onSuccess: (data) => {
        setShowSignaturePad({ open: false, isLoading: eSignSuccess });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(`${error.errors}`, { autoClose: 2000, type: "error" });
        setShowSignaturePad({ open: false, isLoading: eSignSuccess });
      },
    });
  }
  const handleSignaturePad = (signature: string) => {
    if (signature.includes("data:image/png;base64,")) {
      signature = signature.split(",")[1];
    }
    const signatureAttachmentObj: SignatureAttachment = {
      signature,
      entityIds: [grant?.optionHolderId.toString() ?? ""],
      origin: "grant",
    };
    setShowSignaturePad({ open: true, isLoading: !signatureBeingAttached });
    attachSignature(signatureAttachmentObj, {
      onSuccess: () => {
        toast("State Changed Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        setShowSignaturePad({ open: false, isLoading: signatureBeingAttached });
        setGrantCreatedDialog({ open: false });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(`${error.errors}`, { autoClose: 2000, type: "error" });
        setShowSignaturePad({ open: false, isLoading: signatureBeingAttached });
      },
    });
  };
  const [noteError, setNoteError] = useState("");
  const [currentLoadingState, setCurrentLoadingState] = useState<string | null>(
    null
  );

  const requestApproverSignature =
    companyData?.companySettings?.grantLetterSettings?.requestApproverSignature;
  function updateGrantStatus(state: string) {
    setCurrentLoadingState(state);

    if (!grant?.optionHolderId || !state) return;
    if (requestApproverSignature && state === "APPROVED" && !isCustomLetter) {
      toast("Please Sign In order to approve the grant", {
        type: "info",
        autoClose: 2000,
      });
      setShowSignaturePad({
        open: true,
        entityType: "grant",
        ids: [grant.optionHolderId],
      });
      return;
    }
    if (state === "WITHDRAW" && !showMessage) {
      setShowMessage(true);
      return;
    }
    if (notes === "" && grant?.optionHolderState === "GRANTED") {
      setNoteError("Please Enter Note");
      return;
    }
    changeOptionStates(
      {
        optionHolderIds: [grant.optionHolderId.toString()],
        optionHolderState: state,
        note: notes,
        mailToBeSent: emailCheck,
        lapseWithdrawed,
      },
      {
        onSuccess: () => {
          toast("State Changed Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          setGrantCreatedDialog({ open: false });
        },
        onError: (err: any) => {
          const error = err.response.data as ErrorInterface;
          errorMessage.setMessage(error.errorMessage);
          toast(error.errorMessage, { type: "error", autoClose: 2000 });
          setGrantCreatedDialog({ open: false });
        },
      }
    );
  }
  return (
    <>
      <div className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {grantCreatedDialog.variant === "state change"
            ? "Update Grant Status"
            : "Grant added successfully"}
          <button
            className="cursor-pointer"
            onClick={() => setGrantCreatedDialog({ open: false })}
          >
            X
          </button>
        </h6>
      </div>
      <VStack className="justify-between px-10 grow">
        <HStack
          aria-label="section"
          className="py-4 mb-10 border-b border-dashed "
        >
          <Box className="py-2 pr-4">
            <ExpandButton
              expanded={expanded}
              onClick={() => setExpanded((state) => !state)}
            ></ExpandButton>
          </Box>
          <VStack className="flex-1">
            <Box
              aria-label="section header"
              className="flex flex-row items-center gap-4 mb-2 "
            >
              <Box>
                <h5 className="text-sm font-medium text-[#030303]">
                  Grant Details
                </h5>
                <h6 className="text-sm font-normal text-gray-light">
                  {grant?.planName}
                </h6>
              </Box>
              <StatusLabel state={grant?.optionHolderState ?? ""} />
            </Box>
            {expanded && (
              <HStack aria-label="section body" className="flex-wrap grow">
                {[
                  {
                    field: "Name of the Holder",
                    value: grant?.optionHolderName,
                  },
                  {
                    field: "Granted",
                    value: getFormattedValue(
                      grant?.optionsGranted || 0,
                      currency,
                      grant?.isFractional
                    ),
                  },
                  {
                    field: "Grant Price",
                    value: formatCurrency(grant?.grantPrice ?? 0, currency),
                  },
                  {
                    field: "Grant Date",
                    value:
                      grant?.grantDate &&
                      formatDisplayDate(
                        new Date(grant.grantDate)?.toUTCString()
                      ),
                  },
                  {
                    field: "Vested",
                    value: getFormattedValue(
                      grant?.optionsVested || 0,
                      currency,
                      grant?.isFractional
                    ),
                  },
                  {
                    field: "Grant ID",
                    value: grant?.grantIdentifier,
                  },
                  {
                    field: "Vested Schedule",
                    value: grant?.vestingTemplateName,
                  },
                  {
                    field: "Plan Name",
                    value: grant?.planName,
                  },
                  {
                    field: "Vesting Date Type",
                    value: capitalizeEachWord(
                      stringFormat(grant?.vestingDateType || "")
                    ),
                  },
                  {
                    field: "Vesting Start Date",
                    value:
                      grant?.vestingDate &&
                      formatDisplayDate(
                        new Date(grant.vestingDate).toUTCString()
                      ),
                  },
                ].map(({ field, value }) => (
                  <HStack key={field} className="w-1/2 p-1 ">
                    <span className="w-1/2 text-sm font-normal text-gray-400">
                      {field}
                    </span>
                    <span className="w-1/2 text-xs3 text-[#030303] font-medium">
                      {value?.toString()}
                    </span>
                  </HStack>
                ))}
              </HStack>
            )}
          </VStack>
        </HStack>
        {grantCreatedDialog.variant === "state change" && (
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">Notes</Label>
              <TextArea
                value={notes}
                disabled={
                  grant?.optionHolderState !== "GRANTED" &&
                  grant?.optionHolderState !== "OFFERED"
                }
                className={`${
                  grant?.optionHolderState !== "GRANTED" &&
                  grant?.optionHolderState !== "OFFERED"
                    ? "cursor-not-allowed"
                    : ""
                }`}
                onChange={(e) => {
                  setNotes(e.target.value);
                  if (notes) setNoteError("");
                }}
              />
              {noteError !== "" ? <Error text={noteError} /> : ""}
            </div>

            <HStack className="gap-8 px-10 text-sm font-normal">
              {(grant?.optionHolderState === "APPROVED" ||
                grant?.optionHolderState === "GRANTED") && (
                <SwitchButton
                  value={emailCheck}
                  label="Send Email to Employee"
                  className={`${
                    grant?.optionHolderState !== "APPROVED" &&
                    grant?.optionHolderState !== "GRANTED"
                      ? "text-gray-400 cursor-not-allowed"
                      : ""
                  } `}
                  onClick={(e) => {
                    if (
                      grant?.optionHolderState === "APPROVED" ||
                      grant?.optionHolderState === "GRANTED"
                    )
                      setEmailCheck(!emailCheck);
                  }}
                />
              )}
            </HStack>
          </HStack>
        )}
      </VStack>
      {grantCreatedDialog.variant === "state change" ? (
        <>
          {showMessage ? (
            <VStack>
              <HStack className="gap-8 px-10 mt-6 text-sm font-normal">
                <SwitchButton
                  value={lapseWithdrawed}
                  label="Lapsed Withdrawed options?"
                  onClick={() => {
                    setLapseWithdrawed(!lapseWithdrawed);
                  }}
                />
              </HStack>
              <HStack className="justify-between p-8 justify">
                <div className="flex items-start">
                  <ButtonPrimary1
                    onClick={() => setGrantCreatedDialog({ open: false })}
                  >
                    Back
                  </ButtonPrimary1>
                </div>
                <div className="flex items-center space-x-4">
                  <ButtonPrimary
                    key={"WITHDRAW"}
                    onClick={(e) => updateGrantStatus("WITHDRAW")}
                    className={``}
                    loading={isLoading}
                  >
                    WITHDRAW
                  </ButtonPrimary>
                </div>
              </HStack>
            </VStack>
          ) : (
            <HStack className="justify-between p-8 justify">
              <div className="flex items-start">
                <ButtonPrimary1
                  onClick={() => setGrantCreatedDialog({ open: false })}
                >
                  Back
                </ButtonPrimary1>
              </div>
              <div className="flex items-center space-x-4">
                {availableState.map((state) => (
                  <ButtonPrimary
                    key={state}
                    onClick={() => updateGrantStatus(state)}
                    className={``}
                    loading={currentLoadingState === state}
                  >
                    {stringFormat(state)}
                  </ButtonPrimary>
                ))}
              </div>
            </HStack>
          )}
        </>
      ) : (
        <HStack className="justify-between p-8 justify">
          <div className="flex items-start">
            <ButtonPrimary1
              onClick={() => setGrantCreatedDialog({ open: false })}
            >
              Close
            </ButtonPrimary1>
          </div>
          <div className="flex items-center">
            <ButtonPrimary onClick={() => handleClick()}>
              Send For Approval
            </ButtonPrimary>
          </div>
        </HStack>
      )}
      <AlertDialog
        open={showDialog}
        error
        message={errorMessage.message}
        primaryActionText="Retry"
        secondaryActionText="Go Back"
        onPrimaryAction={() => {
          errorMessage.reset();
          setGrantCreatedDialog({ open: false });
          setShowDialog(false);
        }}
        onSecondaryAction={() => {
          errorMessage.reset();
          setGrantCreatedDialog({ open: false });
          setShowDialog(false);
        }}
        onClose={() => {
          setGrantCreatedDialog({ open: false });
          errorMessage.reset();
          setShowDialog(false);
        }}
      />
    </>
  );
}
