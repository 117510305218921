/* eslint-disable no-prototype-builtins */
/* eslint-disable etc/no-commented-out-code */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import { Icon } from "@iconify/react";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import _ from "lodash";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import {
  useEmployeeUploadViaExcel,
  useGetEmployeeUploadConfigs,
  useSaveEmployeeData,
} from "../../queries";
import { Select } from "../../components/shared/Select";
import Checkbox from "../../shared/checkbox";
import convertToBase64 from "../../utils/convertToBase64";
import {
  EmployeeUploadHeaderResponse,
  NewEmployeesToBeAdded,
  UploadedData,
} from "../../types/Employee";
import ExcelImport from "./ExcelImport";
import GreyTipImport from "./GreyTipImport";
import DarwinBoxDatasetImport from "./DarwinBoxDatasetImport";

interface EditEmployeeInterface extends NewEmployeesToBeAdded {
  [key: string]: any;
}
function EmployeeImport() {
  const [uploadedData, setUploadedData] = useState<UploadedData>({
    duplicateEmployees: [],
    newEmployeesToBeAdded: [],
    headersInFile: [],
    headersInSystem: [],
    validationErrors: [],
    cacheId: undefined,
  });
  useEffect(() => {
    const savedData = localStorage.getItem("dataToBeUploaded");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      if (parsedData?.validationErrors.length > 0) {
        setShowData(true);
      }
      setUploadedData(parsedData);
      setExcelConfig({
        ...excelCofig,
        rawHeaderData: {
          headersInFile: parsedData?.headersInFile || [],
          headersInSystem: parsedData?.headersInSystem || [],
        },
      });
    }
  }, []);
  const { data: _employeeUploadConfigs } = useGetEmployeeUploadConfigs();
  const [importMode, setImportMode] = useState(""); // Change it to Empty String later so that it can be used for other imports
  const [configValid, setConfigValid] = useState(false);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [newUploadConfig, setnewUploadConfig] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [excelCofig, setExcelConfig] = useState<{
    headerRow: number;
    file: string;
    data?: any;
    rawHeaderData?: EmployeeUploadHeaderResponse;
    useExitingTemplate?: boolean;
    exitingMapperConfigId?: string;
    validationErrors?: any;
    sampleData?: { [k: string]: any };
    mapperData?: {
      [k: string]: { type: string; field: string };
    };
    name?: string;
    cacheId?: string;
  }>({
    file: "",
    headerRow: 1,
  });
  const uploadConfig = useMemo(
    () => _employeeUploadConfigs,
    [_employeeUploadConfigs]
  );
  const [configMetaData, setConfigMetaData] = useState({
    configName: "",
    existingConfigChanged: false,
    existingConfigSelected: false,
  });
  const availablePlatForms = [
    {
      name: "GreyTip",
      logo: "/greytip.png",
      disabled: false,
    },
    {
      name: "Excel",
      logo: "/excel.avif",
      disabled: false,
    },
    {
      name: "Darwinbox",
      logo: "https://equion-dev.s3.us-west-2.amazonaws.com/assets/darwinbox.jpg",
      disabled: false,
    },
  ];
  function resetData() {
    setImportMode("");
    setConfigValid(false);
    setOperationInProgress(false);
    setExcelConfig({ file: "", headerRow: 1 });
    setUploadedData({
      duplicateEmployees: [],
      newEmployeesToBeAdded: [],
      headersInFile: [],
      headersInSystem: [],
      validationErrors: [],
      cacheId: undefined,
    });
    localStorage.removeItem("dataToBeUploaded");
    setnewUploadConfig(new Map<string, string>());
    setConfigMetaData({
      configName: "",
      existingConfigChanged: false,
      existingConfigSelected: false,
    });
  }
  const [type, setType] = useState("New Employees");
  const { mutate: saveEmployeeData, isLoading: isEmployeesSaved } =
    useSaveEmployeeData();
  function changeFieldValue(
    index: number,
    updatedValue: string | number | Date | undefined,
    fieldName: string
  ) {
    const editedNewEmployee: EditEmployeeInterface[] =
      uploadedData?.newEmployeesToBeAdded || [];
    editedNewEmployee?.forEach((employee, i) => {
      if (i === index && employee.hasOwnProperty(fieldName)) {
        employee[fieldName] = updatedValue;
      }
    });
    setUploadedData({
      ...uploadedData,
      newEmployeesToBeAdded: editedNewEmployee,
    });
  }
  // useEffect(() => {
  //   if (uploadedData.headersInFile.length > 0)
  //     localStorage.setItem("dataToBeUploaded", JSON.stringify(uploadedData));
  // }, [uploadedData]);
  const [showData, setShowData] = useState(false);
  return (
    <VStack className="justify-between w-full h-full px-4 bg-white border-2 rounded-md min-h-[300px]">
      <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
        <h6 className="flex-1">Import Employees</h6>
      </HStack>
      <HStack className="justify-around p-4 min-w-72">
        {importMode === "" &&
          availablePlatForms.map((platform) => (
            <HStack
              key={platform.name}
              className={`gap-4 ${
                platform.disabled ? "cursor-not-allowed" : ""
              }`}
            >
              <VStack className="justify-between w-full">
                <img
                  src={platform.logo}
                  alt={platform.name}
                  height={100}
                  width={100}
                  className="mb-4  aspect-auto object-center object-contain align-middle justify-center flex-1"
                />
                <div className="justify-center w-full text-center align-middle">
                  <h3 className="text-sm font-medium">
                    Import from {platform.name}
                  </h3>
                  <ButtonPrimary
                    className={`${
                      platform.disabled ? "cursor-not-allowed bg-green" : ""
                    }`}
                    disabled={platform.disabled}
                    onClick={() => setImportMode(platform.name)}
                    type="submit"
                  >
                    Import
                  </ButtonPrimary>
                </div>
              </VStack>
            </HStack>
          ))}
      </HStack>
      {importMode === "GreyTip" && <GreyTipImport resetData={resetData} />}
      {importMode === "Excel" && <ExcelImport resetData={resetData} />}
      {importMode === "Darwinbox" && (
        <DarwinBoxDatasetImport resetData={resetData} />
      )}
    </VStack>
  );
}

export default EmployeeImport;
