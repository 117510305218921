import { useMutation, useQuery } from "react-query";
import {
  accelerateForGrant,
  addEditPoolSize,
  addGrant,
  addGrantExcel,
  addPlan,
  bulkGenerateGrantLetters,
  changeOptionStates,
  changePlanState,
  createDigioSignRequest,
  createTrust,
  deleteGrant,
  deletePlan,
  deleteTrust,
  downloadTemplate,
  editGrant,
  editPlan,
  editTrust,
  getAllGrantActivity,
  getAllGrants,
  getAllPlanDocs,
  getCompanyHRMSConfgs,
  getCreatePlanDetails,
  getEsopAccounting,
  getEsopPlansSummary,
  getOwnersOfPlan,
  getPlanDetails,
  getPlanTemplates,
  getPoolDetails,
  getTransactionsCard,
  getTrustDetails,
  getVestingOverrides,
  importAll,
  savePlanOwner,
  scheduleHRMSSync,
  signAndApproveGrantLetter,
  updatePlanDocs,
  validateOnboardingExcel,
} from "../api/Esop";
import { exportExcel } from "../api/ExportExcel";
import { GenericAxiosResponse } from "../types/EsopPlan";
import { PlanExercise } from "../types/Exercise";
import { GrantResponse, ValidationObj } from "../types/Grant";
import { PlanVesting } from "../types/Vest";
import { queryClient } from "./client";
import { PendingRequest } from "./pendingRequests";
import { randomGrant, randomPlan } from "./placeholders";

export function useEsopPlans() {
  return useQuery({
    queryKey: "esopPlansSummary",
    queryFn: async () => {
      const data = await getEsopPlansSummary();
      return data.data;
    },
    placeholderData: Array(10)
      .fill(0)
      .map((_i) => randomPlan()),
  });
}
export function usePoolDetails() {
  return useQuery({
    queryKey: "getPoolDetails",
    queryFn: async () => {
      const data = await getPoolDetails();
      return data.data;
    },
  });
}

export function useTrustDetails() {
  return useQuery({
    queryKey: "getTrustDetails",
    queryFn: async () => {
      const data = await getTrustDetails();
      return data.data;
    },
  });
}

export function useCreateTrust() {
  return useMutation({
    mutationKey: "addTrust",
    mutationFn: createTrust,
    onSuccess: () => {
      queryClient.invalidateQueries("getTrustDetails");
      queryClient.refetchQueries("getTrustDetails");
      queryClient.invalidateQueries("createPlanDetails");
      queryClient.refetchQueries("createPlanDetails");
    },
  });
}

export function useEditTrust() {
  return useMutation({
    mutationKey: "editTrust",
    mutationFn: editTrust,
    onSuccess: () => {
      queryClient.invalidateQueries("getTrustDetails");
      queryClient.refetchQueries("getTrustDetails");
      queryClient.invalidateQueries("createPlanDetails");
      queryClient.refetchQueries("createPlanDetails");
    },
  });
}

export function useDeleteTrust() {
  return useMutation({
    mutationKey: "deleteTrust",
    mutationFn: deleteTrust,
    onSuccess: () => {
      queryClient.invalidateQueries("getTrustDetails");
      queryClient.refetchQueries("getTrustDetails");
      queryClient.invalidateQueries("createPlanDetails");
      queryClient.refetchQueries("createPlanDetails");
    },
  });
}

export function useAddPlan() {
  return useMutation({
    mutationKey: "addPlan",
    mutationFn: addPlan,
    onSuccess: (data) => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}

export function useGrants() {
  return useQuery({
    queryKey: "allGrants",
    staleTime: 9999,
    cacheTime: 9999,
    queryFn: async () => {
      const response = await getAllGrants();
      return response.data;
    },
    select: (grants) => {
      grants = grants.sort(
        (a, b) =>
          new Date(a.grantDate).getTime() - new Date(b.grantDate).getTime()
      );
      let cumulativeGrantedOptions = 0;

      const grantsWithCumulation = [];
      for (const grant of grants) {
        cumulativeGrantedOptions += grant.optionsGranted;
        const vestingExtended = {
          ...grant,
          cumulativeGrantedOptions,
        };
        grantsWithCumulation.push(vestingExtended);
      }
      return grantsWithCumulation;
    },
    placeholderData: Array(60)
      .fill({})
      .map((_i) => randomGrant()),
  });
}

export function useGrant(id: string) {
  const { data: _grants, ...rest } = useGrants();
  const grant = (_grants || []).find((grant) => grant.optionHolderId === id);
  return { grant, ...rest };
}

export function useAllVestings() {
  const { data: _grants } = useGrants();
  const grants = _grants || [];
  const vestings = grants
    .flatMap((g) => g.vestings)
    .sort(
      (a, b) =>
        new Date(a.projectedVestingDate).getTime() -
        new Date(b.projectedVestingDate).getTime()
    );
  let cumulativeActualVestedOptions = 0;
  let cumulativeProjectedVestedOptions = 0;
  const vestingsWithCumulation: PlanVesting[] = [];
  for (const vesting of vestings) {
    cumulativeActualVestedOptions += vesting.actualVestedOptions;
    cumulativeProjectedVestedOptions += vesting.projectedVestedOptions;
    const vestingExtended = {
      ...vesting,
      cumulativeActualVestedOptions,
      cumulativeProjectedVestedOptions,
    };
    vestingsWithCumulation.push(vestingExtended);
  }
  return vestingsWithCumulation;
}

export function useAllExercises() {
  const { data: _grants } = useGrants();
  const grants = _grants || [];
  const exercises = grants
    .flatMap((g) => g.exercises)
    .sort(
      (a, b) =>
        new Date(a.exerciseDate).getTime() - new Date(b.exerciseDate).getTime()
    );
  let cumulativeExercisedOptions = 0;
  const exerciseWithCumulation: PlanExercise[] = [];
  for (const exercise of exercises) {
    cumulativeExercisedOptions +=
      exercise.cashExercisedOptions + exercise.stockExercisedOptions;
    const exerciseExtended = {
      ...exercise,
      cumulativeExercisedOptions,
    };
    exerciseWithCumulation.push(exerciseExtended);
  }
  return exerciseWithCumulation;
}
export function useAddGrant() {
  return useMutation({
    mutationKey: "addGrant",
    mutationFn: addGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("vestingEvents");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function useEditGrant() {
  return useMutation({
    mutationKey: "editGrant",
    mutationFn: editGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("vestingEvents");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function useTransactionsCard() {
  return useQuery({
    queryKey: "transactionsCard",
    queryFn: async () => {
      const response = await getTransactionsCard();
      return response.data;
    },
  });
}

export function useAddGrantExcel() {
  return useMutation({
    mutationKey: "addGrantExcel",
    mutationFn: addGrantExcel,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("vestingEvents");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: (error: { response: { data: { data: GrantResponse } } }) => {},
  });
}

export function useGetEsopPlanDetails(id: string, enabled: boolean) {
  return useQuery({
    queryKey: ["getPlanDetails", id],
    queryFn: async () => (await getPlanDetails(id)).data,
    onSuccess: () => {
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.refetchQueries("esopPlansSummary");
    },
    enabled,
  });
}

export function useChangeOptionStates() {
  return useMutation({
    mutationKey: "changeOptionState",
    mutationFn: changeOptionStates,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}
export function useEditPlan() {
  return useMutation({
    mutationKey: "editPlan",
    mutationFn: editPlan,
    onSuccess: (data) => {
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("createPlanDetails");
      queryClient.refetchQueries("createPlanDetails");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.invalidateQueries("documents");
      queryClient.refetchQueries("documents");
    },
  });
}

export function usePlanStateChange() {
  return useMutation({
    mutationKey: "changePlanState",
    mutationFn: changePlanState,
    onSuccess: () => {
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.invalidateQueries("createPlanDetails");
      queryClient.refetchQueries("createPlanDetails");
      queryClient.invalidateQueries("documents");
      queryClient.refetchQueries("documents");
    },
    onError: () => {},
  });
}

export function useAllGrantActivity() {
  return useQuery({
    queryKey: "allGrantActivity",
    queryFn: async () => {
      const data = await getAllGrantActivity();
      return data.data;
    },
  });
}

export function useRecentDrafts() {
  const { data: grantActivities, ...rest } = useAllGrantActivity();
  const createdStateGrants: PendingRequest[] = (grantActivities || [])
    .filter((grantActivity) => grantActivity.optionState === "CREATED")
    .map((grantActivity) => ({
      id: grantActivity.optionHolderId,
      type: "Grant Created",
      subject: `${grantActivity.optionHolderName} - New Grant`,
      requestDate: new Date(grantActivity.dateOfTransaction),
    }));
  createdStateGrants.sort(
    (a, b) =>
      new Date(b.requestDate || new Date()).getTime() -
      new Date(a.requestDate || new Date()).getTime()
  );
  return {
    data: createdStateGrants,
    ...rest,
  };
}

export function useRecentGrantOffered() {
  const { data: grantActivities, ...rest } = useAllGrantActivity();
  const offeredStateGrants: PendingRequest[] = (grantActivities || [])
    .filter((grantActivity) => grantActivity.optionState === "OFFERED")
    .map((grantActivity) => ({
      id: grantActivity.optionHolderId,
      type: "Grant Offered",
      subject: `${grantActivity.optionHolderName} - Yet to accept offer`,
      requestDate: new Date(grantActivity.dateOfTransaction),
    }));
  offeredStateGrants.sort(
    (a, b) =>
      new Date(b.requestDate || new Date()).getTime() -
      new Date(a.requestDate || new Date()).getTime()
  );
  return {
    data: offeredStateGrants,
    ...rest,
  };
}

export function useRecentForApprovals() {
  const { data: grantActivities, ...rest } = useAllGrantActivity();
  const forApprovalStateGrants: PendingRequest[] = (grantActivities || [])
    .filter((grantActivity) => grantActivity.optionState === "FOR_APPROVAL")
    .map((grantActivity) => ({
      id: grantActivity.optionHolderId,
      type: "Grant For Approval",
      subject: `${grantActivity.optionHolderName} - For Approval`,
      requestDate: new Date(grantActivity.dateOfTransaction),
    }));
  forApprovalStateGrants.sort(
    (a, b) =>
      new Date(b.requestDate || new Date()).getTime() -
      new Date(a.requestDate || new Date()).getTime()
  );
  return {
    data: forApprovalStateGrants,
    ...rest,
  };
}

export function useDeleteGrant() {
  return useMutation({
    mutationKey: "deleteGrant",
    mutationFn: deleteGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.invalidateQueries("allExercises");
      queryClient.invalidateQueries("allVestings");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("allExercises");
      queryClient.refetchQueries("allVestings");
    },
    onError: () => {},
  });
}

export function useDeletePlan() {
  return useMutation({
    mutationKey: "deletePlan",
    mutationFn: deletePlan,
    onSuccess: (data) => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: (error: GenericAxiosResponse) => {},
  });
}

export function useCreatePlanDetails() {
  return useQuery({
    queryKey: "createPlanDetails",
    queryFn: async () => {
      const data = await getCreatePlanDetails();
      return data.data;
    },
  });
}

export function useOwnersOfPlan(planId: string) {
  return useQuery({
    queryKey: ["getOwnersOfPlan", planId],
    queryFn: getOwnersOfPlan,
  });
}

export function useEditAddPoolSize() {
  return useMutation({
    mutationKey: "AddEditPoolSize",
    mutationFn: addEditPoolSize,
    onSuccess: () => {
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.invalidateQueries("getPoolDetails");
      queryClient.refetchQueries("getPoolDetails");
    },
    onError: () => {},
  });
}

export function useExportToExcel() {
  return useMutation({
    mutationKey: "excelExport",
    mutationFn: exportExcel,
  });
}

export function useDownloadEsopAccounting() {
  return useMutation({
    mutationKey: "downloadEsopAccounting",
    mutationFn: getEsopAccounting,
  });
}

export function useOwnerShip() {
  return useMutation({
    mutationKey: "ownerShip",
    mutationFn: savePlanOwner,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.invalidateQueries("getOwnersOfPlan");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("getOwnersOfPlan");
    },
  });
}

export function useValidateExcel() {
  return useMutation({
    mutationKey: "validateOnboardingExcel",
    mutationFn: validateOnboardingExcel,
  });
}

export function useDownloadTemplate() {
  return useQuery({
    queryKey: "downloadTemplate",
    queryFn: downloadTemplate,
    enabled: false,
    select: (data) => data,
  });
}

export function useImportExcel() {
  return useMutation({
    mutationKey: "importAll",
    mutationFn: importAll,
  });
}

export function useGenerateGrantLettersBulk() {
  return useMutation({
    mutationKey: "bulkGenerateGrantLetters",
    mutationFn: bulkGenerateGrantLetters,
    onSuccess: () => {
      queryClient.invalidateQueries("documents");
      queryClient.refetchQueries("documents");
    },
  });
}

export function useGetAllPlanDocs(planId: string) {
  return useQuery({
    queryKey: ["getAllPlanDocs", planId],
    queryFn: async () => (await getAllPlanDocs(planId)).data,
  });
}

export function useUpdatePlanDocs() {
  return useMutation({
    mutationKey: ["updatePlan"],
    mutationFn: updatePlanDocs,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllPlanDocs");
      queryClient.refetchQueries("getAllPlanDocs");
      queryClient.invalidateQueries("documents");
      queryClient.refetchQueries("documents");
    },
  });
}

export function useSignAndApproveGrant() {
  return useMutation({
    mutationKey: ["signGrant"],
    mutationFn: signAndApproveGrantLetter,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("transactionsCard");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("transactionsCard");
    },
  });
}
export function useCreateESignRequest() {
  return useMutation({
    mutationFn: createDigioSignRequest,
    mutationKey: "createDigioSignRequest",
  });
}

export function useAccelerateForGrant() {
  return useMutation({
    mutationKey: "accelerateForGrant",
    mutationFn: accelerateForGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.refetchQueries("allGrants");
      queryClient.invalidateQueries("employeeVestingTableDetails");
      queryClient.refetchQueries("employeeVestingTableDetails");
    },
  });
}

export function useGetVestingOverrides() {
  return useQuery({
    queryKey: "vestingOverrides",
    queryFn: getVestingOverrides,
  });
}

export function usePlanTemplates() {
  return useQuery({
    queryKey: "planTemplates",
    queryFn: async () => {
      const response = await getPlanTemplates();
      return response.data;
    },
  });
}

export function useGetAllCompanyConfigs() {
  return useQuery({
    queryKey: "companyConfigs",
    queryFn: getCompanyHRMSConfgs,
  });
}

export function useScheduleSync() {
  return useMutation({
    mutationKey: "scheduleSync",
    mutationFn: scheduleHRMSSync,
    onSuccess: () => {
      queryClient.invalidateQueries("companyConfigs");
      queryClient.refetchQueries("companyConfigs");
    },
  });
}
