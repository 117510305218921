/*eslint arrow-body-style: [0]*/
import { Icon } from "@iconify/react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { format } from "date-fns";
import * as _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import { Box, HStack, VStack } from "../../components/utils";
import { useVestingTables } from "../../queries";
import { EmployementStatus } from "../../types/Employee";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import { getFormattedValue } from "../../utils/string";
import { EmployeeVestingDetail } from "../../types/VestingTemplate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VestingSchedule = () => {
  const { id } = useParams();
  const _id = id || "";
  const { data: _vestingSchedule, refetch } = useVestingTables(_id);
  useEffect(() => {
    if (!_.isEmpty(_id)) {
      refetch();
    }
  }, [_id]);
  const [showTable, setShowTable] = useState(false);

  const vestingScheduleTemplate = _vestingSchedule || [];
  const currency = getCurrencyType();
  const isFractional = vestingScheduleTemplate.some(
    (schedule) => schedule.isFractional
  );
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItems: TooltipItem<"bar">) => {
            const label = `${tooltipItems.dataset.label || ""}: ${
              isFractional
                ? (tooltipItems.raw as string)
                : tooltipItems.formattedValue
            }`;
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Vesting Projections",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
        },
        stacked: true,
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          font: { size: 12 },
          padding: 1,
        },
      },
      y: {
        title: {
          display: true,
          text: "Grants",
        },
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          font: { size: 12 },
          maxTicksLimit: 5,
        },
      },
    },
  };
  const data = useMemo(() => {
    return {
      labels: vestingScheduleTemplate?.map((vestSchedule) =>
        formatDisplayDate(new Date(vestSchedule.date))
      ),
      datasets: [
        {
          label: "Exercised",
          data: vestingScheduleTemplate.map((exercised) =>
            isFractional
              ? parseFloat(
                  exercised.accumulatedExercisedOptionsForEmployee.toFixed(4)
                )
              : exercised.accumulatedExercisedOptionsForEmployee
          ),
          backgroundColor: "rgb(80,205,137)",
        },
        {
          label: "Vested",
          data: vestingScheduleTemplate.map((vested) =>
            isFractional
              ? parseFloat(
                  vested.accumulatedVestedOptionsForEmployee.toFixed(4)
                )
              : vested.accumulatedVestedOptionsForEmployee
          ),
          backgroundColor: "rgb(248,177,149)",
        },
        {
          label: "Granted",
          data: vestingScheduleTemplate.map((granted) =>
            isFractional
              ? parseFloat(
                  granted.accumulatedGrantedOptionsForEmployee.toFixed(4)
                )
              : granted.accumulatedGrantedOptionsForEmployee
          ),
          backgroundColor: "rgb(246,114,128)",
        },
      ],
    };
  }, [vestingScheduleTemplate]);

  return (
    <Box className="p-4 bg-white ">
      <HStack className="justify-between">
        <VStack>
          <p className="font-semibold">Vesting Schedule</p>
        </VStack>

        {!showTable ? (
          <button
            onClick={() => {
              setShowTable(!showTable);
            }}
          >
            <Icon
              className="hover:text-orange-501"
              icon="mdi:table"
              height={25}
            />
          </button>
        ) : (
          <button
            onClick={() => {
              setShowTable(!showTable);
            }}
          >
            <Icon
              icon="mdi:graph-bar"
              className="hover:text-orange-501"
              height={25}
            />
          </button>
        )}
      </HStack>
      <Box className="mt-6 overflow-y-auto min-h-96">
        {showTable ? (
          <AGGridEmployeeScheduleTable
            currency={currency}
            isFractional={isFractional}
            data={vestingScheduleTemplate || []}
          />
        ) : (
          <Bar options={options} data={data} />
        )}
      </Box>
    </Box>
  );
};

function AGGridEmployeeScheduleTable(props: {
  data: EmployeeVestingDetail[];
  currency: string;
  isFractional: boolean;
}) {
  const { data, currency, isFractional } = props;
  const employeeVestingData = useMemo(() => {
    if (data) {
      data.forEach((item) => {
        item.stockExercised =
          item.exercisedOptions - item.exercisedOptionsWithCash;
      });
      return data;
    }
    return [];
  }, [data]);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "DATE",
        field: "date",
        sortable: true,
        autoHeight: true,
        wrapText: true,
        minWidth: 200,
        width: 200,
        filterValueGetter: (e) => {
          return new Date(e.getValue("date")).getDate();
        },
        filter: "agDateColumnFilter",
        suppressAutoSize: false,
        suppressSizeToFit: true,
        valueFormatter: (e) => formatDisplayDate(e.value),
        cellStyle: { "padding-top": "15px" },
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "GRANTED",
        field: "grantedOptions",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        filter: "agNumberColumnFilter",
        valueFormatter: (e) =>
          getFormattedValue(e.value, currency, isFractional),
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "VESTED",
        field: "vestedOptions",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        valueFormatter: (e) =>
          getFormattedValue(e.value, currency, isFractional),
        filter: "agMultiColumnFilter",
      },
      {
        headerName: "TOTAL VESTED (All Grants)",
        field: "accumulatedVestedOptionsForEmployee",
        autoHeight: true,
        wrapText: true,
        valueFormatter: (e) =>
          getFormattedValue(e.value, currency, isFractional),
        sortable: true,
      },
      {
        headerName: "STOCK EXERCISED",
        field: "stockExercised",
        autoHeight: true,
        wrapText: true,
        valueFormatter: (e) =>
          getFormattedValue(
            e.data.exercisedOptions - e.data.exercisedOptionsWithCash,
            currency,
            isFractional
          ),
        filter: "agNumberColumnFilter",
        menuTabs: ["filterMenuTab"],
        sortable: true,
      },
      {
        headerName: "CASHED OUT",
        field: "exercisedOptionsWithCash",
        autoHeight: true,
        wrapText: true,
        valueFormatter: (e) =>
          getFormattedValue(e.value, currency, isFractional),
        filter: "agNumberColumnFilter",
        menuTabs: ["filterMenuTab"],
        sortable: true,
      },
      {
        headerName: "GRANT ID",
        field: "identifier",
        autoHeight: true,
        wrapText: true,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        sortable: true,
      },
    ],
    []
  );
  return (
    <HStack className="justify-between w-full ">
      <Box
        style={{
          height: `${Math.min(
            400,
            (employeeVestingData.length >= 10
              ? 10
              : employeeVestingData.length + 3) * 60
          )}px`,
        }}
        className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
      >
        <AgGridReact
          rowData={employeeVestingData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
        />
      </Box>
    </HStack>
  );
}

export default VestingSchedule;
