import React from "react";
import { Box, HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import GenericTableHeader from "../../shared/GenericTableHeader";
import ReportsAgGridTable from "./ReportsAgGridTable";
import { CustomResponse, ReportDto } from "../../types/Grant";
import {
  downloadPoolStatement,
  generateAcceptanceReport,
  generateCancellationReport,
  generateEmployeeDetailsReport,
  generateExerciseReport,
  generateFinancialReport,
  generateGrantReport,
  generateLiquidityReport,
  generateTransactionReport,
  generateVestWiseReport,
} from "../../api/Esop";

export interface ReportNames {
  name: string;
  description: string;
  type: string;
  headerName: string;
  startDate: string;
  endDate: string;
  onSubmitFunction?: (param: any) => Promise<CustomResponse<ReportDto>>;
}

const reportNames: ReportNames[] = [
  {
    name: "Grant Report",
    description: "Provides details of all grants issued under the ESOP plan.",
    type: "Grant",
    headerName: "Download Grant Report",
    onSubmitFunction: generateGrantReport,
    startDate: "Grant Start Date",
    endDate: "Grant End Date",
  },
  {
    name: "Vest-wise Report",
    description: "Provides details of all grants issued under the ESOP plan.",
    type: "Vesting",
    headerName: "Download Vestwise Report",
    onSubmitFunction: generateVestWiseReport,
    startDate: "Grant Start Date",
    endDate: "Grant End Date",
  },
  {
    name: "Cancellation Report",
    description: "Tracks the cancellation of any ESOP grants or shares.",
    type: "Transaction",
    headerName: "Download Cancellation Report",
    onSubmitFunction: generateCancellationReport,
    startDate: "Transaction Start Date",
    endDate: "Transaction End Date",
  },
  {
    name: "Employee wise Report",
    description: "Offers a breakdown of ESOP details by employee.",
    type: "Employee",
    headerName: "Download Employee wise Report",
    onSubmitFunction: generateEmployeeDetailsReport,
    startDate: "Transaction Start Date",
    endDate: "Transaction End Date",
  },
  {
    name: "Exercise Report",
    description: "Summarizes the exercise of vested options by employees.",
    type: "Transaction",
    headerName: "Download Exercise Report",
    onSubmitFunction: generateExerciseReport,
    startDate: "Transaction Start Date",
    endDate: "Transaction End Date",
  },
  {
    name: "Liquidity Report",
    description: "Displays liquidity events and associated stock movements.",
    type: "Grant",
    headerName: "Download Liquidity Report",
    onSubmitFunction: generateLiquidityReport,
    startDate: "Event Start Date",
    endDate: "Event End Date",
  },
  {
    name: "BuyBack Report",
    description: "Details any buyback events of employee shares.",
    type: "Grant",
    headerName: "Download BuyBack Report",
    onSubmitFunction: generateLiquidityReport,
    startDate: "Event Start Date",
    endDate: "Event End Date",
  },
  {
    name: "Pool Statement",
    description: "Shows the available and used ESOP pool balance.",
    type: "Company",
    headerName: "Download Pool Statement",
    onSubmitFunction: downloadPoolStatement,
    startDate: "",
    endDate: "",
  },
  {
    name: "Acceptance Report",
    description: "Documents the acceptance of ESOP offers by employees.",
    type: "Grant",
    headerName: "Download Acceptance Report",
    onSubmitFunction: generateAcceptanceReport,
    startDate: "Grant Start Date",
    endDate: "Grant End Date",
  },
  {
    name: "Financial Report",
    description: "Provides financial implications and status of the ESOP plan.",
    type: "Grant",
    headerName: "Download Financial Report",
    onSubmitFunction: generateFinancialReport,
    startDate: "Grant Start Date",
    endDate: "Grant End Date",
  },
  {
    name: "SH-6 Report",
    description: "Regulatory report for managing ESOP compliance.",
    type: "Grant",
    headerName: "Download SH-6 Report",
    startDate: "",
    endDate: "",
  },
];

const Reports = () => (
  <VStack className="gap-4">
    <HStack className="justify-between min-h-[45px] items-center">
      <BCHeader className="items-baseline " bcTitle="Reports" bcSubTitle={""} />
    </HStack>
    <Box className="p-4 bg-white border rounded-lg min-w-min border-borderColor drop-shadow-box ">
      <HStack className="justify-between w-full ">
        <ReportsAgGridTable reportsData={reportNames} />
      </HStack>
    </Box>
  </VStack>
);

export default Reports;
