import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-community";
import { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Dialog } from "@mui/material";
import SearchInput from "../../../components/shared/SearchInput";
import { Box, ButtonPrimary, HStack, VStack } from "../../../components/utils";
import { KeyPersonnelType } from "../../../types/modelling";
import { Action, CTADropdown } from "../../../components/shared/Dropdown";
import { getSingleSalaryRangeForUI } from "../modellingUtils";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import { RandomDepartmentColorGenerator } from "../companyDetails/utils/RandomDepartmentColorGenerator";
import AddKeyPersonnelCompModellingDialog from "./dialogs/AddKeyPersonnelCompModellingDialog";

export default function KeyPersonnelCompModelling() {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  };
  const {
    compensationModellingData,
    addorDeleteKeyPersonnelToCompensationModelling,
  } = useOrgTemplateStore();
  const [KeyPersonnel, setKeyPersonnel] = useState<Array<KeyPersonnelType>>([]);
  const rowData = useMemo(() => {
    if (!KeyPersonnel) return [];
    return KeyPersonnel.map((key) => ({
      ...key,
      actions: (
        <CTADropdown
          dropdownClassName=" xl:right-10 lg:right-8 md:right-2"
          actions={[
            {
              name: "Remove Key Personnel",
            },
          ]}
          onAction={(action) => {
            handleAction(key, action);
          }}
        />
      ),
    }));
  }, [KeyPersonnel]);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      cellStyle: {
        lineHeight: "1.5",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  let index = 0;
  let prevDept = "";
  const columnDefs: ColDef[] = [
    {
      headerName: "DEPARTMENT",
      autoHeight: true,
      field: "department",
      width: 150,
      flex: 1,
      filter: "agSetColumnFilter",
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellStyle: (params: any) => {
        const existingDept = params.value;
        if (existingDept === prevDept) index -= 1;
        const color = RandomDepartmentColorGenerator(index, existingDept);
        index = index > 12 ? 0 : index + 1;
        prevDept = existingDept;
        return {
          color: "#ffffff",
          backgroundColor: color,
          display: "flex",
          "align-items": "center",
          lineHeight: "1.5",
          "justify-content": "center",
        };
      },
    },
    {
      headerName: "NAME",
      autoHeight: true,
      field: "name",
      flex: 1,
      filter: "agSetColumnFilter",
      width: 100,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "EMP ID",
      autoHeight: true,
      field: "employeeId",
      flex: 1,
      filter: "agSetColumnFilter",
      width: 80,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "ROLE",
      autoHeight: true,
      field: "role",
      flex: 1,
      filter: "agSetColumnFilter",
      width: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Salary",
      autoHeight: true,
      field: "salary",
      filter: "agSetColumnFilter",
      width: 80,
      flex: 1,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      valueFormatter: (params) =>
        params.data
          ? getSingleSalaryRangeForUI(
              params.data?.salary,
              currencySymbol,
              currencyType
            )
          : getSingleSalaryRangeForUI(
              params.value,
              currencySymbol,
              currencyType
            ),
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: CTADropdown,
    },
  ];

  useEffect(() => {
    if (
      compensationModellingData?.data &&
      compensationModellingData.data.departments
    ) {
      const data: KeyPersonnelType[] = [];
      Object.entries(compensationModellingData.data.departments).forEach(
        ([departmentName, depData]) => {
          Object.entries(depData.levels).forEach(([levelName, levData]) => {
            Object.entries(levData.roles).forEach(([roleName, roleData]) => {
              if (roleData.employees) {
                Object.entries(roleData.employees).forEach(
                  ([employeeId, empData]) => {
                    if (empData.keyPersonnel) {
                      data.push({
                        department: departmentName,
                        level: levelName,
                        role: roleName,
                        salary: empData.salary ?? 0,
                        employeeId,
                        name: empData.employeeData.employeeName,
                      });
                    }
                  }
                );
              }
            });
          });
        }
      );
      setKeyPersonnel(data);
    }
  }, [compensationModellingData]);
  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });

  function handleAction(employee: KeyPersonnelType, action: Action) {
    if (action.name === "Remove Key Personnel") {
      addorDeleteKeyPersonnelToCompensationModelling(
        employee.department,
        employee.level,
        employee.role,
        employee.employeeId,
        false,
        0
      );
    }
  }
  return (
    <VStack className="w-full">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Assign Key Personnel
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Add details of key employees in every department in the organisation.
        </HStack>
      </VStack>
      <HStack className="justify-end gap-8 pt-16 pr-5">
        <div className="flex gap-6 text-xs text-center justify-evenly">
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
          <ButtonPrimary
            onClick={() => {
              setDialog({ open: !dialog.open });
            }}
          >
            Add
          </ButtonPrimary>
        </div>
      </HStack>
      <Dialog
        maxWidth="xl"
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
      >
        <div className="w-[700px] h-[320px] min-h-min mx-auto bg-white rounded-lg">
          <AddKeyPersonnelCompModellingDialog
            open={dialog.open}
            onClose={() => setDialog({ open: false })}
          />
        </div>
      </Dialog>
      <HStack className="justify-between w-full pt-6 pb-6 pl-8">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            suppressRowTransform={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}
