import { AgGridReact } from "ag-grid-react";
import { ColDef, ColGroupDef, GridApi } from "ag-grid-community";
import React, { useMemo, useRef, useState, useEffect } from "react";
import _ from "lodash";
import { Icon } from "@iconify/react";
import SearchInput from "../../../components/shared/SearchInput";
import { Box, HStack, VStack } from "../../../components/utils";
import {
  CompensationSummary,
  CompensationSummaryWithEmp,
} from "../../../types/modelling";
import {
  getSalaryRangeForUI,
  getSingleSalaryRangeForUI,
} from "../modellingUtils";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import {
  SalaryRange,
  YearlyData,
} from "../companyDetails/utils/OrgStructureJSONDataProc";
import { formatDisplayDate } from "../../../utils/date";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { RandomDepartmentColorGenerator } from "../companyDetails/utils/RandomDepartmentColorGenerator";
import Avatar from "../../esopOverview/Avatar";
import { EmployementStatus } from "../../../types/Employee";

export default function CompModellingSummaryWithEmp() {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  };
  let index = 0;
  const { compensationModellingData } = useOrgTemplateStore();
  const [summaryData, setSummaryData] = useState<
    Array<CompensationSummaryWithEmp>
  >([]);
  const [totalOptions, setTotalOptions] = useState<number>();
  const [totalSalary, setTotalSalary] = useState<number>();

  const getSalary = (
    percentage: number,
    yoyGrowth: number,
    pps: number,
    salary: number,
    yearData?: YearlyData
  ) => {
    let totalPps = pps;
    if (yearData) {
      let totalSalary = 0;
      let totalOptions = 0;
      for (const year of Object.values(yearData)) {
        const currentSalary = (percentage * salary * year) / 100;
        totalPps += (totalPps * yoyGrowth) / 100;
        totalSalary += currentSalary;
        totalOptions += Math.floor(currentSalary / totalPps);
      }
      return { salary: totalSalary, options: totalOptions };
    }
    return {
      salary: (percentage * salary) / 100,
      options: (percentage * salary) / 100 / pps,
    };
  };
  const rowData = useMemo(
    () =>
      summaryData.map((summary) => ({
        department: summary.department,
        employeeName: (
          <EmployeeInfoRender
            name={summary.name}
            keyPersonnel={summary.keyPersonnel}
            level={summary.level}
          />
        ),
        role: (
          <KeyRoleCellRenderer
            keyRole={summary.role.keyRole}
            level={summary.role.level}
            role={summary.role.role}
          />
        ),
        options: (
          <OptionsCellRenderer
            salaryRange={Number(summary.options.salaryRange)}
            options={Number(summary.options.optionsRange)}
            percentage={summary.options.percentage}
          />
        ),
        salary: summary.salary,
      })),
    [summaryData]
  );

  useEffect(() => {
    if (
      compensationModellingData &&
      compensationModellingData.data &&
      compensationModellingData.data.departments &&
      compensationModellingData.data.employeeListUploaded
    ) {
      const data: CompensationSummaryWithEmp[] = [];
      let totalSalary = 0;
      let totalOptions = 0;
      Object.entries(compensationModellingData.data.departments).forEach(
        ([departmentName, depData]) => {
          Object.entries(depData.levels).forEach(([levelName, levelData]) => {
            if (
              levelData.futureHires &&
              (levelData.futureHires.employeeCount ?? 0) > 0
            ) {
              const salaryData = getSalary(
                levelData.percentageOfSalary || 0,
                compensationModellingData.data?.yoyGrowth || 0,
                compensationModellingData.data?.ppsOfCompany || 1,
                Number(levelData.futureHires.salary) || 1,
                levelData.yearlyData
              );
              const futureHires = {
                department: departmentName,
                keyPersonnel: false,
                level: levelName,
                name: "Future Hires",
                salary: Number(levelData.futureHires.salary) || 0,
                options: {
                  salaryRange: salaryData.salary,
                  optionsRange: salaryData.options,
                  employeeCount:
                    levelData.totalEmployeeCount -
                    levelData.currentEmployeeCount,
                  percentage: levelData.percentageOfSalary,
                },
                role: {
                  role: "",
                  keyRole: false,
                  level: "",
                },
              };
              totalSalary += futureHires.options.salaryRange;
              totalOptions += futureHires.options.optionsRange;
              data.push(futureHires);
            }
            Object.entries(levelData.roles).forEach(([roleName, roleData]) => {
              if (roleData.employees) {
                Object.entries(roleData.employees).forEach(
                  ([empId, empData]) => {
                    const salaryData = getSalary(
                      empData.percentageOfSalary || 0,
                      compensationModellingData.data?.yoyGrowth || 0,
                      compensationModellingData.data?.ppsOfCompany || 1,
                      empData.salary
                    );
                    const returnData = {
                      department: departmentName,
                      keyPersonnel: empData.keyPersonnel,
                      level: levelName,
                      name: empData.employeeData.employeeName,
                      salary: Number(empData.employeeData.salary) || 0,
                      options: {
                        salaryRange: salaryData.salary,
                        optionsRange: salaryData.options,
                        employeeCount: 1,
                        percentage: empData.percentageOfSalary,
                      },
                      role: {
                        role: roleName,
                        keyRole: roleData.keyRole,
                        level: levelName,
                      },
                    };
                    totalSalary += returnData.options.salaryRange;
                    totalOptions += returnData.options.optionsRange;
                    data.push(returnData);
                  }
                );
              }
            });
          });
        }
      );
      setTotalOptions(totalOptions);
      setSummaryData(data);
      setTotalSalary(totalSalary);
    }
  }, [compensationModellingData]);

  function KeyRoleCellRenderer(params: {
    role: string;
    keyRole: boolean;
    level: string;
  }) {
    if (params.keyRole) {
      return (
        <HStack className="items-center gap-2 text-sm">
          <VStack className="gap-2">
            {params.role}
            <span className="text-xs text-gray-400">{params.level}</span>
          </VStack>
          <Icon
            icon="mdi:person-star-outline"
            height={"15px"}
            style={{ color: "#bd00ff" }}
          />{" "}
        </HStack>
      );
    } else {
      return (
        <VStack className="gap-2 text-sm">
          <span>{params.role}</span>
          <span className="text-xs text-gray-400">{params.level}</span>
        </VStack>
      );
    }
  }

  function OptionsCellRenderer(props: {
    salaryRange: number;
    options: number;
    percentage: number;
  }) {
    const options =
      Number.isNaN(props.options) ||
      props.options === undefined ||
      props.options === null
        ? 0
        : props.options;

    const salary =
      Number.isNaN(props.salaryRange) ||
      props.options === undefined ||
      props.options === null
        ? 0
        : props.salaryRange;

    return (
      <>
        <VStack className="gap-2 text-sm">
          <HStack>
            {getSingleSalaryRangeForUI(salary, currencySymbol, currencyType)}
          </HStack>
          <HStack className="text-xs text-gray-400">{`${options.toLocaleString(
            currencyType
          )} | ${props.percentage}%`}</HStack>
        </VStack>
      </>
    );
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      cellStyle: {
        lineHeight: "1.5",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      filter: true,
      resizable: true,
    }),
    []
  );
  function EmployeeInfoRender(emp: {
    name: string;
    keyPersonnel: boolean;
    level: string;
  }) {
    return (
      <HStack className="items-center justify-between pt-2">
        <Avatar name={emp.name} status={"EMPLOYED"} />
        <Box className="ml-2 text-center">
          <p className="text-xs font-medium text-gray-dark">{emp.name}</p>
        </Box>
        <span className="pl-2">
          {emp.keyPersonnel && (
            <img
              src="../assets/KeyPersonnel.svg"
              alt="llll"
              height={"20px"}
              width={"20px"}
            />
          )}
        </span>
      </HStack>
    );
  }
  let prevDept = "";
  const columnDefs: ColDef[] = [
    {
      headerName: "DEPARTMENT",
      autoHeight: true,
      field: "department",
      width: 150,
      flex: 1,
      filter: "agSetColumnFilter",
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellStyle: (params: any) => {
        const existingDept = params.value;
        if (existingDept === prevDept) index -= 1;
        const color = RandomDepartmentColorGenerator(index, existingDept);
        index = index > 12 ? 0 : index + 1;
        prevDept = existingDept;
        return {
          color: "#ffffff",
          backgroundColor: color,
          display: "flex",
          "align-items": "center",
          lineHeight: "1.5",
          "justify-content": "center",
        };
      },
    },
    {
      headerName: "NAME",
      autoHeight: true,
      field: "employeeName",
      flex: 1,
      filter: "agSetColumnFilter",
      width: 250,
      sortable: true,
      menuTabs: ["filterMenuTab"],

      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: EmployeeInfoRender,
    },
    {
      headerName: "ROLE",
      autoHeight: true,
      field: "role",
      flex: 1,
      filter: "agSetColumnFilter",
      width: 150,
      sortable: true,
      menuTabs: ["filterMenuTab"],

      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: KeyRoleCellRenderer,
    },
    {
      headerName: "SALARY RANGE",
      autoHeight: true,
      field: "salary",
      filter: "agSetColumnFilter",
      width: 150,
      flex: 1,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      valueFormatter: (params) =>
        params.data
          ? getSingleSalaryRangeForUI(
              params.data?.salary,
              currencySymbol,
              currencyType
            )
          : getSingleSalaryRangeForUI(
              params.value,
              currencySymbol,
              currencyType
            ),
    },
    {
      headerName: "OPTIONS",
      autoHeight: true,
      field: "options",
      flex: 1,
      filter: "agSetColumnFilter",
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: OptionsCellRenderer,
    },
  ];

  return (
    <VStack className="w-full gap-6">
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Summary
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Company-wide employment and pay scale structure.
        </HStack>
      </VStack>
      <HStack className="justify-start w-full gap-6 px-6 pt-6">
        <VStack className="w-1/2 gap-6 pl-4 shadow-custom">
          <HStack className="flex-1 gap-8 py-6">
            <VStack className="justify-start space-y-4">
              <p className="">PPS:</p>
              <div className="">Date Of Valuation:</div>
            </VStack>
            <VStack className="justify-start space-y-4">
              <p className="">
                {formatCurrency(
                  compensationModellingData?.data?.ppsOfCompany || 1,
                  currencyType
                )}
              </p>
              <div className="">
                {compensationModellingData?.data?.dateOfValuation
                  ? formatDisplayDate(
                      compensationModellingData?.data?.dateOfValuation
                    )
                  : ""}
              </div>
            </VStack>
          </HStack>
        </VStack>
        <VStack className="w-1/2 gap-6 pl-4 shadow-custom">
          <HStack className="flex-1 gap-8 py-6">
            <VStack className="justify-start space-y-4">
              <p>No. of Options:</p>
              <p>Allocation Budget:</p>
            </VStack>
            <VStack className="justify-start space-y-4">
              <p>{`${(totalOptions || 0).toLocaleString(currencyType)}`}</p>
              <p>
                {getSingleSalaryRangeForUI(
                  totalSalary || 0,
                  currencySymbol,
                  currencyType
                )}
              </p>
            </VStack>
          </HStack>
        </VStack>
      </HStack>

      <HStack className="justify-end gap-8 pt-10 pr-4">
        <div className="flex gap-2 text-xs text-center justify-evenly">
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
        </div>
      </HStack>

      <HStack className="justify-between w-full pl-8">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            rowHeight={55}
            onGridReady={onGridReady}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            suppressRowTransform={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}
